export const route = {
  default: "/",

  // 사업소개 //
  // ************************************************************* //
  intro1: "/intro", //사업소개
  intro2: "/intro/vision", //비전 및 목표
  intro3: "/intro/ability", //핵심역량
  intro4: "/intro/running", //초연결형 We러닝
  intro5: "/intro/university", //참여대학

  // 성과공유 //
  // ************************************************************* //
  sharing1: "/sharing", //우수성과
  sharing2: "/sharing/kmooc", //K-MOOC
  sharing3: "/sharing/reference", //자료실

  kmoocDetail: "/sharing/kmooc/detail", //K-MOOC 상세
  referenceDetail: "/sharing/reference/detail", //자료실 상세

  // 교육과정 //
  // ************************************************************* //
  curriculum1: "/curriculum", //교육과정 소개
  curriculum2: "/curriculum/subject", //개설과목
  curriculum3: "/curriculum/target", //교육대상
  curriculum4: "/curriculum/complete", //이수안내

  subjectDetail: "/curriculum/subject/detail", //개설과목 상세

  // 학생지원 //
  // ************************************************************* //
  support1: "/support", //지원소개

  support2: "/support/supporter", //서포터즈
  support3: "/support/activity", //비교과활동
  activityDetail: "/support/activity/detail", //비교과활동 상세

  // 정보광장 //
  // ************************************************************* //
  plaza1: "/plaza/notice", //공지사항
  noticeDetail: "/plaza/notice/detail", //공지사항 상세

  plaza2: "/plaza/press", //보도자료
  pressDetail: "/plaza/press/detail", //보도자료 상세

  plaza3: "/plaza/employment", //취업정보
  plaza4: "/plaza/faq", //faq

  // ************************************************************* //
  privacy: "/privacy",
  board: "/board",
  boardDetail: "/board/detail",
};
