<script setup>
import InfraApi from "@/api/infra";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { ref, defineEmits, defineProps, onBeforeMount } from "vue";
import { useToast } from "vue-toastification";
import { onClickOutside } from "@vueuse/core";
const emit = defineEmits(["closeModal"]);

const target = ref(null);

onClickOutside(target, () => {
  emit("closeModal");
});

const toast = useToast();

const props = defineProps(["infraModalNum"]);

const info = ref({});

onBeforeMount(() => {
  getApi();
});

const getApi = async () => {
  try {
    let response = (await InfraApi.getById(props.infraModalNum)).data.data;
    response = {
      ...response,
      explanation: JSON.parse(response.explanation),
    };
    info.value = response;
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <div class="modal-dim">
    <div class="modal-scroll-container">
      <div ref="target" class="modal-container">
        <div
          class="flex-row-between"
          style="padding: 0 9px; margin-bottom: 15px"
        >
          <PretendardText class="text-16">상세보기</PretendardText>
          <img
            src="../../../assets/ico/x.png"
            style="object-fit: contain; cursor: pointer"
            alt=""
            @click="emit('closeModal')"
          />
        </div>
        <div class="img-wrapper">
          <img :src="info.logoUrl" alt="" />
        </div>
        <div class="main-img-wrapper">
          <img :src="info.imageUrl" alt="" />
        </div>
        <div class="gray-box">
          <PretendardText class="text-14 title">{{
            info.title
          }}</PretendardText>
          <div
            class="content-wrapper"
            v-for="(item, index) in info?.explanation"
            :key="index"
          >
            <div class="circle" />
            <PretendardText class="text-14"> {{ item.content }}</PretendardText>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-scroll-container {
  width: 100%;
  max-height: 100vh;
  display: flex;
  padding: 64px 0;
  justify-content: center;
  align-items: center;
  overflow: auto;

  @include mobile {
    padding: 27px 20px;
  }
}

.modal-container {
  width: 100%;
  max-width: 800px;
  height: fit-content;
  border-radius: 10px;
  background-color: $white;
  padding: 15px;
  overflow: auto;

  @include mobile {
    overflow: unset;
  }
}

.text-14 {
  color: $g03;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.6px;
}

.text-16 {
  color: $g01;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;
}

.gray-box {
  width: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  gap: 8px;
  border-radius: 10px;
  background: #f6f6f6;
  box-sizing: border-box;

  .title {
    color: $black;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .circle {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $g03;
  }

  .content-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  height: 55px;

  img {
    height: 100%;
  }
}

.main-img-wrapper {
  height: fit-content;
  margin-bottom: 20px;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.univ-logo {
  width: 202px;
  height: 62px;
  object-fit: contain;
}
</style>
