<script setup>
import { ref, onBeforeMount, defineProps } from "vue";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { displayHooks } from "@/hooks/useDisplay";
import { router } from "@/router";
import { route } from "@/router/route";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
import SubjectApi from "@/api/subject";
const toast = useToast();

let useDisplayRef = ref(displayHooks);
let menuValue = ref("");

let mainSectionMenu = ref([]);

const info = ref({});

const props = defineProps({
  id: String,
});

onBeforeMount(() => {
  getMenu();
  getInfo();
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[1].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`curriculum${index + 1}`],
          });
        }

        if (item.homeLink === "/curriculum02") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const goMenu = () => {
  router.push(route.curriculum2);
};

const getInfo = async () => {
  try {
    const response = (await SubjectApi.getById(props.id)).data.data;

    info.value = response;
    console.log(info);
  } catch (error) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <SubpageHeader />
  <MainSection
    bgUrl="curriculum"
    :options="mainSectionMenu"
    :menuValue="menuValue"
    mainHeader="교육과정"
    subHeader01="Home"
    subHeader02="교육과정"
  />

  <BodyContain style="padding-bottom: 141px">
    <PretendardText class="intro-title">{{ info.title }}</PretendardText>
    <PretendardText
      v-if="info.englishTitle"
      class="text-16"
      :style="
        useDisplayRef.isMobile ? `margin-bottom: 30px` : `margin-bottom: 50px`
      "
      >{{ info.englishTitle }}</PretendardText
    >
    <PretendardText class="text-24">기본정보</PretendardText>
    <div class="table">
      <div class="row">
        <div class="title-cell cell">제목</div>
        <div class="cell">{{ info.title }}</div>
      </div>
      <div class="row">
        <div class="title-cell cell">수준</div>
        <div class="cell">{{ info.level }}</div>
      </div>
      <div class="row">
        <div class="title-cell cell">학점</div>
        <div class="cell">{{ info.grade }}</div>
      </div>
      <div class="row">
        <div class="title-cell cell">교강사</div>
        <div class="cell">{{ info.instructor }}</div>
      </div>
    </div>
    <PretendardText class="text-24" style="margin-top: 80px"
      >수업 진행 계획</PretendardText
    >
    <div class="table-scroll" v-if="info?.courseSchedules?.length > 0">
      <div class="scedule-table">
        <div class="row">
          <div class="title-cell cell first">No.</div>
          <div class="title-cell cell second">수업 내용</div>
          <div class="title-cell cell third">담당자</div>
          <div class="title-cell cell fourth">수업방식</div>
        </div>
        <div
          v-for="(item, index) in info?.courseSchedules"
          :key="index"
          class="row content-row"
        >
          <div class="cell first">{{ index + 1 }}</div>
          <div class="cell second">{{ item.content }}</div>
          <div class="cell third">
            {{ item.manager ? item.manager : info.instructor }}
          </div>
          <div class="cell fourth">
            {{
              item.classMethod ? JSON.parse(item.classMethod).join(", ") : ""
            }}
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;
      "
    >
      <div class="list-btn" @click="goMenu">목록</div>
      <div class="lms-btn">학습플랫폼(LMS) 바로가기</div>
    </div>
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.intro-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 20px;

  @include mobile {
    font-size: 20px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

.text-16 {
  color: $g03;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.6px;
}

.text-24 {
  color: $g02;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;
  margin-bottom: 20px;

  @include mobile {
    font-size: 16px;
  }
}

.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  /* border-top: 1px solid $line; */
  /* border-bottom: 1px solid $line; */
}

.row {
  display: table-row;
  height: 80px;

  @include mobile {
    height: 50px;
  }

  .cell {
    width: 100%;
    height: 80px;
    display: table-cell;
    border: 1px solid $line;
    border-right: 0px;
    border-top: 0px;
    padding: 10px 20px;
    color: $g01;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.6px;

    @include mobile {
      font-size: 14px;
      height: 50px;
    }
  }

  .cell:first-child,
  .cell:nth-child(2) {
    border-top: 1px solid $line;
  }

  .title-cell {
    width: 150px;
    height: 80px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-left: 0px;

    @include mobile {
      width: 80px;
      height: 50px;
    }
  }
}

.scedule-table {
  display: table;
  width: 100%;
  border-collapse: collapse;

  overflow: auto;

  .row {
    width: 100%;
    display: flex;
  }

  .first {
    width: 100px;
  }

  .second {
    width: 660px;
  }

  .third {
    width: 150px;
  }

  .fourth {
    width: 290px;
  }

  .content-row {
    .first,
    .second {
      font-weight: 700;
    }

    .cell {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .second {
      justify-content: flex-start;
    }
  }
}

.table-scroll {
  @include mobile {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 30px;
  }
}

.table-scroll::-webkit-scrollbar {
  width: 18px; /* 스크롤바의 너비 */
}

.table-scroll::-webkit-scrollbar-thumb {
  width: 6px;
  background: $primary4; /* 스크롤바의 색상 */
  border-radius: 999px;
  border: 6px solid #ffffff;
}

.table-scroll::-webkit-scrollbar-track {
  border-radius: 999px;
  background: #ffffff;
  border: 6px solid #ffffff;
}

.list-btn {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  border: 1px solid $g05;
  font-family: Pretendard;
  color: $primary4;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.6px;
  cursor: pointer;
}

.lms-btn {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  border: 1px solid #54966e;
  font-family: Pretendard;
  color: #54966e;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.6px;
  cursor: pointer;
}
</style>
