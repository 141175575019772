<script setup>
import { ref, onBeforeMount } from "vue";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import { route } from "@/router/route";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import CurrAccordianItem from "@/components/organisms/curriculum/currAccordianItem.vue";
import SearchInput from "@/components/molcules/input/searchInput.vue";
import BtmPagination from "@/components/molcules/pagination/btmPagination.vue";
import MenuApi from "@/api/menu";
import TargetApi from "@/api/target";
// import BoardApi from "@/api/board";
import { useToast } from "vue-toastification";
const toast = useToast();

import { displayHooks } from "@/hooks/useDisplay";
let useDisplayRef = ref(displayHooks);
let menuValue = ref("");

// let boardId = ref(0);

let last = ref(false);
let first = ref(false);
let page = ref(0);
let maxPage = ref(0);
let step = ref(0);
let total = ref(0);
let listSize = ref(3);
let paginationSize = ref(5);

let keyword = ref("");
let mainSectionMenu = ref([]);

const goPrevPage = () => {
  if (!first.value) {
    page.value--;
    getApi();
  } else return;

  if (page.value < paginationSize.value * step.value) {
    step.value--;
  }
};

const goNextPage = () => {
  if (!last.value) {
    page.value++;
    getApi();
  } else return;

  if (page.value + 1 > paginationSize.value * (step.value + 1)) {
    step.value++;
  }
};

const goPage = (index) => {
  page.value = index;
  getApi();
};

onBeforeMount(() => {
  getMenu();
  getApi(true);
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[1].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`curriculum${index + 1}`],
          });
        }

        if (item.homeLink === "/curriculum3") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const list = ref([]);

const getApi = async (initial) => {
  try {
    if (initial) {
      total.value = 0;
      page.value = 0;
      step.value = 0;
    }
    const response = await TargetApi.get({
      size: listSize.value,
      page: page.value,
      keyword: keyword.value,
      useFlag: true,
    });

    if (response.data.data) {
      last.value = response.data?.data?.last;
      first.value = response.data?.data?.first;
      list.value = response.data?.data?.content;
      total.value = response.data.data.totalElements;

      maxPage.value = Math.ceil(total.value / listSize.value);
      if (maxPage.value < paginationSize.value) {
        paginationSize.value = maxPage.value;
      }
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <SubpageHeader />
  <MainSection
    bgUrl="curriculum"
    :options="mainSectionMenu"
    menuValue="교육대상"
    mainHeader="교육과정"
    subHeader01="Home"
    subHeader02="교육과정"
  />

  <BodyContain
    :style="`padding-bottom: ${useDisplayRef.isMobile ? '60px' : '120px'}`"
  >
    <PretendardText class="intro-title">교육대상</PretendardText>
    <PretendardText class="text-24"
      >관심분야, 학과 전공 수준별
      <br v-if="useDisplayRef.isMobile" />마이크로디그리, 부전공, 복수전공 가능
    </PretendardText>
    <div class="box-wrapper">
      <div class="box" style="background: #d3be38">
<!--        <div class="label-top">외국어･지역학/ <br v-if="useDisplayRef.isTablet" />문예창작/<br />-->
<!--                    문화･예술 관련 <br v-if="useDisplayRef.isTablet" />전공 인재</div>-->
                  <PretendardText class="content">영화･영상 전공 계열</PretendardText>
                </div>
                <div class="box" style="background: #a8b959">

        <PretendardText class="content">웹툰･애니메이션 전공 계열</PretendardText>
<!--        <div class="label-bottom">자연과학계열</div>-->
      </div>
      <div class="box" style="background: #78a873">

<!--        <div class="label-top">공학계열</div>-->
        <PretendardText class="content">음악･공연 전공 계열</PretendardText>
      </div>
      <div class="box" style="background: #408374">
        <PretendardText class="content">문예창작･문학 전공 계열</PretendardText>
<!--        <div class="label-bottom">의학계열</div>-->
      </div>
      <div class="box" style="background: #074d85">

<!--        <div class="label-top">예비창업·로컬크리에이터</div>-->
        <PretendardText class="content"
        >외국어･<br
            v-if="useDisplayRef.isTablet"
        />지역학 전공 계열</PretendardText
        >
      </div>
    </div>
    <div class="table-wrapper">
      <div
        :class="useDisplayRef.isMobile ? 'flex-col' : 'flex-row-between'"
        :style="
          useDisplayRef.isMobile
            ? 'margin-bottom: 30px'
            : 'margin-bottom: 50px; justify-content: flex-end'
        "
      >
        <SearchInput
          v-model="keyword"
          placeholder="검색어를 입력하세요."
          @click="getApi(true)"
        />
      </div>

      <div v-if="list.length" class="accordian-wrapper">
        <CurrAccordianItem
          v-for="(item, index) in list"
          :key="index"
          :title="item.title"
          :content="item.content"
          :type="item.educationCategoryTitle"
        />
      </div>

      <div style="position: relative">
        <BtmPagination
          class="target-pagination"
          :page="page"
          :maxPage="maxPage"
          :step="step"
          :last="last"
          :first="first"
          :total="total"
          :listSize="listSize"
          :paginationSize="paginationSize"
          @goPrevPage="goPrevPage"
          @goNextPage="goNextPage"
          @goPage="goPage"
        />
      </div>
    </div>
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.intro-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 50px;

  @include mobile {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.text-24 {
  color: $g02;
  font-size: 24px;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.6px;
  margin-bottom: 30px;

  @include mobile {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.box-wrapper {
  display: flex;
  gap: 20px;

  @include mobile {
    flex-direction: column;
    gap: 10px;
  }
}

.box {
  display: flex;
  width: 224px;
  height: 224px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;

  @include mobile {
    width: 100%;
    height: fit-content;
    padding: 14px 0px;
  }

  .content {
    color: $white;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    letter-spacing: -0.6px;
  }

  .label-top {
    position: absolute;
    top: 10%;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    text-align: center;
    background: rgba(255, 255, 255, 1);
  }

  .label-bottom {
    position: absolute;
    top: 80%;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    text-align: center;
    background: rgba(255, 255, 255, 1);
  }

}

.table-wrapper {
  margin-top: 80px;

  @include mobile {
    margin-top: 60px;
  }
}

</style>
