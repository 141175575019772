import api from "./api";

export default class SubjectApi {
  static async get(data) {
    return await api.get(`courses`, data);
  }
  static async getForExternal(data) {
    return await api.postExternal('/loginpass/getRegCurrSubList.do',data);
  }

  static async getOperAcplListForExternal(data) {
    return await api.postExternal(`/loginpass/getOperAcplListForExternal.do`,data);
  }
  static async getOperAcplDataForExternal(params) {
    return await api.postExternal(`/loginpass/getOperAcplDataForExternal.do`,params);
  }

  static async getById(id) {
    return await api.get(`course/${id}`);
  }
}
