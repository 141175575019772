<script setup>
import { onMounted, ref, onBeforeMount, watch } from "vue";

import PretendardText from "@/components/atoms/text/PretendardText.vue";
import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import ConsortiumModal1 from "@/components/templates/modal/intro/consortiumModal1.vue";
import InfraModal from "@/components/templates/modal/infraModal.vue";
import TrackTypeBox from "@/components/organisms/main/TrackTypeBox.vue";
import { route } from "@/router/route";
import { displayHooks } from "@/hooks/useDisplay";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
import InfraApi from "@/api/infra";
import { register } from "swiper/element/bundle";

register();

const swiper = ref(null);
const toast = useToast();

let useDisplayRef = ref(displayHooks);
let menuValue = ref("");

const modalNum = ref(-1);
const infroModalNum = ref(-1);

const closeModal = () => {
  modalNum.value = -1;
  infroModalNum.value = -1;
};

const item1 = ref(null);
const item1Observe = ref(false);

const item2 = ref(null);
const item2Observe = ref(false);

const observeRef = (ref, refType) => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          refType.value = true;
        }
      });
    },
    {
      threshold: 0.6, // 50% 이상이 보일 때 콜백 실행
    }
  );

  if (ref.value) {
    observer.observe(ref.value);
  }
};

onMounted(() => {
  observeRef(item1, item1Observe);
  observeRef(item2, item2Observe);
});

let mainSectionMenu = ref([]);

onBeforeMount(() => {
  getMenu();
  getInfra();
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);

    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[0].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`intro${index + 1}`],
          });
        }

        if (item.homeLink === "/intro1") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const infraList = ref([]);

const getInfra = async () => {
  try {
    const response = (await InfraApi.get({ useFlag: true })).data.data.content;
    infraList.value = response;
  } catch (error) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

watch([modalNum, infroModalNum], () => {
  if (modalNum.value !== -1 || infroModalNum.value !== -1) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
});
</script>

<template>
  <SubpageHeader />
  <MainSection
    bgUrl="intro"
    :options="mainSectionMenu"
    :menuValue="menuValue"
    mainHeader="사업소개"
    subHeader01="Home"
    subHeader02="사업소개"
  />

  <BodyContain
    :style="
      useDisplayRef.isMobile ? `padding-bottom: 60px` : `padding-bottom: 140px`
    "
  >
    <div class="global-k-box">
      <img
        v-if="!useDisplayRef.isMobile"
        src="../../../src/assets/img/intro/global_k_bg.png"
        alt=""
      />
      <img
        v-if="useDisplayRef.isMobile"
        src="../../../src/assets/img/intro/m_global_k_bg.png"
        alt=""
      />
      <PretendardText class="title"
        >글로벌 K-컬처 선도 융합인재양성 사업이란?</PretendardText
      >
      <PretendardText class="subtitle"
        >교육과정, 교육방법, 교육인프라를 혁신하여
        <br v-if="useDisplayRef.isMobile" />국가 수준의 핵심인재
        양성</PretendardText
      >
      <PretendardText class="caption"
        >대학이 가진 특성화를 바탕으로
        <br v-if="useDisplayRef.isMobile" />'초연결형 We러닝' 인프라를 구축 및
        운영합니다.<br />K-컬처 실무 현장에서 진행되고 있는 역동적 융합을
        이해하고, <br />관련 역량을 습득하여 진로를 능동적으로 준비할 수 있는<br
          v-if="useDisplayRef.isMobile"
        />
        학습 환경을 제공합니다. <br />산업체, 연구기관, 지역사회간 적극적인
        협력을 통해 문화를 통한 <br />국가 경쟁력을 강화하고 지역 문제 해결을
        도모합니다.
      </PretendardText>
      <OutLineBtn style="z-index: 2" :isWhite="true" btnText="문의하기" />
    </div>
    <div class="flex-col" style="margin-bottom: 120px">
      <PretendardText class="intro-title"
        >글로벌 K-컬처 컨소시엄 사업단</PretendardText
      >
      <div class="organization-wrapper">
        <div class="organization-container">
          <div
            class="organization-box"
            style="background-color: #044a85; border: none; color: #fff"
          >
            사업단장 협의회
          </div>
        </div>
        <div class="organization-container">
          <div
            class="organization-box organization-title"
            style="background-color: #f7fafc; color: #111"
          >
            위원회
          </div>
          <div class="organization-item-wrapper">
            <div class="organization-box">사업관리위원회</div>
            <div class="organization-box">교육과정심의위원회</div>
            <div class="organization-box">자체평가위원회</div>
            <div class="organization-box">외부자문위원회</div>
          </div>
        </div>
        <div class="organization-container">
          <div
            class="organization-box organization-title"
            style="background-color: #f7fafc; color: #111"
          >
            센터
          </div>
          <div class="organization-item-wrapper">
            <div class="organization-box">
              교수학습혁신센터<img
                src="../../assets/ico/btn/contortium_q.png"
                alt=""
                @click="modalNum = 0"
              />
            </div>
            <div class="organization-box">
              교육인프라혁신센터<img
                src="../../assets/ico/btn/contortium_q.png"
                alt=""
                @click="modalNum = 1"
              />
            </div>
            <div class="organization-box">
              성과평가센터<img
                src="../../assets/ico/btn/contortium_q.png"
                alt=""
                @click="modalNum = 2"
              />
            </div>
            <div class="organization-box">
              문화융합커리어개발센터<img
                src="../../assets/ico/btn/contortium_q.png"
                alt=""
                @click="modalNum = 3"
              />
            </div>
            <div class="organization-box">
              글로벌연계지원센터<img
                src="../../assets/ico/btn/contortium_q.png"
                alt=""
                @click="modalNum = 4"
              />
            </div>
          </div>
        </div>
        <div class="organization-container">
          <div
            class="organization-box organization-title"
            style="background-color: #f7fafc; color: #111"
          >
            교육개발
          </div>
          <div class="organization-item-wrapper">
            <div class="organization-box">캐치웨이브 교육협의회</div>
            <div class="organization-box">로그웨이브 분야별 교육협의회</div>
          </div>
        </div>
        <div class="organization-container">
          <div
            class="organization-box organization-title"
            style="background-color: #f7fafc; color: #111"
          >
            기타
          </div>
          <div class="organization-item-wrapper">
            <div class="organization-box">글로벌K-컬처연구소</div>
            <div class="organization-box">글로벌 K-컬처 리빙랩</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-col section2">
      <PretendardText class="intro-title"
        >참여대학별 특성을 고려한 <br v-if="useDisplayRef.isMobile" />인재상 및
        교육과정</PretendardText
      >
      <div class="curriculum-wrapper flex-col-center">
        <img
          v-if="useDisplayRef.isMobile"
          src="../../assets/img/intro/m_curriculum.png"
          alt=""
        />
        <img
          v-if="!useDisplayRef.isMobile"
          src="../../assets/img/intro/curriculum.png"
          alt=""
        />
        <div class="circle-wrapper">
          <div class="circle" style="background-color: #d3be38">창의력</div>
          <div class="circle" style="background-color: #a8b959">소통력</div>
          <div class="circle" style="background-color: #78a873">
            문제해결 · 분석력
          </div>
          <div class="circle circle-m" style="background-color: #408374">
            콘텐츠 · 기술력
          </div>
          <div class="circle circle-m" style="background-color: #074d85">
            융합력
          </div>
        </div>
      </div>
      <div class="flex-col-center fivec-wrapper">
        <img class="fivec-title" src="../../assets/img/intro/5c.png" alt="" />
        <img
          v-if="!useDisplayRef.isMobile"
          class="fivec-tri"
          src="../../assets/img/intro/5c_tri.png"
          alt=""
        />
        <img
          v-if="useDisplayRef.isMobile"
          class="fivec-tri"
          src="../../assets/img/intro/m_5c_tri.png"
          alt=""
        />
        <div style="width: 100%">
          <PretendardText
            v-if="!useDisplayRef.isMobile"
            class="text-14"
            style="font-weight: 600; color: #044a85; margin-bottom: 10px"
            >융합</PretendardText
          >
          <div class="training-wrapper">
            <div class="training-box training-box-top">
              <div class="training-category-title">글로벌</div>
              <div class="flex-row-align-center" style="gap: 8px">
                <img src="../../assets/ico/intro/pentagon.png" alt="" />
                <PretendardText
                  :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                  >글로벌</PretendardText
                >
              </div>
            </div>
            <div class="training-box training-box-top">
              <div class="training-category-title">인문</div>
              <div class="flex-row-align-center" style="gap: 8px">
                <img src="../../assets/ico/intro/star.png" alt="" />
                <PretendardText
                  :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                  >인문</PretendardText
                >
              </div>
            </div>
            <div class="training-box culture-wrapper-box" style="width: 100%">
              <div class="training-category-title">K-컬처</div>
              <div
                class="flex-row-align-center culture-wrapper"
                style="gap: 15px"
              >
                <div class="flex-row-align-center" style="gap: 15px">
                  <div
                    class="flex-row-align-center culture-box"
                    style="gap: 8px"
                  >
                    <img src="../../assets/ico/intro/triangle.png" alt="" />
                    <PretendardText
                      :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                      >영화 · 드라마</PretendardText
                    >
                  </div>
                  <div
                    class="flex-row-align-center culture-box"
                    style="gap: 8px"
                  >
                    <img src="../../assets/ico/intro/square.png" alt="" />
                    <PretendardText
                      :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                      >웹툰 · 애니메이션</PretendardText
                    >
                  </div>
                  <div
                    class="flex-row-align-center culture-box"
                    style="gap: 8px"
                  >
                    <img src="../../assets/ico/intro/rhombus.png" alt="" />
                    <PretendardText
                      :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                      >음악 · 공연</PretendardText
                    >
                  </div>
                </div>
                <div class="flex-row-align-center" style="gap: 15px">
                  <div
                    class="flex-row-align-center culture-box"
                    style="gap: 8px"
                  >
                    <img src="../../assets/ico/intro/circle.png" alt="" />
                    <PretendardText
                      :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                      >문학 · 웹소설</PretendardText
                    >
                  </div>
                  <div
                    class="flex-row-align-center culture-box"
                    style="gap: 8px"
                  >
                    <img src="../../assets/ico/intro/star2.png" alt="" />
                    <PretendardText
                      :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                      >융합콘텐츠</PretendardText
                    >
                  </div>
                </div>
              </div>
            </div>
            <img
              v-if="useDisplayRef.isDesktop"
              class="map"
              src="../../assets/img/intro/5c_map.png"
              alt=""
            />
          </div>
          <div v-if="!useDisplayRef.isDesktop" class="m-culture-text">
            <PretendardText class="text-16" style="font-weight: 500"
              >지역에 기반을 두고 세계무대에서 활약하는</PretendardText
            >
            <img src="../../assets/img/intro/m_culture_text.png" alt="" />
          </div>
          <div
            :class="!useDisplayRef.isDesktop ? `flex-col` : `flex-row-between`"
            :style="
              !useDisplayRef.isDesktop
                ? `margin-top: 30px; gap: 10px; align-items: center`
                : `margin-top: 53px`
            "
          >
            <div class="univ-wrapper">
              <div class="univ-title-wrapper">수도권 (서울 · 경기)</div>
              <div class="univ-box">
                <img
                  src="../../assets/ico/intro/dku_logo.png"
                  alt=""
                  :style="!useDisplayRef.isDesktop ? `width: 112px;` : ``"
                />
                <div class="shapes-box">
                  <img src="../../assets/ico/intro/pentagon.png" alt="" />
                  <img src="../../assets/ico/intro/star.png" alt="" />
                  <img src="../../assets/ico/intro/circle.png" alt="" />
                </div>
              </div>
              <div class="univ-box">
                <img
                  src="../../assets/ico/intro/cku_logo.png"
                  alt=""
                  :style="!useDisplayRef.isDesktop ? `width: 117px;` : ``"
                />
                <div class="shapes-box">
                  <img src="../../assets/ico/intro/square.png" alt="" />
                  <img src="../../assets/ico/intro/star2.png" alt="" />
                </div>
              </div>
            </div>
            <div class="univ-wrapper">
              <div class="univ-title-wrapper">충청권 (충남)</div>
              <div class="univ-box">
                <img
                  src="../../assets/ico/intro/hsu_logo.png"
                  alt=""
                  :style="!useDisplayRef.isDesktop ? `width: 85px;` : ``"
                />
                <div class="shapes-box">
                  <img src="../../assets/ico/intro/square.png" alt="" />
                  <img src="../../assets/ico/intro/rhombus.png" alt="" />
                  <img src="../../assets/ico/intro/circle.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div
            :class="!useDisplayRef.isDesktop ? `flex-col` : `flex-row-between`"
            :style="
              !useDisplayRef.isDesktop
                ? `margin-top: 10px; gap: 10px; align-items: center`
                : `margin-top: 53px`
            "
          >
            <div class="univ-wrapper">
              <div class="univ-title-wrapper">호남제주권 (전북)</div>
              <div class="univ-box">
                <img
                  src="../../assets/ico/intro/wku_logo.png"
                  alt=""
                  :style="!useDisplayRef.isDesktop ? `width: 81px;` : ``"
                />
                <div class="shapes-box">
                  <img src="../../assets/ico/intro/pentagon.png" alt="" />
                  <img src="../../assets/ico/intro/square.png" alt="" />
                  <img src="../../assets/ico/intro/circle.png" alt="" />
                </div>
              </div>
            </div>
            <div class="univ-wrapper">
              <div class="univ-title-wrapper">동남권 (부산)</div>
              <div class="univ-box">
                <img
                  src="../../assets/ico/intro/dsu_logo.png"
                  alt=""
                  :style="!useDisplayRef.isDesktop ? `width: 104px;` : ``"
                />
                <div class="shapes-box">
                  <img src="../../assets/ico/intro/square.png" alt="" />
                  <img src="../../assets/ico/intro/triangle.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="yellow-box">
          <PretendardText class="yellow-box-text"
            >지역사회에 K-컬처 융합인재 공급<br />지역 소멸의 위기를 지역상생의
            기회로!</PretendardText
          >
        </div>
        <img
          src="../../assets/ico/intro/yellow-arrow.png"
          alt=""
          style="margin-top: -8px; margin-bottom: 30px"
        />
        <div class="gradient-box">
          <PretendardText class="gradient-box-text">성과 확산</PretendardText>
        </div>
        <div class="univ-yellow-wrapper">
          <PretendardText class="univ-yellow-text"
            >글로벌K-컬처선도 융합인재양성 컨소시엄</PretendardText
          >
          <img
            v-if="!useDisplayRef.isDesktop"
            class="m-univ-list"
            src="../../assets/img/intro/consortium_univ.png"
            alt=""
          />
          <div
            v-if="useDisplayRef.isDesktop"
            class="flex-row-align-center"
            style="justify-content: center; gap: 15px; position: relative"
          >
            <img
              src="../../assets/img/intro/wku_consortium.png"
              alt=""
              style="z-index: 2"
            />
            <img
              src="../../assets/img/intro/dsu_consortium.png"
              alt=""
              style="z-index: 2"
            />
            <img
              src="../../assets/img/intro/hsu_consortium.png"
              alt=""
              style="z-index: 2"
            />
            <img
              src="../../assets/img/intro/dku_consortium.png"
              alt=""
              style="z-index: 2"
            />
            <img
              src="../../assets/img/intro/cku_consortium.png"
              alt=""
              style="z-index: 2"
            />
            <div class="univ-wrapper-line" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex-col section3">
      <PretendardText
        class="intro-title"
        :style="
          useDisplayRef.isMobile ? `margin-bottom: 30px` : `margin-bottom: 0`
        "
        >리빙랩 지역 특화 및 대학간 산학연 공유</PretendardText
      >
      <div class="section3-univ-wrapper" v-if="!useDisplayRef.isDesktop">
        <div class="univ">
          <div class="univ-name">단국대</div>
          <PretendardText class="univ-content"
            >외국어, 한국어, 문학 /웹소설 특화 수도권 리빙랩</PretendardText
          >
        </div>
        <div class="univ">
          <div class="univ-name">동서대</div>
          <PretendardText class="univ-content"
            >영화 / 드라마 특화 동남권 리빙랩</PretendardText
          >
        </div>
        <div class="univ">
          <div class="univ-name">원광대</div>
          <PretendardText class="univ-content"
            >인문 / 역사문화 특화 호남권 리빙랩</PretendardText
          >
        </div>
        <div class="univ">
          <div class="univ-name">청강문화산업대</div>
          <PretendardText class="univ-content"
            >웹툰 / 애니메이션 특화 수도권 리빙랩</PretendardText
          >
        </div>
        <div class="univ">
          <div class="univ-name">한서대</div>
          <PretendardText class="univ-content"
            >음악 / 공연 특화 충청권 리빙랩</PretendardText
          >
        </div>
      </div>
      <img
        v-if="useDisplayRef.isDesktop"
        src="../../assets/img/intro/recommend.png"
        alt=""
        style="margin-bottom: 65px"
      />
      <div class="flex-col" style="gap: 20px">
        <PretendardText class="section3-title"
          >지역 문화예술 기관</PretendardText
        >
        <div
          :class="
            !useDisplayRef.isDesktop ? `flex-col` : `flex-row-align-center`
          "
          style="gap: 20px"
        >
          <div class="gray-line-box gray-line-box-content">
            <PretendardText class="section3-subtitle"
              >대한민국 문화콘텐츠사업 선도</PretendardText
            >
            <div class="flex-col" style="gap: 8px">
              <div class="flex-row-align-center" style="gap: 6px">
                <div class="circle" />
                <PretendardText
                  :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                  >판교테크노벨리</PretendardText
                >
              </div>
              <div class="flex-row-align-center" style="gap: 6px">
                <div class="circle" />
                <PretendardText
                  :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                  >경기콘텐츠진흥원</PretendardText
                >
              </div>
              <div class="flex-row-align-center" style="gap: 6px">
                <div class="circle" />
                <PretendardText
                  :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                  >경기콘텐츠코리아랩</PretendardText
                >
              </div>
              <div class="flex-row-align-center" style="gap: 6px">
                <div class="circle" />
                <PretendardText
                  :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                  >구리 음악창작소</PretendardText
                >
              </div>
              <div class="flex-row-align-center" style="gap: 6px">
                <div class="circle" />
                <PretendardText
                  :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                  >경기콘텐츠진흥원</PretendardText
                >
              </div>
            </div>
          </div>
          <div class="gray-line-box gray-line-box-youth">
            <PretendardText class="section3-subtitle"
              >청년 문화예술인 창업 · 창작 활동 지원</PretendardText
            >
            <div
              :class="
                useDisplayRef.isMobile ? `flex-col` : `flex-row-align-center`
              "
              style="width: 100%; gap: 20px"
            >
              <div class="flex-col" style="gap: 8px; width: 100%">
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >충남정보문화산업진흥원</PretendardText
                  >
                </div>
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >충남 콘텐츠코리아랩</PretendardText
                  >
                </div>
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >충남 콘텐츠기업지원센터</PretendardText
                  >
                </div>
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >충남 음악창작소</PretendardText
                  >
                </div>
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >충남 글로벌 게임센터</PretendardText
                  >
                </div>
              </div>
              <div class="flex-col" style="gap: 8px; width: 100%">
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >전라북도 콘텐츠융합진흥원</PretendardText
                  >
                </div>
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >전북 콘텐츠코리아랩</PretendardText
                  >
                </div>
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >전북 콘텐츠기업 지원센터</PretendardText
                  >
                </div>
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >전북 음악창작소</PretendardText
                  >
                </div>
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >익산문화예술의 거리</PretendardText
                  >
                </div>
              </div>
              <div class="flex-col" style="gap: 8px; width: 100%">
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >부산국제영화제</PretendardText
                  >
                </div>
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >임권택영화박물관</PretendardText
                  >
                </div>
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >부산 정보산업진흥원</PretendardText
                  >
                </div>
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >부산 콘텐츠코리아랩</PretendardText
                  >
                </div>
                <div class="flex-row-align-center" style="gap: 6px">
                  <div class="circle" />
                  <PretendardText
                    :class="useDisplayRef.isMobile ? `text-12` : `text-14`"
                    >부산 글로벌게임센터</PretendardText
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col expectation-wrapper">
        <PretendardText class="section3-title">기대효과</PretendardText>
        <div class="flex-row-align-center expectation-box-wrapper">
          <div
            class="gray-line-box flex-col-center-center"
            style="width: 100%; gap: 15px; height: 250px"
          >
            <img
              class="effect-ico"
              src="../../assets/ico/intro/global.png"
              alt=""
            />
            <PretendardText
              class="section3-subtitle"
              style="font-weight: 400; text-align: center"
              >글로벌 K-컬처<br />
              교류 체계<br />
              구축</PretendardText
            >
          </div>
          <div
            class="gray-line-box flex-col-center-center"
            style="width: 100%; gap: 15px; height: 250px"
          >
            <img
              class="effect-ico"
              src="../../assets/ico/intro/fusion.png"
              alt=""
            />
            <PretendardText
              class="section3-subtitle"
              style="font-weight: 400; text-align: center"
              >산·학·연·관 통합<br />
              연결기반<br />
              융합인재 양성</PretendardText
            >
          </div>
          <div
            class="gray-line-box flex-col-center-center"
            style="width: 100%; gap: 15px; height: 250px"
          >
            <img
              class="effect-ico"
              src="../../assets/ico/intro/job.png"
              alt=""
            />
            <PretendardText
              class="section3-subtitle"
              style="font-weight: 400; text-align: center"
              >K-컬쳐 산업 발전을 통한<br />
              국부 상승,<br />
              일자리 창출</PretendardText
            >
          </div>
          <div
            class="gray-line-box flex-col-center-center"
            style="width: 100%; gap: 15px; height: 250px"
          >
            <img
              class="effect-ico"
              src="../../assets/ico/intro/puzzle.png"
              alt=""
            />
            <PretendardText
              class="section3-subtitle"
              style="font-weight: 400; text-align: center"
              >문화콘텐츠 산업의<br />
              미래형 기술<br />
              인력구조 개편</PretendardText
            >
          </div>
        </div>
      </div>
    </div>
  </BodyContain>
  <div class="flex-col-center section4">
    <div class="section4-category">비전</div>
    <PretendardText
      :class="useDisplayRef.isMobile ? `text-16` : `text-24`"
      :style="
        useDisplayRef.isMobile ? `font-weight: 400` : `margin-bottom: 10px`
      "
      >글로벌 안목으로 지역과 세계를 연결하고,
      <br v-if="!useDisplayRef.isDesktop" />창의적 감각으로 문화와 산업을
      매개하는,</PretendardText
    >
    <PretendardText
      class="text-32"
      :style="
        useDisplayRef.isMobile ? `font-size: 20px` : `margin-bottom: 69px`
      "
      >글로벌 K-컬처 융합인재 양성</PretendardText
    >
    <img
      ref="item1"
      class="vision-logo"
      :class="{ 'slide-in': item1Observe }"
      src="../../assets/img/intro/vision_logo.png"
      alt=""
      style="margin-bottom: 46px"
    />
    <div class="section4-category">목표</div>
    <PretendardText
      class="text-32"
      :style="
        useDisplayRef.isMobile
          ? `font-size: 20px; margin-bottom: 30px`
          : `margin-bottom: 20px`
      "
      >5C 인재 5,000명 양성</PretendardText
    >
    <div
      ref="item2"
      :class="[
        { 'slide-in': item2Observe },
        !useDisplayRef.isDesktop
          ? `flex-col white-box-wrapper`
          : `flex-row-align-center item-wrapper`,
      ]"
      :style="useDisplayRef.isMobile ? `gap: 10px` : `gap: 30px`"
    >
      <div class="white-box">
        <PretendardText class="text-16"
          >지역소멸의 위기를 지역 상생의 기회로 전환</PretendardText
        >
        <PretendardText class="text-14"
          >지역에 기반을 두고 세계 무대에서 활동하는<br />
          K-컬처 융합인재를 육성하여 지역 소멸의 위기를 극복하고<br />
          대학 · 지역 · 산업이 상생하는 균형발전 실현에 기여</PretendardText
        >
      </div>
      <div class="white-box">
        <PretendardText class="text-16"
          >인문적 통찰과 예술적 표현의 융합을 통한 가치 창출</PretendardText
        >
        <PretendardText class="text-14"
          >창의력, 소통력, 문제해결 · 분석력, 콘텐츠 제작 · 기술력,<br />
          융합력을 고루 갖춘 인재를 양성하여<br />
          K-컬처를 선도하는 새로운 가치 창출</PretendardText
        >
      </div>
      <div class="white-box">
        <PretendardText class="text-16"
          >인문사회계열 학생들의 진로 개척 지원</PretendardText
        >
        <PretendardText class="text-14"
          >글로벌 한국을 통해 다양하게 진로를 모색하고,<br />
          창의적 감각으로 실무 역량을 습득하여,<br />
          글로벌 K-컬처 전문가로 성장하도록 지원</PretendardText
        >
      </div>
    </div>
  </div>
  <div class="flex-col-center section5">
    <div class="section4-category">핵심역량</div>
    <img
      v-if="!useDisplayRef.isMobile"
      class="intro-ability"
      alt=""
      src="../../../src/assets/img/intro/ability.png"
    />
    <img
      v-if="useDisplayRef.isMobile"
      class="intro-ability"
      alt=""
      src="../../../src/assets/img/intro/m_ability.png"
    />
    <div class="track-wrapper">
      <img
        v-if="!useDisplayRef.isMobile"
        class="intro-track"
        alt=""
        src="../../../src/assets/img/intro/track.png"
      />
      <img
        v-if="useDisplayRef.isMobile"
        class="intro-track"
        alt=""
        src="../../../src/assets/img/main/m_track.png"
      />
      <div class="track-info">
        <div class="track-title-wrapper" style="margin-bottom: 5px">
          <div class="line" />
          <PretendardText class="track-title">진로유형</PretendardText>
          <div class="line" />
        </div>
        <TrackTypeBox
          backgroundColor="#044A85"
          content="K-컬처의 원형을 찾는"
          title="Story-Builder"
          :isBlack="false"
          img="1"
        />
        <TrackTypeBox
          backgroundColor="#54966E"
          content="K-컬처에 생명력을 더하는"
          title="Creator"
          :isBlack="false"
          img="2"
        />
        <TrackTypeBox
          backgroundColor="#F6E382"
          content="K-컬처를 더 많은 사람들에게 전하는"
          title="Influencer"
          isBlack
          img="3"
        />
        <TrackTypeBox
          backgroundColor="#DDD"
          content="K-컬처에 날개를 달아주는"
          title="Valuator"
          isBlack
          img="4"
        />
      </div>
    </div>
    <div class="section4-category" style="margin: 60px 0 30px 0">인프라</div>
    <!-- <div
        v-for="(item, index) in infraList"
        :key="index"
        class="infra-wrapper flex-col-center"
        @click="infroModalNum = item.id"
      >
        <div class="title-box">
          <PretendardText class="text-16">{{ item.university }}</PretendardText>
          <PretendardText class="text-14">{{ item.space }}</PretendardText>
        </div>
        <img
          :src="
            item.imageUrl
              ? item.imageUrl
              : require(`../../assets/img/img_empty.png`)
          "
          :style="item.id === 1 ? { 'object-position': 'right' } : {}"
          alt=""
        />
      </div> -->
    <div class="infra">
      <div v-if="infraList.length <= 5" class="infra-container">
        <div
          v-for="(item, index) in infraList"
          :key="index"
          class="infra-wrapper flex-col-center"
          @click="infroModalNum = item.id"
        >
          <div class="title-box">
            <PretendardText class="text-16">{{
              item.university
            }}</PretendardText>
            <PretendardText class="text-14">{{ item.space }}</PretendardText>
          </div>
          <img
            :src="
              item.imageUrl
                ? item.imageUrl
                : require(`../../assets/img/img_empty.png`)
            "
            :style="item.id === 1 ? { 'object-position': 'right' } : {}"
            alt=""
          />
        </div>
      </div>
      <div v-if="infraList.length > 5" class="infra-container">
        <swiper-container
          ref="swiper"
          :slides-per-view="1"
          :loop="true"
          :centered-slides="true"
          @swiperslidechange="onSlideChange"
          :navigation="{
            nextEl: '.infra-swiper-button-next',
            prevEl: '.infra-swiper-button-prev',
          }"
          :breakpoints="{
            768: {
              slidesPerView: 5,
            },
          }"
          style="width: 100%"
        >
          <swiper-slide
            v-for="(item, index) in infraList"
            :key="index"
            class="swiper-slide"
          >
            <div class="infra-wrapper flex-col-center">
              <div class="title-box">
                <PretendardText class="text-16">{{
                  item.university
                }}</PretendardText>
                <PretendardText class="text-14">{{
                  item.space
                }}</PretendardText>
              </div>
              <img
                :src="
                  item.imageUrl
                    ? item.imageUrl
                    : require(`../../assets/img/img_empty.png`)
                "
                :style="item.id === 1 ? { 'object-position': 'right' } : {}"
                alt=""
              /></div
          ></swiper-slide>
        </swiper-container>
        <img
          class="infra-swiper-button infra-swiper-button-prev"
          src="../../assets/ico/intro/arrow_left.png"
          alt=""
        />
        <img
          class="infra-swiper-button infra-swiper-button-next"
          src="../../assets/ico/intro/arrow_right.png"
          alt=""
        />
      </div>
    </div>
  </div>
  <BodyContain
    :style="
      useDisplayRef.isMobile ? `padding-bottom: 50px` : `padding-bottom: 140px`
    "
  >
    <div class="flex-col-center">
      <PretendardText class="intro-title"
        >HUSS ‘크리버스(Creaverse)’ 통합 플랫폼</PretendardText
      >
      <img
        v-if="!useDisplayRef.isMobile"
        class="platform1"
        src="../../assets/img/intro/platform.png"
        alt=""
      />
      <img
        v-if="useDisplayRef.isMobile"
        class="platform1"
        src="../../assets/img/intro/m_platform.png"
        alt=""
      />
      <img
        v-if="!useDisplayRef.isMobile"
        class="platform2"
        src="../../assets/img/intro/platform2.png"
        alt=""
      />
      <img
        v-if="useDisplayRef.isMobile"
        class="platform2"
        src="../../assets/img/intro/m_platform2.png"
        alt=""
      />
    </div>
  </BodyContain>
  <MainFooter />
  <ConsortiumModal1
    v-if="modalNum !== -1"
    :modalNum="modalNum"
    @closeModal="closeModal"
  />
  <InfraModal
    v-if="infroModalNum !== -1"
    :infraModalNum="infroModalNum"
    @closeModal="closeModal"
  />
</template>

<style lang="scss" scoped>
.global-k-box {
  width: 100%;
  height: 300px;
  flex-shrink: 0;
  border-radius: 0 90px 0 90px;
  position: relative;
  margin-bottom: 120px;
  padding: 55px 80px;
  display: flex;
  flex-direction: column;

  @include mobile {
    padding: 20px;
    height: 390px;
    margin-bottom: 60px;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include tablet {
      border-radius: 0 90px 0 90px;
    }
  }

  & .title {
    color: $white;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.6px;

    @include mobile {
      font-size: 16px;
    }
  }

  & .subtitle {
    color: $white;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.6px;

    @include mobile {
      font-size: 14px;
    }
  }

  & .caption {
    color: $white;
    position: relative;
    z-index: 2;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.6px;

    @include mobile {
      font-size: 12px;
    }
  }
}

.intro-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 50px;

  @include mobile {
    font-size: 20px;
    padding-bottom: 15px;
    margin-bottom: 30px;
  }
}

.organization-wrapper {
  display: flex;
  gap: 20px;

  @include small {
    flex-direction: column;
    gap: 10px;
  }

  & .organization-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include small {
      flex-direction: column;
      gap: 10px;
      background: #f7fafc;
      padding: 20px;
      border-radius: 10px;
    }
  }

  .organization-container:first-child {
    background: #ffffff00 !important;
    padding: 0px;
  }

  & .organization-box {
    border-radius: 10px;
    border: 1px solid $line;
    width: 224px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $g04;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.6px;
    position: relative;
    background-color: #fff;

    @include small {
      width: 100%;
      height: 39px;
      font-size: 12px;
    }

    & > img {
      position: absolute;
      top: 6px;
      right: 6px;
      cursor: pointer;

      @include small {
        top: 5px;
        right: 5px;
        width: 16px;
      }
    }
  }

  .organization-item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include small {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px;

      .organization-box {
        height: 39px;
        flex-basis: calc(50% - 2.5px);
      }
    }
  }

  .organization-title {
    @include small {
      border: none;
      justify-content: flex-start;
      background-color: #ffffff00 !important;
      font-size: 14px;
    }
  }
}

.section2 {
  .intro-title {
    @include mobile {
      margin-bottom: 57px !important;
    }
  }

  & .curriculum-wrapper {
    width: 100%;
    height: 311px;
    border-radius: 999px;
    border: 1px solid $line;
    position: relative;

    @include mobile {
      border-radius: 50px;
    }

    @include tablet {
      height: fit-content;
      border-radius: 100px;
    }

    & img {
      position: absolute;
      top: -30px;
    }

    & .circle-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 54px;
      flex-wrap: wrap;

      @include tablet {
        margin-bottom: 30px;
      }
    }

    & .circle {
      width: 224px;
      height: 224px;
      border-radius: 999px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-blend-mode: multiply;
      color: $white;
      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: -0.6px;
      margin: 0 -15px;
      mix-blend-mode: multiply;

      @include mobile {
        width: 120px;
        height: 120px;
        font-size: 14px;
        margin: 0 -10px;
        flex-basis: 120px;
      }
    }

    .circle-m {
      @include mobile {
        margin-top: -20px;
      }
      @include tablet {
        margin-top: -40px;
      }
    }
  }

  .fivec-wrapper {
    margin-top: 40px;
    position: relative;

    .fivec-title {
      z-index: 2;
      margin-bottom: 30px;

      @include mobile {
        width: 268px;
      }
    }

    .fivec-tri {
      position: absolute;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
  }
}

.five-c {
  text-align: center;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 5;
  -webkit-text-stroke-color: $white;
  font-size: 40px;
  font-weight: 700;
  line-height: 120%; /* 48px */
  letter-spacing: -0.6px;
  background: linear-gradient(
    90deg,
    #044a85 1.64%,
    #54966e 47.24%,
    #f6e382 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 40px;
  margin-bottom: 30px;
}

.text-14 {
  color: $g02;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;
}

.text-12 {
  color: $g02;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;
}

.training-wrapper {
  display: flex;
  gap: 10px;
  position: relative;
  flex-wrap: no-wrap;

  @include small {
    flex-wrap: wrap;
  }

  .training-box {
    display: flex;
    min-width: 215px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #f6f6f6;
    position: relative;
    z-index: 2;

    .culture-wrapper {
      @include small {
        flex-direction: column;
      }
    }
  }

  .culture-wrapper-box {
    @include small {
      padding: 16px 19px;
    }
  }

  .training-box-top {
    @include small {
      flex-basis: calc(50% - 5px);
      min-width: unset;
    }
  }

  .training-category-title {
    display: flex;
    padding: 6px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 999px;
    background: $white;
    color: $black;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%; /* 16.8px */
    letter-spacing: -0.6px;
  }

  .map {
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

.univ-wrapper {
  display: flex;
  height: fit-content;
  flex-direction: column;
  width: 250px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $line;
  box-shadow: 0px 4px 18px 0px #cddbe7;
}

.univ-title-wrapper {
  display: flex;
  width: 100%;
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid $line;
  background: #f6f6f6;
  color: $black;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.6px;

  @include mobile {
    padding: 5px 10px;
    font-size: 14px;
  }
}

.univ-box {
  display: flex;
  width: 100%;
  height: 55px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-top: 1px solid $line;
  background: $white;
  border-radius: 0px 0px 10px 10px;

  @include mobile {
    height: 45px;
  }
}

.shapes-box {
  display: flex;
  gap: 4px;
}

.yellow-box {
  display: inline-flex;
  padding: 8px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 999px;
  background: #f6e489;
  margin-top: 123px;
  position: relative;
  z-index: 2;

  @include mobile {
    margin-top: 30px;
  }

  @include tablet {
    margin-top: 60px;
  }
}

.yellow-box-text {
  color: $g02;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 14px;
  }
}

.gradient-box {
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: $gradient;
  margin-bottom: 40px;
}

.gradient-box-text {
  color: $white;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 16px;
  }
}

.univ-yellow-wrapper {
  width: 100%;
  height: 395px;
  border-radius: 10px;
  background: #fffdf3;
  padding: 50px 0;

  @include small {
    padding: 60px 0 0 0;
    height: unset;
  }

  @include tablet {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .m-univ-list {
    width: 100%;

    @include tablet {
      width: 70%;
    }
  }
}

.univ-yellow-text {
  color: $g02;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  margin-bottom: 50px;

  @include mobile {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.univ-wrapper-line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 5px;
  background: linear-gradient(
    90deg,
    rgba(246, 228, 137, 1) 0%,
    rgba(204, 217, 143, 1) 30%,
    rgba(194, 217, 212, 1) 49%,
    rgba(198, 221, 196, 1) 68%,
    rgba(192, 209, 223, 1) 100%
  );
  z-index: 1;
}

.section3 {
  margin-top: 120px;

  @include mobile {
    margin-top: 60px;
  }

  .section3-univ-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 60px;

    .univ {
      display: flex;
      width: 100%;
      padding: 15px 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      background: $primary4;

      .univ-name {
        display: flex;
        padding: 4px 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 999px;
        background: rgba(255, 255, 255, 0.2);
        color: $white;
        text-align: center;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 16.8px */
        letter-spacing: -0.6px;

        @include tablet {
          font-size: 18px;
        }
      }

      .univ-content {
        color: $white;
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        letter-spacing: -0.6px;

        @include tablet {
          font-size: 16px;
        }
      }
    }
  }
}

.section3-title {
  color: $g02;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
  }
}

.section3-subtitle {
  color: $black;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 14px;
  }

  @include tablet {
    font-size: 18px;
  }
}

.gray-line-box {
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid $line;
  background: #fff;
}

.gray-line-box-content {
  width: 300px;
  gap: 20px;

  @include small {
    width: 100%;
  }
}

.gray-line-box-youth {
  width: 880px;
  gap: 20px;

  @include small {
    width: 100%;
  }
}

.circle {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #044a85;
}

.effect-ico {
  width: 90px;
  height: 90px;
}

.expectation-wrapper {
  gap: 20px;
  margin-top: 80px;

  @include mobile {
    gap: 30px;
    margin-top: 60px;
  }

  .expectation-box-wrapper {
    gap: 20px;
    flex-wrap: no-wrap;

    @include mobile {
      flex-wrap: wrap;
      gap: 10px;
    }

    .gray-line-box {
      @include mobile {
        flex-basis: calc(50% - 5px);
      }
    }
  }
}

.section4 {
  background: url("../../assets/ico/intro/vision-bg.png") no-repeat;
  background-position: top center;
  padding-top: 95px;
  width: 100%;
  height: 879px;
  background-color: #82a5c210;

  @include mobile {
    padding: 60px 20px;
    height: fit-content;
    background-position: top 33% center;
    background-size: contain;
  }
  @include tablet {
    padding: 60px 20px;
    background-position: top 50% center;
    background-size: contain;
    height: fit-content;
  }
}

.section4-category {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  background: #fff;
  color: #044a85;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.6px;
  margin-bottom: 20px;

  @include mobile {
    font-size: 14px;
  }
}

.vision-logo {
  @include mobile {
    width: 189px;
    height: 126px;
    margin-top: 30px;
  }
}

.text-16 {
  color: $black;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;
}

.text-24 {
  color: $g01;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;
}

.text-32 {
  color: $black;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.6px;
}

.white-box-wrapper {
  width: 100%;
}

.white-box {
  display: flex;
  width: 100%;
  height: 150px;
  padding: 30px 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid $line;
  background: $white;

  @include mobile {
    padding: 30px 15px;
  }
}

.section5 {
  background-color: #f7fafc;
  padding: 80px 120px;

  @include mobile {
    padding: 0px 20px 60px 20px;
  }

  img {
    @include small {
      width: 100%;
      height: fit-content;
      object-fit: contain;
      object-position: top;
    }
  }
}

.intro-ability {
  width: 1200px;
  height: 560px;

  @include mobile {
    width: 100%;
    height: 100%;
  }
}

.intro-track {
  width: 665px;
  height: 560px;

  @include mobile {
    width: 100%;
    height: 100%;
  }
}

.track-wrapper {
  margin-top: 50px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  gap: 185px;

  @include mobile {
    flex-direction: column;
    gap: 30px;
  }

  @include tablet {
    flex-direction: column;
    gap: 120px;
  }

  & > .track-info {
    & > .track-title-wrapper {
      display: flex;
      align-items: center;
      gap: 20px;

      & > .line {
        height: 1px;
        width: 100%;
        background-color: $g06;

        @include mobile {
        }
      }

      & > .track-title {
        color: #222;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 120%; /* 19.2px */
        letter-spacing: -0.6px;
        min-width: max-content;

        @include mobile {
          font-size: 14px;
        }
      }
    }

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.infra-wrapper {
  border-radius: 10px;
  border: 1px solid $line;
  background-color: #fff;
  width: 230px;
  cursor: pointer;

  @include small {
    flex: 0 0 auto !important;
  }

  & > .title-box {
    @extend .flex-col-center;
    padding: 18px 24px;
    gap: 5px;
    height: 100px;
  }

  & img {
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-radius: 0 0 10px 10px;
  }
}

.platform1 {
  width: 100%;
  height: 280px;

  @include small {
    height: fit-content;
    object-fit: contain;
  }
}

.platform2 {
  width: 100%;
  height: 490px;
  margin-top: 50px;

  @include small {
    margin-top: 30px;
    height: fit-content;
    object-fit: contain;
  }
}

.m-culture-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-top: 30px;

  @include tablet {
    margin-top: 60px;
  }
}

.infra {
  width: 100%;
  max-width: 1200px;
}

.infra-container {
  display: flex;
  gap: 13px;
  width: 100%;
  position: relative;
  justify-content: center;

  @include small {
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.infra-container::-webkit-scrollbar {
  display: none;
}

.swiper-slide {
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.infra-swiper-button {
  cursor: pointer;
  position: absolute;
  top: 45%;
  z-index: 10;
  width: 24px;
  height: 24px;

  @include mobile {
  }
}

.infra-swiper-button-prev {
  left: -24px;

  @include mobile {
    left: 0;
  }
}

.infra-swiper-button-next {
  right: -24px;

  @include mobile {
    right: 0;
  }
}
</style>
