import api from "./api";

export default class ReferenceApi {
  static async get(data) {
    return await api.get(`references`, data);
  }

  static async getById(id) {
    return await api.get(`reference/view/${id}`);
  }
}
