<script setup>
import { defineEmits, ref, onMounted } from "vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import TextInput from "@/components/atoms/input/textInput.vue";
import TextArea from "@/components/atoms/input/textArea.vue";
import SelectBox from "@/components/molcules/input/selectBox.vue";
import FileInput from "@/components/atoms/input/fileInput.vue";
import RadioInput from "@/components/atoms/input/radioInput.vue";
import ContactApi from "@/api/contact";

import { useToast } from "vue-toastification";
const toast = useToast();

const privacy = ref("");
const name = ref("");
const email1 = ref("");
const email2 = ref("");
const tel1 = ref("");
const tel2 = ref("");
const tel3 = ref("");
const content = ref("");
const university = ref({ name: "대학선택", value: "NONE" });
const domain = ref({ name: "도메인 선택", value: "NONE" });
const file = ref("");

const univList = ref([
  { name: "단국대학교", value: "DANKOOK" },
  { name: "동서대학교", value: "DONGSEO" },
  { name: "원광대학교", value: "WONKWANG" },
  { name: "청강대학교", value: "CHUNGKANG" },
  { name: "한서대학교", value: "HANSEO" },
]);

const domainList = ref([
  { name: "naver.com", value: "NAVER" },
  { name: "nate.com", value: "NATE" },
  { name: "hanmail.net", value: "HANMAIL" },
  { name: "hotmail.com", value: "HOTMAIL" },
  { name: "yahoo.co.kr", value: "YAHOO" },
  { name: "korea.com", value: "KOREA" },
  { name: "gmail.com", value: "GMAIL" },
  { name: "직접입력", value: "DIRECT" },
]);

const setUniv = (selectedUniv) => {
  university.value = selectedUniv;
};

const setDomain = (selectedDomain) => {
  domain.value = selectedDomain;
  if (selectedDomain.value === "DIRECT") {
    return;
  }
  email2.value = selectedDomain.name;
};

const changeFile = (data) => {
  file.value = data;
};

const emit = defineEmits(["closeModal"]);

const postContact = async () => {
  try {
    // // reCAPTCHA 통과 여부 확인
    // if (!isRecaptchaPassed()) {
    //   toast("reCAPTCHA를 통과해주세요.", {
    //     toastClassName: "toast-wrapper",
    //     position: "top-center",
    //     timeout: 2000,
    //     hideProgressBar: true,
    //     closeButton: false,
    //     icon: false,
    //   });
    //   return;
    // }

    if (!privacy.value) {
      toast("개인정보처리방침에 동의해주세요.", {
        toastClassName: "toast-wrapper",
        position: "top-center",
        timeout: 2000,
        hideProgressBar: true,
        closeButton: false,
        icon: false,
      });
      return;
    }
    if (
      !name.value ||
      !email1.value ||
      !email2.value ||
      !tel1.value ||
      !tel2.value ||
      !tel3.value ||
      !content.value
    ) {
      toast("필수 정보를 모두 입력해주세요.", {
        toastClassName: "toast-wrapper",
        position: "top-center",
        timeout: 2000,
        hideProgressBar: true,
        closeButton: false,
        icon: false,
      });

      return;
    }
    const formData = new FormData();
    formData.append(`name`, name.value);
    formData.append(`email`, `${email1.value}@${email2.value}`);
    formData.append(`phoneNumber`, `${tel1.value}-${tel2.value}-${tel3.value}`);
    formData.append(`content`, content.value);
    formData.append(`school`, university.value?.value);
    if (file.value) {
      formData.append(`file`, file.value);
    }

    await ContactApi.post(formData);
    toast("문의가 완료되었습니다.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

onMounted(() => {
  const script = document.createElement("script");
  script.src = "https://www.google.com/recaptcha/api.js";
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
});
</script>

<template>
  <div class="modal-dim">
    <div class="modal-scroll-container">
      <div class="modal-container">
        <div class="flex-row-between top-wrapper">
          <PretendardText class="text-16" style="font-weight: 700"
            >문의하기</PretendardText
          >
          <img
            src="../../../assets/ico/x.png"
            style="object-fit: contain; cursor: pointer"
            alt=""
            @click="emit('closeModal')"
          />
        </div>
        <div class="privacy-box">
          <PretendardText class="text-14">
            개인정보 수집·이용에 관한 사항(필수)<br />
            문의 상담을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br />
            1. 수집항목(필수) : 이름, 휴대전화, 이메일<br />
            2. 이용 목적 : 상담서비스 진행 및 관련 정보 제공을 위한 안내와
            문자(SMS) 발송<br />
            3. 보유 기간 : 이용 목적 달성시까지 (최대 3년 보관)<br />
            * 위의 개인정보 수집, 이용에 대한 동의를 거부할 권리가 있습니다.
            그러나 동의를 거부하실 경우 상담 서비스 신청이 불가합니다.
          </PretendardText>
          <div class="checkbox-container">
            <RadioInput
              v-model="privacy"
              :value="true"
              label="동의합니다."
              id="privacy"
            />
            <RadioInput
              v-model="privacy"
              :value="false"
              label="동의하지 않습니다."
              id="un-privacy"
            />
          </div>
        </div>
        <div class="input-wrapper" style="padding-top: 20px">
          <PretendardText class="input-title"
            >대학<span class="star">*</span></PretendardText
          >
          <SelectBox
            :list="univList"
            @setValue="setUniv"
            :value="university.name"
          />
        </div>
        <div class="input-wrapper">
          <PretendardText class="input-title"
            >이름<span class="star">*</span></PretendardText
          >
          <TextInput v-model="name" placeholder="이름을 입력하세요." />
        </div>
        <div class="input-wrapper">
          <PretendardText class="input-title"
            >이메일<span class="star">*</span></PretendardText
          >
          <div class="multi-input-wrapper email-input-wrapper">
            <div class="email-wrapper">
              <TextInput v-model="email1" placeholder="이메일 입력" />
              <PretendardText class="separator">@</PretendardText>
              <TextInput
                v-model="email2"
                :disabled="domain.value !== `DIRECT`"
              />
            </div>
            <SelectBox
              class="email-select"
              :list="domainList"
              @setValue="setDomain"
              :value="domain.name"
            />
          </div>
        </div>
        <div class="input-wrapper">
          <PretendardText class="input-title"
            >연락처<span class="star">*</span></PretendardText
          >
          <div class="multi-input-wrapper">
            <TextInput v-model="tel1" />
            <PretendardText class="separator">-</PretendardText>
            <TextInput v-model="tel2" />
            <PretendardText class="separator">-</PretendardText>
            <TextInput v-model="tel3" />
          </div>
        </div>
        <div class="input-wrapper" style="align-items: flex-start">
          <PretendardText class="input-title"
            >내용<span class="star">*</span></PretendardText
          >
          <TextArea
            v-model="content"
            placeholder="이름을 입력하세요."
            inputStyle="height: 136px"
          />
        </div>
        <div class="input-wrapper">
          <PretendardText class="input-title">첨부파일</PretendardText>
          <FileInput @changeFile="changeFile" />
        </div>
        <div class="recaptcha-wrapper">
          <div
            class="g-recaptcha"
            data-sitekey="6Lf_wb0pAAAAADjMmUASl4xhLc7mlH4UbOG3cXoo"
          ></div>
        </div>
        <div
          class="flex-row-align-center"
          style="width: 100%; margin-top: 15px; justify-content: center"
        >
          <div class="btn" @click="postContact">
            <PretendardText class="text-16" style="color: #fff"
              >문의하기</PretendardText
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-scroll-container {
  width: 100%;
  /* max-height: 100vh; */
  display: flex;
  padding: 64px 0;
  justify-content: center;
  overflow: auto;

  @include mobile {
    padding: 27px 20px;
  }
}

.modal-container {
  width: 100%;
  max-width: 750px;
  height: fit-content;
  border-radius: 10px;
  background-color: $white;
  padding: 20px 0px;

  @include mobile {
    padding: 0;
    height: fit-content;
    padding-bottom: 20px;
  }
}

.top-wrapper {
  padding: 0 24px;
  margin-bottom: 15px;
  align-items: center;

  @include mobile {
    padding: 20px 24px;
    margin-bottom: 0px;
  }
}

.privacy-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #f7fafc;
  padding: 20px 24px;

  .checkbox-container {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
  }

  @include mobile {
    padding: 15px;
  }

  .text-14 {
    @include mobile {
      font-size: 11px;
    }
  }
}

.text-24 {
  color: $g02;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;
}

.text-16 {
  color: $g02;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 14px;
  }
}

.text-14 {
  color: $g03;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.6px;
}

.title-wrapper {
  padding: 15px 4px;
  display: flex;
  align-items: center;
  gap: 6px;

  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $primary4;
  }
}

.univ-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 216px;

  .univ-box {
    width: 100%;
    height: 155.72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $g08;
  }

  .name-wrapper {
    padding: 10px 0 15px 0;
    display: flex;
    justify-content: center;
  }
}

.btn {
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  background: $primary4;
  cursor: pointer;
}

.input-wrapper {
  display: flex;
  align-items: center;
  padding: 10px 24px;

  @include mobile {
    padding: 10px 15px;
  }

  .input-title {
    min-width: 80px;
    color: $g02;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.6px;

    @include mobile {
      font-size: 14px;
      min-width: 65px;
    }

    .star {
      color: $primary4;
    }
  }
}

.multi-input-wrapper {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;

  .separator {
    color: $g02;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.6px;
  }

  .email-wrapper {
    display: flex;
    flex: 2.2;
    gap: 10px;
    align-items: center;
  }

  .email-select {
    flex: 1;
  }
}

.email-input-wrapper {
  @include mobile {
    flex-direction: column;
  }
}

.recaptcha-wrapper {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}
</style>
