<script setup>
import { ref, onBeforeMount } from "vue";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
// import VisionUniv from "@/components/organisms/intro/visionUniv.vue";
import VisionUniv2 from "@/components/organisms/intro/visionUniv2.vue";
import { route } from "@/router/route";
import { displayHooks } from "@/hooks/useDisplay";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
const toast = useToast();

let useDisplayRef = ref(displayHooks);
let menuValue = ref("");

let mainSectionMenu = ref([]);

onBeforeMount(() => {
  getMenu();
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[0].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`intro${index + 1}`],
          });
        }

        if (item.homeLink === "/intro2") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <SubpageHeader />
  <MainSection
    bgUrl="intro"
    :options="mainSectionMenu"
    :menuValue="menuValue"
    mainHeader="사업소개"
    subHeader01="Home"
    subHeader02="사업소개"
  />

  <BodyContain
    :style="
      useDisplayRef.isMobile ? `padding: 60px 0` : `padding-bottom: 120px`
    "
  >
    <div
      class="flex-col"
      :style="useDisplayRef.isMobile ? `padding: 0 20px` : ``"
    >
      <PretendardText class="intro-title">비전 및 목표</PretendardText>
      <PretendardText class="text-16" :style="{ marginBottom: `50px` }"
        >컨소시엄 대학별 특성화 요소를 바탕으로 글로벌 K-웨이브
        <br v-if="useDisplayRef.isMobile" />교육과정을 공동 개발하고 영역별
        특성화된 인재 양성합니다.</PretendardText
      >
      <div class="vision-table">
        <img src="../../../assets/img/intro/vision_table.png" alt="" />
      </div>
    </div>
    <div
      class="flex-col"
      :style="useDisplayRef.isMobile ? `padding: 0 20px` : ``"
    >
      <PretendardText class="text-24" :style="{ marginTop: `80px` }"
        >컨소시엄 사업단 비전</PretendardText
      >
      <PretendardText
        class="text-16"
        style="margin-top: 20px; margin-bottom: 50px"
        >대학별 비전과 교육목표를 기반으로 상호 연계한
        <br v-if="useDisplayRef.isMobile" />컨소시엄 사업단 비전을
        제시합니다.</PretendardText
      >
    </div>
    <VisionUniv2 />
    <div v-if="!useDisplayRef.isMobile" class="univ-logo-wrapper">
      <div class="univ-logo">
        <img src="../../../assets/img/intro/dku.png" alt="" />
      </div>
      <div class="univ-logo">
        <img src="../../../assets/img/intro/dsu.png" alt="" />
      </div>
      <div class="univ-logo">
        <img src="../../../assets/img/intro/wku.png" alt="" />
      </div>
      <div class="univ-logo">
        <img src="../../../assets/img/intro/cku.png" alt="" />
      </div>
      <div class="univ-logo">
        <img src="../../../assets/img/intro/hsu.png" alt="" />
      </div>
    </div>
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.intro-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 50px;

  @include mobile {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.text-16 {
  color: $g03;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 14px;
  }
}

.text-24 {
  color: $g02;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;
}

.vision-table {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  padding-bottom: 30px;

  img {
    width: 100%;

    @include mobile {
      width: unset;
      height: 310px;
    }
  }
}

.vision-table::-webkit-scrollbar {
  width: 18px; /* 스크롤바의 너비 */
}

.vision-table::-webkit-scrollbar-thumb {
  width: 6px;
  background: $primary4; /* 스크롤바의 색상 */
  border-radius: 999px;
  border: 6px solid #ffffff;
}

.vision-table::-webkit-scrollbar-track {
  border-radius: 999px;
  background: #ffffff;
  border: 6px solid #ffffff;
}

.univ-logo-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 50px;
  padding: 29px 0;
  border-top: 1px solid $line;
  border-bottom: 1px solid $line;

  .logo-box {
    width: 220px;
    height: 55px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
