<script setup>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { consortium } from "@/constants/consortium";
import { defineProps, defineEmits, ref } from "vue";
import { onClickOutside } from "@vueuse/core";
const emit = defineEmits(["closeModal"]);

const target = ref(null);

onClickOutside(target, () => {
  emit("closeModal");
});

const props = defineProps({
  modalNum: Number,
});
</script>

<template>
  <div class="modal-dim">
    <div ref="target" class="modal-container">
      <div class="flex-row-between modal-title-wrapper">
        <PretendardText class="title">{{
          consortium[props.modalNum]?.title
        }}</PretendardText>
        <img
          src="../../../../assets/ico/x.png"
          alt=""
          @click="emit('closeModal')"
        />
      </div>
      <div class="flex-col modal-list-wrapper">
        <div
          v-for="(item, index) in consortium[props.modalNum]?.list"
          :key="index"
          class="modal-list flex-row-align-center"
        >
          <div class="circle" />
          <PretendardText class="content">{{ item }}</PretendardText>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-dim {
  align-items: center;
}

.modal-container {
  width: 400px;
  border-radius: 10px;
  background-color: $white;
}

.modal-title-wrapper {
  padding: 20px 24px;
  align-items: center;
  border-bottom: 1px solid $line;

  & .title {
    color: $g01;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.6px;
  }

  & img {
    cursor: pointer;
  }
}

.modal-list-wrapper {
  gap: 8px;
  padding: 15px 24px;

  .modal-list {
    gap: 6px;
  }

  & .circle {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #044a85;
  }

  & .content {
    color: $g04;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.6px;
    white-space: pre-wrap;
  }
}
</style>
