<template>
  <div class="title-wrapper">
    <PretendardText class="title">{{ title }}</PretendardText>
    <img src="../../../assets/ico/btn/summary_title_arrow.png" alt="" @click="menuRouter" />
  </div>
</template>

<script>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { router } from "@/router";

export default {
  name: "SummaryTitle",
  components: { PretendardText },
  props: {
    title: String,
    link: String,
  },
  data() {
    return {};
  },
  methods: {
    menuRouter() {
      router.push({ path: this.link });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & img {
    cursor: pointer;
  }
}
.title {
  color: #044a85;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.6px;
}
</style>
