<script setup>
import {ref, onBeforeMount, defineProps, onMounted} from "vue";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { displayHooks } from "@/hooks/useDisplay";
import { router } from "@/router";
import { route } from "@/router/route";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
import SubjectApi from "@/api/subject";
const toast = useToast();

let useDisplayRef = ref(displayHooks);
let menuValue = ref("");

let mainSectionMenu = ref([]);

const info = ref({});

const props = defineProps({
  id: String,
  sbcCd: String,
  sbcNm: String,
  majrCd: String,
  trkCd: String,
  year: String,
  //
  level: String,
  credit: String,
  teacher: String
});

onBeforeMount(() => {
  getMenu();
  // getInfo();
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[1].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`curriculum${index + 1}`],
          });
        }

        if (item.homeLink === "/curriculum02") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
onMounted(() => {
  setData();
});
const course = ref({
  id: 0,
  courseCategory: '',
  courseCategoryTitle: '',
  detailCategory: '',
  detailCategoryTitle: '',
  englishTitle: '',
  grade: '',
  instructor: '',
  level: '',
  livingLabCategory: 'AR',
  livingLabCategoryTitle: '',
  semester: '',
  semesterCategory: 'ONE',
  textbook2Flag: true,
  textbook2Title: true,
  textbookFlag: true,
  textbookTitle: true,
  title: '',
  useFlag: true,
  courseSchedules: [],
  operAcplCd: '',
  rgsrNm: '',
  t:'',
})
const clasStleMapping = {
  "0000190001": "오프라인",
  "0000190002": "온라인 녹화강의",
  "0000190003": "온라인 실시간강의(라이브)",
  "0000190004": "온라인 실시간강의(홀로그램)",
  "0000190005": "온라인 실감형콘텐츠(AR)",
  "0000190006": "온라인 실감형콘텐츠(VR)",
  "0000190008": "온/오프라인 혼합"
};

async function setData() {
  try {
    const response = await SubjectApi.getOperAcplListForExternal(props);
    const operAcplCdList = response.data.result.list.map(item => item.operAcplCd);
    course.value.operAcplCd = operAcplCdList;
    course.value.rgsrNm = response.data.result.list.rgsrNm;
    const operAcplDataParams = {
      ...props,
      operAcplCd: course.value.operAcplCd
    };

    const operResponse = await SubjectApi.getOperAcplDataForExternal(operAcplDataParams);
    const data = operResponse.data.result
    course.value.courseCategoryTitle = data.operAcplInfo.sbcKrnm
    course.value.grade = data.operAcplInfo.gdptNm
    course.value.level = data.operAcplInfo.grdNm
    data.bookList.forEach(book => {
      course.value.textbookTitle = book.bookTy == '1' ? (book.tcmnKr || '-') : course.value.textbookTitle;
      course.value.textbook2Title = book.bookTy == '2' ? (book.tcmnKr || '-') : course.value.textbook2Title;
    });
    course.value.semester = data.operAcplInfo.edcCrseYear
    course.value.semesterCategory = data.operAcplInfo.edcCrseSmstNm
    course.value.courseSchedules = data.weekList.map(item => {
      const clasStleText = clasStleMapping[item.clasStle] || item.clasStle;
      return {
        ...item,
        clasStle: clasStleText,
      };
    })
    course.value.t = [...new Set(data.weekList.map(i => {
      const pfList = JSON.parse(i.pfList);
      return pfList.length > 0 ? pfList[0].usr_krnm : null;
    }).filter(item => item !== null))].join(', ');

  }
  catch (error) {
    console.log(error)
  }
}

const goMenu = () => {
  router.push(route.curriculum2);
};
const goLms = () => {
  window.location.href = "https://portal.k-culture.ac.kr/";
}

</script>

<template>
  <SubpageHeader />
  <MainSection
      bgUrl="curriculum"
      :options="mainSectionMenu"
      :menuValue="menuValue"
      mainHeader="교육과정"
      subHeader01="Home"
      subHeader02="교육과정"
  />

  <BodyContain style="padding-bottom: 141px">
    <PretendardText class="intro-title">{{  course.courseCategoryTitle }}</PretendardText>
    <PretendardText
        v-if="info.englishTitle"
        class="text-16"
        :style="
        useDisplayRef.isMobile ? `margin-bottom: 30px` : `margin-bottom: 50px`
      "
    >{{ info.englishTitle }}</PretendardText
    >
    <PretendardText class="text-24">기본정보</PretendardText>
    <div class="table">
      <div class="row">
        <div class="title-cell cell">제목</div>
        <div class="cell">{{ course.courseCategoryTitle || sbcNm }}</div>
      </div>
      <div class="row">
        <div class="title-cell cell">수준</div>
        <div class="cell">{{ course.level || level}}</div>
      </div>
      <div class="row">
        <div class="title-cell cell">학점</div>
        <div class="cell">{{ course.grade || credit}}</div>
      </div>
      <div class="row">
        <div class="title-cell cell">교강사</div>
        <div class="cell">{{ course.t || teacher }}</div>
      </div>
    </div>
    <PretendardText class="text-24" style="margin-top: 80px"
    >수업 진행 계획</PretendardText
    >
<!--    <div class="table-scroll" v-if="info?.courseSchedules?.length > 0">-->
      <div class="table-scroll">
      <div class="scedule-table">
        <div class="row">
          <div class="title-cell cell first">No.</div>
          <div class="title-cell cell second">수업 내용</div>
          <div class="title-cell cell third">담당자</div>
          <div class="title-cell cell fourth">수업방식</div>
        </div>
        <div v-for="(item, index) in course.courseSchedules" :key="item.id" class="row content-row">
          <div class="cell first">{{ index + 1 }}</div>
          <div class="cell second">{{ item.clasSbjcKr }}</div>
          <div class="cell third">
            {{ (item.pfList && JSON.parse(item.pfList).length > 0 && JSON.parse(item.pfList)[0].usr_krnm)
              ? JSON.parse(item.pfList)[0].usr_krnm : "-" }}
          </div>
          <div class="cell fourth">
            {{
              item.clasStle
            }}
          </div>
        </div>
      </div>
    </div>
    <div
        style="
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;
      "
    >
      <div class="list-btn" @click="goMenu">목록</div>
      <div class="lms-btn" @click="goLms">학습플랫폼(LMS) 바로가기</div>
    </div>
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.intro-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 20px;

  @include mobile {
    font-size: 20px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

.text-16 {
  color: $g03;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.6px;
}

.text-24 {
  color: $g02;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;
  margin-bottom: 20px;

  @include mobile {
    font-size: 16px;
  }
}

.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  /* border-top: 1px solid $line; */
  /* border-bottom: 1px solid $line; */
}

.row {
  display: table-row;
  height: 80px;

  @include mobile {
    height: 50px;
  }

  .cell {
    width: 100%;
    height: 80px;
    display: table-cell;
    border: 1px solid $line;
    border-right: 0px;
    border-top: 0px;
    padding: 10px 20px;
    color: $g01;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.6px;

    @include mobile {
      font-size: 14px;
      height: 50px;
    }
  }

  .cell:first-child,
  .cell:nth-child(2) {
    border-top: 1px solid $line;
  }

  .title-cell {
    width: 150px;
    height: 80px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-left: 0px;

    @include mobile {
      width: 80px;
      height: 50px;
    }
  }
}

.scedule-table {
  display: table;
  width: 100%;
  border-collapse: collapse;

  overflow: auto;

  .row {
    width: 100%;
    display: flex;
  }

  .first {
    width: 100px;
  }

  .second {
    width: 660px;
  }

  .third {
    width: 150px;
  }

  .fourth {
    width: 290px;
  }

  .content-row {
    .first,
    .second {
      font-weight: 700;
    }

    .cell {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .second {
      justify-content: flex-start;
    }
  }
}

.table-scroll {
  @include mobile {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 30px;
  }
}

.table-scroll::-webkit-scrollbar {
  width: 18px; /* 스크롤바의 너비 */
}

.table-scroll::-webkit-scrollbar-thumb {
  width: 6px;
  background: $primary4; /* 스크롤바의 색상 */
  border-radius: 999px;
  border: 6px solid #ffffff;
}

.table-scroll::-webkit-scrollbar-track {
  border-radius: 999px;
  background: #ffffff;
  border: 6px solid #ffffff;
}

.list-btn {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  border: 1px solid $g05;
  font-family: Pretendard;
  color: $primary4;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.6px;
  cursor: pointer;
}

.lms-btn {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  border: 1px solid #54966e;
  font-family: Pretendard;
  color: #54966e;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.6px;
  cursor: pointer;
}
</style>
