<script setup>
import { defineProps, toRefs, ref, defineModel, defineEmits } from "vue";

const model = defineModel();
const emit = defineEmits(["click"]);
const props = defineProps({
  style: [String, Object],
  placeholder: String,
});
const { style, placeholder } = toRefs(props);

let input_flag = ref("#e5e8eb");
</script>

<template>
  <div class="conatiner-wrapper" :style="style">
    <input
      type="text"
      class="input-container"
      :placeholder="placeholder"
      v-model="model"
      @keyup.enter="emit('click')"
      @focus="input_flag = '#007CFF'"
      @blur="input_flag = '#e5e8eb'"
    />
    <img
      @click="emit('click')"
      src="@/assets/ico/plaza/search.svg"
      style="width: 20px; height: 20px; cursor: pointer"
    />
  </div>
</template>

<style lang="scss" scoped>
.conatiner-wrapper {
  width: 335px;
  max-height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 17.5px 10px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid v-bind(input_flag);
  border-radius: 6px;
}

@include mobile {
  .conatiner-wrapper {
    width: 100%;
    max-height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 17.5px 10px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid v-bind(input_flag);
    border-radius: 6px;
  }
}

.input-container {
  flex: 1;
  border: 0px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #191f28;

  &:focus {
    background: #fff;
    outline: none;

    border: 0px;
  }

  &::placeholder {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    color: #717377;
  }
}
</style>
