import api from "./api";

export default class PressApi {
  static async get(data) {
    return await api.get(`presses`, data);
  }

  static async getById(id) {
    return await api.get(`press/view/${id}`);
  }
}
