<script setup>
import { defineProps, toRefs, ref } from "vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";

const props = defineProps({
  title: String,
  content: String,
  type: String,
});
const { title, content } = toRefs(props);

const isOpen = ref(false);
</script>

<template>
  <div class="accordian-wrapper">
    <div class="title-wrapper">
      <div class="title-container">
        <div class="type-wrappr">
          {{ type }}
        </div>
        <PretendardText class="title">{{ title }}</PretendardText>
      </div>
      <img
        :class="isOpen ? 'icon rotateToBottom' : 'icon'"
        src="@/assets/ico/plaza/down_arrow.svg"
        @click="isOpen = !isOpen"
      />
    </div>
    <div v-if="isOpen" class="content-wrapper">
      <div class="content-container">
        <PretendardText class="content">{{ content }}</PretendardText>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.accordian-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  & .title-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px 15px;
    border-top: 1px solid #ebebeb;
    box-sizing: border-box;
    gap: 10px;

    & .title-container {
      display: flex;
      align-items: center;
      align-items: center;

      & .title {
        color: #282828;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.2px;
        letter-spacing: -0.6px;
        margin-left: 10px;
      }
    }

    & > .icon {
      width: 24px;
      height: 24px;
      transition: all ease 0.2s;
      cursor: pointer;

      &.rotateToBottom {
        transform: rotate(180deg);
      }
    }
  }

  & .content-wrapper {
    width: 100%;
    padding: 20px 15px;
    background: #f9f9f9;
    box-sizing: border-box;

    & .content-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      & .content {
        color: #4d5054;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: -0.6px;
        margin-left: 10px;

        white-space: pre-wrap;
      }
    }
  }
}

@include mobile {
  .accordian-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    & .title-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 10px;
      border-top: 1px solid #ebebeb;
      box-sizing: border-box;

      & .title-container {
        display: flex;
        align-items: center;

        & .title {
          color: #282828;
          font-size: 14px;
          font-weight: 500;
          line-height: 20.8px;
          letter-spacing: -0.6px;
          margin-left: 10px;
        }
      }

      & > .icon {
        width: 24px;
        height: 24px;
        transition: all ease 0.2s;
        cursor: pointer;

        &.rotateToBottom {
          transform: rotate(180deg);
        }
      }
    }

    & .content-wrapper {
      width: 100%;
      padding: 20px 10px;

      background: #f9f9f9;
      box-sizing: border-box;

      & .content-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        & .content {
          color: #4d5054;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: -0.6px;
          margin-left: 10px;

          white-space: pre-wrap;
        }
      }
    }
  }
}

.type-wrappr {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #044a85;
  color: $white;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.6px;
  min-width: fit-content;
}
</style>
