import api from "./api";

export default class FaqApi {
  static async get(data) {
    return await api.get(`faqs`, data);
  }

  static async getById(id) {
    return await api.get(`faq/${id}`);
  }
}
