<script setup>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { defineEmits, defineProps, ref } from "vue";
import { onClickOutside } from "@vueuse/core";
const emit = defineEmits(["closeModal"]);

const target = ref(null);

onClickOutside(target, () => {
  emit("closeModal");
});

const props = defineProps(["modalValue"]);
</script>

<template>
  <div class="modal-dim">
    <div class="modal-scroll-container">
      <div ref="target" class="modal-container">
        <div
          class="flex-row-between"
          style="padding: 0 9px; margin-bottom: 15px"
        >
          <PretendardText class="text-16">상세보기</PretendardText>
          <img
            src="../../../../assets/ico/x.png"
            style="object-fit: contain; cursor: pointer"
            alt=""
            @click="emit('closeModal')"
          />
        </div>
        <div class="img-wrapper">
          <img class="univ-logo" :src="props.modalValue?.imageUrl" alt="" />
        </div>
        <div class="section1">
          <div class="gray-box">
            <PretendardText class="title">공유 기능 및 역할</PretendardText>
            <div style="gap: 4px">
              <PretendardText class="text-14">{{
                props.modalValue?.shareContent
              }}</PretendardText>
            </div>
          </div>
          <div class="gray-box">
            <PretendardText class="title">SNS 운영 현황 및 주소</PretendardText>
            <div class="flex-row-align-center" style="gap: 8px">
              <a :href="props.modalValue?.homepageLink" target="blank">
                <img
                  src="../../../../assets/ico/intro/homepage_white.png"
                  alt=""
                />
              </a>
              <a :href="props.modalValue?.blogLink" target="blank">
                <img src="../../../../assets/ico/intro/blog_white.png" alt="" />
              </a>
              <a :href="props.modalValue?.youtubeLink" target="blank">
                <img
                  src="../../../../assets/ico/intro/youtube_white.png"
                  alt=""
                />
              </a>
              <a :href="props.modalValue?.instagramLink" target="blank">
                <img
                  src="../../../../assets/ico/intro/insta_white.png"
                  alt=""
                />
              </a>
              <a :href="`tel:${props.modalValue?.mainNumber}`">
                <img src="../../../../assets/ico/intro/tel_white.png" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div class="gray-line-box">
          <PretendardText class="title">조직도</PretendardText>
          <img
            v-if="props.modalValue?.fileUrl"
            :src="props.modalValue?.fileUrl"
            alt=""
          />
          <PretendardText v-else class="empty-text"
            >내용이 없습니다.</PretendardText
          >
        </div>
        <div class="gray-box">
          <PretendardText class="title">서포터즈 운영현황</PretendardText>
          <div class="flex-col" style="gap: 4px">
            <PretendardText
              class="supporter-text"
              :class="
                props.modalValue?.supporterOperate ? `text-14` : `empty-text`
              "
              >{{
                props.modalValue?.supporterOperate || "내용이 없습니다."
              }}</PretendardText
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-scroll-container {
  width: 100%;
  max-height: 100vh;
  display: flex;
  padding: 64px 0;
  justify-content: center;
  align-items: center;
  overflow: auto;

  @include mobile {
    padding: 27px 20px;
  }
}

.modal-container {
  width: 100%;
  max-width: 800px;
  height: fit-content;
  border-radius: 10px;
  background-color: $white;
  padding: 20px;
  overflow: auto;

  @include mobile {
    overflow: unset;
    /* height: fit-content; */
    padding: 15px;
  }
}

.text-14 {
  color: $g03;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.6px;
}

.title {
  @extend .text-14;
  font-weight: 600;
  color: $black;
}

.text-16 {
  color: $g01;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;
}

.gray-box {
  width: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  background: #f6f6f6;
  box-sizing: border-box;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.gray-line-box {
  width: 100%;
  display: flex;
  padding: 10px 15px;
  flex-direction: column;
  gap: 8px;
  background: #fff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  margin-bottom: 20px;

  @include mobile {
    margin-bottom: 10px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.img-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.univ-logo {
  width: 202px;
  height: 62px;
  object-fit: contain;
}

.section1 {
  display: flex;
  gap: 10px;
  height: 150px;
  margin-bottom: 20px;

  @include mobile {
    gap: 20px;
    height: unset;
    flex-direction: column;
    margin-bottom: 10px;
  }
}

.empty-text {
  color: $g03;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.6px;
}

.supporter-text {
  white-space: pre-wrap;
}
</style>
