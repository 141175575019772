<script setup>
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import { onBeforeMount, ref } from "vue";
import PrivacyApi from "@/api/privacy";
import { useToast } from "vue-toastification";
const toast = useToast();

const privacyInfo = ref({});

onBeforeMount(() => {
  getApi(true);
});

const getApi = async () => {
  try {
    const response = await PrivacyApi.get();
    if (response.data.data) {
      privacyInfo.value = response.data.data;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <SubpageHeader />
  <div class="section-wrapper">
    <div>
      <PretendardText class="caption">Home</PretendardText>
      <div class="vertical-bar" />
      <PretendardText class="caption">개인정보처리방침</PretendardText>
    </div>
    <PretendardText class="title">개인정보처리방침</PretendardText>
  </div>
  <div class="privacy-wrapper">
    <div class="conatiner">
      <pretendardText class="content1"
        >글로벌 K-컬처 선도 융합인재양성 컨소시엄 사업단>(이하 “글로벌 K-컬처
        컨소시엄 사업단”)은(는)<br />「개인정보 보호법」 제30조에 따라
        정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게
        처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
        수립·공개합니다.</pretendardText
      >
      <pretendardText class="content2"
        ><div v-html="privacyInfo.content"></div
      ></pretendardText>
    </div>
  </div>
  <MainFooter />
</template>

<style lang="scss" scoped>
.section-wrapper {
  width: 100%;
  height: 450px;
  background: url("../../assets/img/topSection/privacy.png") center center
    no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mobile {
    height: 260px;
    background: url("../../assets/img/topSection/privacy_m.png") center center
      no-repeat;
  }

  & > div {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    @include mobile {
      margin-bottom: 15px;
    }

    & > .vertical-bar {
      width: 1px;
      height: 10px;
      background-color: $g05;
    }
  }

  & .caption {
    color: $white;
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.6px;
  }

  & .title {
    color: $white;
    font-size: 45px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.6px;

    @include mobile {
      font-size: 32px;
    }
  }
}

.privacy-wrapper {
  padding: 120px 0 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    padding: 60px 20px;
  }

  & > .conatiner {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    & > .content1 {
      color: $g01;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: -0.6px;
      margin-bottom: 20px;

      @include mobile {
        font-size: 14px;
        margin-bottom: 15px;
      }
    }

    & > .content2 {
      color: $g01;
      font-size: 14px;
      font-weight: 300;
      line-height: 140%;
      letter-spacing: -0.6px;

      @include mobile {
        font-size: 12px;
      }
    }
  }
}
</style>
