<script setup>
import { ref } from "vue";
import { register } from "swiper/element/bundle";

register();

const activeIndex = ref(0);
const swiper = ref(null);

const onSlideChange = () => {
  activeIndex.value = swiper.value?.swiper.realIndex;
};

const images = ref(["univ1", "univ2", "univ3", "univ4", "univ5"]);
</script>

<template>
  <swiper-container
    ref="swiper"
    :slides-per-view="1"
    :loop="true"
    :centered-slides="true"
    @swiperslidechange="onSlideChange"
    :navigation="{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }"
    :breakpoints="{
      768: {
        slidesPerView: 3,
      },
    }"
  >
    <swiper-slide
      v-for="(item, index) in images"
      :key="index"
      class="swiper-slide"
      ><div>
        <img
          :src="
            require(`@/assets/img/intro/${item}_${
              activeIndex === index ? `active` : `deactive`
            }.png`)
          "
          alt=""
        /></div
    ></swiper-slide>
  </swiper-container>
  <div class="btn-wrapper">
    <img
      class="swiper-button swiper-button-prev"
      src="../../../assets/ico/intro/arrow_left.png"
      alt=""
    />
    <img
      class="swiper-button swiper-button-next"
      src="../../../assets/ico/intro/arrow_right.png"
      alt=""
    />
  </div>
</template>

<style lang="scss" scoped>
.swiper-slide {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.swiper-slide-active {
  position: relative;
  z-index: 2;
  div {
    width: 385px;
    height: 385px;
    border-radius: 50%;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include mobile {
      width: 300px;
      height: 300px;

      img {
        width: 300px;
        height: 300px;
      }
    }
  }
}

.swiper-slide-prev,
.swiper-slide-next {
  position: relative;
  top: 43px;
  z-index: 1;
}

.swiper-slide-prev {
  right: -100px;

  @include mobile {
    right: 0px;
  }
}

.swiper-slide-next {
  left: -100px;

  @include mobile {
    left: 0px;
  }
}

.swiper-button {
  cursor: pointer;
  position: relative;
  top: -204px;
  z-index: 10;

  @include mobile {
    top: -162px;
  }
}

.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 425px;

  @include mobile {
    justify-content: space-between;
    gap: 0;
  }
}
</style>
