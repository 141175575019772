<script setup>
import { ref, onBeforeMount, watch } from "vue";
import { route } from "@/router/route";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import TabBar from "@/components/organisms/tab/tabBar.vue";
import SupporterReady from "@/components/organisms/SupporterReady.vue";
import SelectBox from "@/components/molcules/input/selectBox.vue";
import { displayHooks } from "@/hooks/useDisplay";
import MenuApi from "@/api/menu";
import BtmPagination from "@/components/molcules/pagination/btmPagination.vue";
import SupporterApi from "@/api/supporter";

import { useToast } from "vue-toastification";
import CooperationApi from "@/api/cooperation";
const toast = useToast();

const university = ref({});

let useDisplayRef = ref(displayHooks);

let last = ref(false);
let first = ref(false);
let page = ref(0);
let maxPage = ref(0);
let step = ref(0);
let total = ref(0);
let listSize = ref(12);
let paginationSize = ref(5);

let keyword = ref("");
let mainSectionMenu = ref([]);

const goPrevPage = () => {
  if (!first.value) {
    page.value--;
    getApi();
  } else return;

  if (page.value < paginationSize.value * step.value) {
    step.value--;
  }
};

const goNextPage = () => {
  if (!last.value) {
    page.value++;
    getApi();
  } else return;

  if (page.value + 1 > paginationSize.value * (step.value + 1)) {
    step.value++;
  }
};

const goPage = (index) => {
  page.value = index;
  getApi();
};

onBeforeMount(() => {
  getCooperation();
  getMenu();
});

const getCooperation = async () => {
  try {
    const arr = [];
    const response = (await CooperationApi.get()).data.data.content;

    response.map((item) => {
      univList.value?.map((item2) => {
        if (item2.name === item.title) {
          arr.push({
            ...item2,
            id: item.id,
            blogLink: item.blogLink,
            instagramLink: item.instagramLink,
            youtubeLink: item.youtubeLink,
          });
          return;
        }
      });
    });

    univList.value = arr;
    university.value = arr[0];

    getApi(true);
    getSupporters();
  } catch (error) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);

    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[2].subMenus;
      subMenu.map((item, index) => {
        arr.push({
          value: item.title,
          label: item.title,
          routeName: route[`support${index + 1}`],
        });
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const list = ref([]);
const supporters = ref(null);

const getApi = async (initial) => {
  try {
    if (initial) {
      total.value = 0;
      page.value = 0;
      step.value = 0;
      paginationSize.value = 5;
    }
    const response = await SupporterApi.getSNS({
      size: listSize.value,
      page: page.value,
      keyword: keyword.value,
      school: university.value?.value,
      useFlag: true,
    });

    if (response.data.data) {
      last.value = response.data?.data?.last;
      first.value = response.data?.data?.first;
      list.value = response.data?.data?.content;
      total.value = response.data.data.totalElements;

      maxPage.value = Math.ceil(total.value / listSize.value);

      if (maxPage.value <= paginationSize.value) {
        paginationSize.value = maxPage.value;
      }
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const getSupporters = async () => {
  try {
    const { content } = (
      await SupporterApi.get({
        school: university.value?.value,
      })
    ).data.data;

    if (content.length > 0) {
      supporters.value = content[0];
    }
  } catch (error) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const univList = ref([
  { name: "단국대학교", value: "DANKOOK" },
  { name: "동서대학교", value: "DONGSEO" },
  { name: "원광대학교", value: "WONKWANG" },
  { name: "청강문화산업대학교", value: "CHUNGKANG" },
  { name: "한서대학교", value: "HANSEO" },
]);

const setUniv = (selectedUniv) => {
  university.value = selectedUniv;
};

watch(university, () => {
  getApi();
  getSupporters();
});

const navigateToLink = (link) => {
  window.open(link, "_blank");
};
</script>

<template>
  <SubpageHeader />

  <MainSection
    bgUrl="support"
    :options="mainSectionMenu"
    menuValue="서포터즈"
    mainHeader="학생지원"
    subHeader01="Home"
    subHeader02="학생지원"
    containStyle="min-width:100px"
  />

  <BodyContain
    :style="useDisplayRef.isMobile ? `padding-top: 30px` : `padding-top: 80px`"
  >
    <SelectBox
      v-if="useDisplayRef.isMobile"
      :list="univList"
      @setValue="setUniv"
      :value="university.name"
      style="background-color: #f4f4f4"
      textStyle="color: #044A85; font-weight: 600"
    />
    <div v-if="!useDisplayRef.isMobile" class="tab-container">
      <TabBar
        class="tab-unit"
        v-for="(item, index) in univList"
        :key="index"
        :active="item.value === university.value"
        @click="setUniv(item)"
        :title="item.name"
      />
    </div>

    <PretendardText class="header-title">서포터즈</PretendardText>

    <div v-if="supporters" class="fill-wrapper" style="margin-bottom: 45px">
      <img
        class="supporters-img"
        :src="supporters?.imageUrl"
        style="width: 100%; height: 100%"
      />
    </div>
    <div v-else class="empty-wrapper" style="margin-bottom: 45px">
      <SupporterReady />
    </div>
    <PretendardText class="header-title">SNS</PretendardText>
<!--    <div class="sns-row-wrapper">-->
<!--      <a :href="university?.blogLink" target="blank">-->
<!--        <div class="sns-contain" style="border: 1px solid #40cc19">-->
<!--          <img src="@/assets/ico/support/blog.svg" />-->
<!--          <PretendardText class="text">블로그</PretendardText>-->
<!--        </div>-->
<!--      </a>-->
<!--      <a :href="university?.instagramLink" target="blank">-->
<!--        <div-->
<!--          class="sns-contain"-->
<!--          style="-->
<!--            border: 1px solid transparent;-->
<!--            background-image: linear-gradient(#fff, #fff),-->
<!--              linear-gradient(-->
<!--                to bottom left,-->
<!--                #7609f7,-->
<!--                #e802d2,-->
<!--                #f70171,-->
<!--                #f2088a,-->
<!--                #f4c000-->
<!--              );-->
<!--            background-origin: border-box;-->
<!--            background-clip: content-box, border-box;-->
<!--          "-->
<!--        >-->
<!--          <img src="@/assets/ico/support/insta.svg" />-->
<!--          <PretendardText class="text">인스타그램</PretendardText>-->
<!--        </div>-->
<!--      </a>-->
<!--      <a :href="university?.youtubeLink" target="blank">-->
<!--        <div class="sns-contain" style="border: 1px solid #f70000">-->
<!--          <img src="@/assets/ico/support/youtube.svg" />-->
<!--          <PretendardText class="text">K-컬처 혁신융합대학</PretendardText>-->
<!--        </div>-->
<!--      </a>-->
<!--    </div>-->

    <div class="empty-wrapper" v-if="list.length <= 0">
      <SupporterReady text="SNS 준비중입니다." />
    </div>
    <div v-else class="sns-list-wrapper">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="sns-item"
        @click="navigateToLink(item.link)"
      >
        <img
          :src="
            item.imageUrl
              ? item.imageUrl
              : require(`@/assets/img/img_empty.png`)
          "
        />
        <img
          class="sns-type"
          :src="
            require(`@/assets/ico/support/circle_${
              item.participationCategory === 'YOUTUBE'
                ? 'youtube'
                : item.participationCategory === 'INSTAGRAM'
                ? 'insta'
                : item.participationCategory === 'BLOG'
                ? 'blog'
                : ''
            }.svg`)
          "
        />
      </div>
    </div>
    <BtmPagination
      :page="page"
      :maxPage="maxPage"
      :step="step"
      :last="last"
      :first="first"
      :total="total"
      :listSize="listSize"
      :paginationSize="paginationSize"
      @goPrevPage="goPrevPage"
      @goNextPage="goNextPage"
      @goPage="goPage"
    />
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.header-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 50px;

  @include mobile {
    font-size: 20px;
    margin-top: 60px;
    margin-bottom: 30px;
  }
}

.tab-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 120px;
  width: 100%;
  height: 70px;

  @include mobile {
    margin-bottom: 60px;
  }

  .tab-unit {
    border-right: 0;
  }
  .tab-unit:last-of-type {
    border-right: 1px solid $line !important;
  }
}

.empty-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 675px;
  background-color: #f7fafc;
  border-radius: 10px;

  @include mobile {
    height: 330px;
  }

  & > img {
    margin-bottom: 20px;
    width: 45px;
    height: 45px;
  }

  & > .text {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: #717377;
  }
}

.fill-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 675px;
  background-color: #ffffff00;
  border-radius: 10px;

  @include mobile {
    height: 330px;
  }

  & > img {
    width: 100%;
    height: 100%;
  }
}

.sns-row-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  gap: 18px;

  @include mobile {
    gap: 10px;
  }

  & .sns-contain {
    width: 180px;
    height: 41px;

    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;

    @include mobile {
      height: 61px;
      flex-direction: column;
      justify-content: center;
      gap: 6px;
    }

    & > img {
      width: 25px;
      height: 25px;
      margin-right: 6px;
      margin-left: 15px;

      @include mobile {
        margin-right: 0px;
        margin-left: 0px;
      }
    }

    & > .text {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      color: #111111;

      @include mobile {
        font-size: 12px;
      }
    }
  }
}

.sns-list-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  @include mobile {
    gap: 10px;
  }

  .sns-item {
    width: 285px;
    height: 285px;
    position: relative;
    cursor: pointer;

    @include mobile {
      flex-basis: calc(50% - 5px);
      height: unset;
    }

    @include tablet {
      flex-basis: calc(50% - 10px);
      height: unset;
    }

    & > .sns-type {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 15px;
      right: 15px;
      filter: brightness(100%) !important;

      @include mobile {
        width: 20px;
        height: 20px;
      }
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      filter: brightness(70%);

      &:hover {
        filter: brightness(100%);
      }
    }
  }
}

.more-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  cursor: pointer;

  @include mobile {
    margin-top: 30px;
  }

  & .more {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #cdd0d3;
    border-radius: 10px;

    width: 80px;
    height: 36px;

    & > .text {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      color: #044a85;
    }
  }
}
</style>
