<script setup>
import { menu } from "@/constants/menu";
import { router } from "@/router";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { ref, defineEmits, onBeforeMount } from "vue";
import { route } from "@/router/route";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
const toast = useToast();

const list = ref([]);

const isOpen = ref(-1);

const closeMenu = () => {
  isOpen.value = -1;
};

const openMenu = (i) => {
  if (isOpen.value === i) {
    closeMenu();
    return;
  }

  isOpen.value = i;
};

const emit = defineEmits(["modalClose"]);

const menuRouter = (item, index, index2) => {
  if (item.contentsFlag) {
    router.push({ path: route.board + `/${item.boardId}` });
    return;
  }
  router.push({ path: route[`${menu[index].link}${index2 + 1}`] });
};

onBeforeMount(() => {
  getMenu();
});

const getMenu = async () => {
  try {
    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);

    if (response.data.data) {
      list.value = response.data.data.content.reverse();
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <div class="menu-wrapper">
    <div class="top-section">
      <img
        class="logo"
        src="../../../assets/img/logo_color.png"
        alt=""
        @click="router.push(route.default)"
      />
      <img
        class="x"
        src="../../../assets/ico/x.png"
        alt=""
        @click="emit('modalClose')"
      />
    </div>
    <div v-for="(item, index) in list" :key="index">
      <div class="category-wrapper" @click="openMenu(index)">
        <PretendardText class="title">
          {{ item.title }}
        </PretendardText>
        <img
          :class="isOpen === index ? `icon rotateToBottom` : `icon`"
          class="arrow"
          src="../../../assets/ico/btn/arrow_down.png"
          alt=""
        />
      </div>
      <div v-if="isOpen === index">
        <div
          v-for="(item2, index2) in item.subMenus"
          :key="index2"
          class="sub-wrapper"
          @click="menuRouter(item2, index, index2)"
        >
          <PretendardText class="title">{{ item2.title }}</PretendardText>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: $white;
  z-index: 999;
}

.top-section {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .logo {
    width: 72px;
    height: 12.841px;
  }

  .x {
    width: 24px;
    height: 24px;
  }
}

.category-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 55px;
  padding: 17px 20px;

  .title {
    color: $black;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.6px;
  }

  .arrow {
    width: 20px;
    height: 20px;
  }
}

.sub-wrapper {
  display: flex;
  width: 100%;
  padding: 15px 20px;
  align-items: center;
  gap: 10px;
  background: #f9f9f9;

  .title {
    color: $g03;
    font-size: 13px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.6px;
  }
}

.icon {
  width: 16px;
  height: 16px;
  transition: all ease 0.2s;

  &.rotateToBottom {
    transform: rotate(180deg);
  }
}
</style>
