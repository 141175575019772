<template>
  <div class="vision-box-wrapper">
    <img :src="require(`@/assets/ico/${src}`)" alt="" />
    <div class="text-wrapper">
      <PretendardText class="title">{{ title }}</PretendardText>
      <PretendardText class="content" v-html="content" />
    </div>
  </div>
</template>

<script>
import PretendardText from "@/components/atoms/text/PretendardText.vue";

export default {
  name: "VisionBox",
  components: { PretendardText },
  props: {
    title: String,
    content: String,
    src: String,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.vision-box-wrapper {
  display: flex;
  width: 100%;
  padding: 7px 20px;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: $g08;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  & .title {
    color: $g01;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.6px;
  }

  & .content {
    color: $g03;
    font-size: 13px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.6px;
  }
}
</style>
