<script setup>
import { ref, onBeforeMount, defineProps } from "vue";
import { route } from "@/router/route";
import { useRouter } from "vue-router";
import { format } from "date-fns";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import MainSection from "@/components/templates/mainSection.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import ReferenceApi from "@/api/reference";
import { useToast } from "vue-toastification";
const toast = useToast();

import { displayHooks } from "@/hooks/useDisplay";
import MenuApi from "@/api/menu";

const props = defineProps({
  id: String,
});

const router = useRouter();
let useDisplayRef = ref(displayHooks);

let mainSectionMenu = ref([]);

onBeforeMount(() => {
  getMenu();
  getApi(true);
  getInfo();
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[3].subMenus;
      subMenu.map((item, index) => {
        return arr.push({
          value: item.title,
          label: item.title,
          routeName: route[`sharing${index + 1}`],
        });
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const listRouter = async () => {
  router.replace({ path: `${route.sharing3}` });
};

const list = ref([]);

const getApi = async () => {
  try {
    const response = await ReferenceApi.get();
    if (response.data.data) {
      list.value = response.data?.data?.content;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const content = ref({});
const nextContent = ref(null);
const prevContent = ref(null);

const goPrevNextPage = async (id) => {
  router.push({ path: `${route.referenceDetail}/${id}` });
  getInfo(id);
};

const getInfo = async (id) => {
  try {
    const response = (await ReferenceApi.getById(id ? id : Number(props.id)))
      .data.data;

    if (response) {
      content.value = response;
      getNextInfo();
      getPrevInfo();
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const getNextInfo = async () => {
  try {
    const response = (await ReferenceApi.getById(Number(props.id) + 1)).data
      .data;

    if (response) {
      nextContent.value = response;
    }
  } catch (e) {
    nextContent.value = null;
  }
};

const getPrevInfo = async () => {
  try {
    const response = (await ReferenceApi.getById(Number(props.id) - 1)).data
      .data;

    if (response) {
      prevContent.value = response;
    }
  } catch (e) {
    prevContent.value = null;
  }
};
</script>

<template>
  <SubpageHeader />

  <MainSection
    bgUrl="sharing"
    :options="mainSectionMenu"
    menuValue="자료실"
    mainHeader="성과공유"
    subHeader01="Home"
    subHeader02="성과공유"
  />

  <BodyContain>
    <div
      :class="useDisplayRef.isMobile ? 'flex-col' : 'flex-row'"
      :style="
        useDisplayRef.isMobile ? 'margin-bottom: 30px' : 'margin-bottom: 20px'
      "
    >
      <PretendardText class="title-text">{{ content.title }}</PretendardText>
    </div>
    <div
      :class="useDisplayRef.isMobile ? 'flex-col' : 'flex-row-between'"
      :style="
        useDisplayRef.isMobile
          ? 'align-items: flex-start'
          : 'align-items: center'
      "
    >
      <PretendardText class="created-text"
        >등록일 :
        {{
          content?.createdAt
            ? format(new Date(content.createdAt), "yyyy-MM-dd")
            : "날짜 없음"
        }}
      </PretendardText>
      <div v-if="content.fileOriginFileName" class="flex-row-align-center">
        <PretendardText class="attach-text">첨부파일 :</PretendardText>
        <PretendardText class="attach-file-text">{{
          content.fileOriginFileName
        }}</PretendardText>
        <img style="cursor: pointer" src="@/assets/ico/plaza/download.svg" />
      </div>
    </div>

    <div
      :style="
        useDisplayRef.isMobile
          ? 'border-top: 1px solid #ebebeb; width: 100%; margin: 20px 0px 30px 0px'
          : 'border: 1px solid #ebebeb; width: 100%; margin: 30px 0px'
      "
    />

    <PretendardText class="content-text">
      <div v-html="content.content" />
    </PretendardText>

    <div
      v-if="nextContent"
      class="step-wrapper"
      @click="goPrevNextPage(nextContent.id)"
    >
      <div class="left-wrapper">
        <PretendardText class="text"> 다음글 </PretendardText>
        <img src="@/assets/ico/plaza/up_arrow.svg" />
      </div>
      <div class="right-wrapper">
        <PretendardText class="text">
          {{ nextContent.title }}
        </PretendardText>
      </div>
    </div>
    <div
      v-if="prevContent"
      class="step-wrapper"
      @click="goPrevNextPage(prevContent.id)"
    >
      <div class="left-wrapper">
        <PretendardText class="text"> 이전글 </PretendardText>
        <img
          src="@/assets/ico/plaza/up_arrow.svg"
          style="transform: rotate(180deg)"
        />
      </div>
      <div class="right-wrapper">
        <PretendardText class="text">
          {{ prevContent.title }}
        </PretendardText>
      </div>
    </div>

    <PretendardText class="list-text" @click="listRouter()">
      목록
    </PretendardText>
  </BodyContain>

  <MainFooter />
</template>

<style lang="scss" scoped>
.title-notice {
  font-size: 24px;
  font-weight: 600;
  line-height: 24.48px;
  letter-spacing: -0.6px;
  color: #044a85;
  margin-right: 20px;
  min-width: 41px;
}

.title-text {
  color: #111111;
  font-size: 24px;
  font-weight: 600;
  line-height: 24.48px;
  letter-spacing: -0.6px;
}

.created-text {
  color: #717377;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -0.6px;
}

.attach-text {
  color: #4d5054;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -0.6px;
}

.attach-file-text {
  color: #111111;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  margin: 0px 4px;

  text-decoration: underline;
  cursor: pointer;
}

.content-text {
  color: #191919;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  letter-spacing: -0.6px;

  margin-bottom: 130px;
}

.step-wrapper {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;

  .left-wrapper {
    height: 60px;
    width: 140px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    background-color: #f9f9f9;

    & > .text {
      color: #111111;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
      letter-spacing: -0.6px;
    }
  }

  .right-wrapper {
    flex: 1;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px;
    background-color: #fff;

    & > .text {
      color: #282828;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
      letter-spacing: -0.6px;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.list-text {
  border: 1px solid #cdd0d3;
  border-radius: 10px;
  padding: 8px 12px;
  align-self: center;
  margin-top: 40px;

  color: #044a85;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -0.6px;

  cursor: pointer;
}

@include mobile {
  .title-notice {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    letter-spacing: -0.6px;
    color: #044a85;
    margin-bottom: 10px;
  }

  .title-text {
    color: #111111;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    letter-spacing: -0.6px;
  }

  .created-text {
    color: #717377;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.6px;
    margin-bottom: 10px;
  }

  .attach-text {
    color: #4d5054;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.6px;
  }

  .attach-file-text {
    color: #111111;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    margin: 0px 4px;

    text-decoration: underline;
    cursor: pointer;
  }

  .content-text {
    color: #191919;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.6px;

    margin-bottom: 60px;
  }

  .step-wrapper {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;

    .left-wrapper {
      height: 60px;
      width: 90px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      background-color: #f9f9f9;

      & > .text {
        color: #111111;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        letter-spacing: -0.6px;
      }
    }

    .right-wrapper {
      flex: 1;
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 10px;
      background-color: #fff;

      & > .text {
        color: #282828;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: -0.6px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .list-text {
    border: 1px solid #cdd0d3;
    border-radius: 10px;
    padding: 8px 12px;
    align-self: center;
    margin-top: 30px;

    color: #044a85;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.6px;

    cursor: pointer;
  }
}
</style>
