<template>
  <div class="accordian-wrapper">
    <div class="title-wrapper">
      <div class="title-container">
        <img src="../../../assets/ico/accordian_mark.png" alt="" />
        <PretendardText class="title">{{ title }}</PretendardText>
      </div>
      <img
        src="../../../assets/ico/accordian_arrow.png"
        alt=""
        @click="isOpen = !isOpen"
      />
    </div>
    <div v-if="isOpen" class="content-wrapper">
      <PretendardText class="content">{{ content }}</PretendardText>
    </div>
  </div>
</template>
<script>
import PretendardText from "@/components/atoms/text/PretendardText.vue";

export default {
  name: "AccordianItem",
  components: {
    PretendardText,
  },
  props: {
    title: String,
    content: String,
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.accordian-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  & .title-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 29px 20px;
    border-bottom: 1px solid $line;

    @include mobile {
      padding: 20px 0;
    }

    & .title-container {
      display: flex;
      align-items: center;
      gap: 12px;

      & .title {
        color: #044a85;
        font-size: 18px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.6px;

        @include mobile {
          font-size: 14px;
        }
      }
    }

    & > img {
      cursor: pointer;
    }
  }

  & .content-wrapper {
    width: 100%;
    padding: 30px 20px;
    border-bottom: 1px solid #ebebeb;
    background: #f7fafc;

    @include mobile {
      padding: 20px 15px;
    }

    & .content {
      color: $g01;
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.6px;

      @include mobile {
        font-size: 14px;
      }
    }
  }
}
</style>
