<script setup>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import NewsCard from "./newsCard2.vue";
import { register } from "swiper/element/bundle";
import { ref, onBeforeMount } from "vue";
import { route } from "@/router/route";
import { router } from "@/router";
import PressApi from "@/api/press";
import { useToast } from "vue-toastification";
const toast = useToast();

register();

let list = ref([]);

onBeforeMount(() => {
  getApi();
});

const detailRouter = async (id) => {
  router.push({ path: `${route.pressDetail}/${id}` });
};

const getApi = async () => {
  try {
    const data = {
      size: 10,
      page: 0,
      exposureFlag: true,
      useFlag: true,
    };

    const response = (await PressApi.get(data)).data.data;

    if (response) {
      list.value = response.content;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <div class="flex-col news-box-wrapper" style="gap: 20px">
    <div class="flex-row-between top-bar">
      <PretendardText class="title">보도자료</PretendardText>
      <div class="flex-row-align-center" style="gap: 8px">
        <img
          class="swiper-button swiper-button-prev"
          src="../../../assets/ico/home/arrow_left.png"
          alt=""
        />
        <img
          class="swiper-button swiper-button-next"
          src="../../../assets/ico/home/arrow_right.png"
          alt=""
        />
      </div>
    </div>
    <div class="box">
      <swiper-container
        class="swiper-container"
        :slides-per-view="2"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
        :loop="true"
        :breakpoints="{
          768: {
            slidesPerView: 3,
          },
        }"
      >
        <swiper-slide
          v-for="(item, index) in list"
          :key="index"
          class="swiper-slide"
          ><NewsCard @click="detailRouter(item.id)" :item="item"
        /></swiper-slide>
      </swiper-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-bar {
  align-items: center;
}

.news-box-wrapper {
  width: 100%;
  max-width: 590px;

  @include small {
    max-width: 100%;
  }
}

.title {
  color: $g02;
  font-size: 20px;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 18px;
  }
}

.box {
  display: flex;
  width: 100%;
  height: 305px;
  padding: 32px 0 32px 30px;
  gap: 15px;
  border-radius: 10px;
  background: $white;
  box-shadow: 0px 2px 10px 0px rgba(40, 40, 40, 0.15);
  overflow: hidden;

  @include mobile {
    padding: 15px;
    height: fit-content;
  }

  .swiper-container {
    width: 135% !important;

    @include mobile {
      width: 130% !important;
    }

    .swiper-slide {
      max-width: 240px;
      margin-right: 15px;

      @include mobile {
        max-width: 190px;
        margin-right: 10px;
      }
    }
  }
}

.swiper-button {
  cursor: pointer;
}

.box::-webkit-scrollbar {
  display: none;
}
</style>
