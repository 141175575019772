<script setup>
import { menu } from "@/constants/menu";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import SummaryBox1 from "./SummaryBox1.vue";
import SummaryBox2 from "./SummaryBox2.vue";
import SummaryBox3 from "./SummaryBox3.vue";
import SummaryBox4 from "./SummaryBox4.vue";
import SummaryBox5 from "./SummaryBox5.vue";
import { router } from "@/router";
import { defineProps, defineEmits } from "vue";
import { route } from "@/router/route";

const props = defineProps(["activeMenu", "subMenu"]);
const emit = defineEmits(["changeDeActiveMenu"]);

const menuRouter = (item, index) => {
  if (item.contentsFlag) {
    router.push({ path: route.board + `/${item.boardId}` });
    return;
  }
  router.push({ path: route[`${menu[props.activeMenu].link}${index + 1}`] });
};
</script>

<template>
  <div v-if="props.activeMenu !== -1" class="submenu-container">
    <div class="submenu-wrapper">
      <div class="item-wrapper">
        <PretendardText
          v-for="(item, index) in props.subMenu"
          :key="index"
          class="submenu"
          @click="emit('changeDeActiveMenu'), menuRouter(item, index)"
          >{{ item.title }}</PretendardText
        >
      </div>
    </div>
    <div class="summary-box">
      <SummaryBox1 v-if="props.activeMenu === 0" />
      <SummaryBox2 v-if="props.activeMenu === 1" />
      <SummaryBox3 v-if="props.activeMenu === 2" />
      <SummaryBox4 v-if="props.activeMenu === 3" />
      <SummaryBox5 v-if="props.activeMenu === 4" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.submenu-container {
  width: 100%;
  position: absolute;
  bottom: -89px;
  right: 0;

  & .submenu-wrapper {
    width: 100%;
    padding: 18px 0;
    background-color: $g08;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;

    & .item-wrapper {
      gap: 16px;

      & .submenu {
        color: $g03;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.6px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:last-child)::after {
          content: "";
          display: inline-block;
          width: 1px;
          height: 15px;
          background: #d9d9d9;
          margin-left: 16px;
        }
      }
    }
  }
}

.summary-box {
  background-color: $white;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  border: 1px solid #efefef;
  border-radius: 0 0 8px 8px;
  position: relative;
  z-index: 5;
}
</style>
