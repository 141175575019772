import api from "./api";

export default class TargetApi {
  static async get(data) {
    return await api.get(`educations`, data);
  }

  static async getById(id) {
    return await api.get(`education/${id}`);
  }
}
