<script setup>
import { defineProps, toRefs, ref } from "vue";
import { useRouter } from "vue-router";

import PretendardText from "@/components/atoms/text/PretendardText.vue";
import MSSelectBox from "@/components/atoms/selectBox/msSelectBox.vue";
import MSMSelectBox from "@/components/atoms/selectBox/msMSelectBox.vue";

import { displayHooks } from "@/hooks/useDisplay";

const router = useRouter();

const props = defineProps({
  bgUrl: { type: String, required: true, default: "" },
  options: {
    type: Array,
    default: () => [],
  },
  menuValue: String,
  mainHeader: String,
  subHeader01: String,
  subHeader02: String,
  containStyle: String,
});

const { options, menuValue, mainHeader, subHeader01, subHeader02 } =
  toRefs(props);

const value = ref(menuValue);
const isClicked = ref(false);

let useDisplayRef = ref(displayHooks);

const clickedFunc = () => {
  isClicked.value = !isClicked.value;
};

const valueClickedFunc = (clickedValue) => {
  value.value = clickedValue;
  router.push({ path: clickedValue.routeName });
};

const routerMove = async (routeName) => {
  router.push({ path: routeName });
};

const getImageUrl = () => {
  return require(`@/assets/img/topSection/${
    useDisplayRef.value.isMobile ? props.bgUrl + "_m" : props.bgUrl
  }.png`);
};
</script>

<template>
  <div
    class="section-wrapper"
    :style="{
      background: props.bgUrl
        ? `url(${getImageUrl()}) center center no-repeat`
        : '#00000070',
      'background-size': 'cover',
      'background-position': 'top center',
    }"
  >
    <div class="top-wrapper">
      <div class="flex-row-align-center">
        <PretendardText class="sub-header">{{ subHeader01 }}</PretendardText>
        <div class="division"></div>

        <PretendardText class="sub-header">{{ subHeader02 }}</PretendardText>
        <div class="division"></div>

        <PretendardText v-if="useDisplayRef.isMobile" class="sub-header">{{
          value
        }}</PretendardText>

        <MSSelectBox
          v-if="!useDisplayRef.isMobile"
          :selectValue="value"
          :options="options"
          @clickedFunc="clickedFunc"
          @valueClicekdFunc="valueClickedFunc"
          :clicked="isClicked"
          :containStyle="containStyle"
        />
      </div>
      <PretendardText class="main-header">{{ mainHeader }}</PretendardText>
    </div>

    <MSMSelectBox
      v-if="useDisplayRef.isMobile"
      :selectValue="value"
      :options="options"
      @clickedFunc="clickedFunc"
      @valueClicekdFunc="valueClickedFunc"
      :clicked="isClicked"
      :containStyle="containStyle"
    />

    <div class="bottom-wrapper" v-if="!useDisplayRef.isMobile">
      <div
        class="bottom-item"
        v-for="(item, index) in options"
        :key="index"
        @click="routerMove(item.routeName)"
      >
        <div v-if="item.value === value ? true : false" class="active"></div>
        <PretendardText class="bottom-text">{{ item.label }}</PretendardText>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@include pc {
  .section-wrapper {
    width: 100%;
    height: 450px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .top-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;

      .division {
        margin: 0px 16px;
        height: 10px;
        border: 1px solid #fff;
      }

      .sub-header {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.8px;
        letter-spacing: -0.6px;
      }

      .main-header {
        color: #fff;
        font-size: 45px;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: -0.6px;
        margin-top: 20px;
      }
    }

    .bottom-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 1200px;
      height: 70px;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 20px 20px 0px 0px;
      gap: 0px 65px;

      .bottom-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 100px; //100px 이상인 부분들이 있어서 min-width로 교체했습니다
        height: 70px;
        position: relative;
        cursor: pointer;

        .active {
          position: absolute;
          top: -4px;
          z-index: 1;
          background-color: #fff;
          width: 8px;
          height: 8px;
          border-radius: 100px;
          box-shadow: 0px 2px 7px 0px rgba(255, 255, 255, 0.9);
        }

        .bottom-text {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          line-height: 21.6px;
          letter-spacing: -0.6px;
        }
      }
    }
  }
}

@include tablet {
  .section-wrapper {
    width: 100%;
    height: 450px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .top-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;

      .division {
        margin: 0px 16px;
        height: 10px;
        border: 1px solid #fff;
      }

      .sub-header {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.8px;
        letter-spacing: -0.6px;
      }

      .main-header {
        color: #fff;
        font-size: 45px;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: -0.6px;
        margin-top: 20px;
      }
    }

    .bottom-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 70px;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 20px 20px 0px 0px;
      gap: 0px 65px;

      .bottom-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 100px; //100px 이상인 부분들이 있어서 min-width로 교체했습니다
        height: 70px;
        position: relative;
        cursor: pointer;

        .active {
          position: absolute;
          top: -4px;
          z-index: 2;
          background-color: #fff;
          width: 8px;
          height: 8px;
          border-radius: 100px;
          box-shadow: 0px 2px 7px 0px rgba(255, 255, 255, 0.9);
        }

        .bottom-text {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          line-height: 21.6px;
          letter-spacing: -0.6px;
        }
      }
    }
  }
}

@include mobile {
  .section-wrapper {
    width: 100%;
    height: 260px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .top-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;

      .division {
        margin: 0px 16px;
        height: 10px;
        border-right: 1px solid #cdd0d3;
      }

      .sub-header {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.8px;
        letter-spacing: -0.6px;
      }

      .main-header {
        color: #fff;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.4px;
        letter-spacing: -0.6px;
        margin-top: 15px;
      }
    }
  }
}
</style>
