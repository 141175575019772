import api from "./api";

export default class KmoocApi {
  static async get(data) {
    return await api.get(`kmoocs`, data);
  }

  static async getById(id) {
    return await api.get(`kmooc/view/${id}`);
  }
}
