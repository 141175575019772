<template>
  <div class="btn-wrapper" :style="style">
    <PretendardText class="btn-text">{{ btnText }}</PretendardText>
    <img
      :src="require(`@/assets/ico/btn/arrow_${isWhite ? 'white' : 'blue'}.png`)"
      alt=""
    />
  </div>
</template>

<script>
import PretendardText from "@/components/atoms/text/PretendardText.vue";

export default {
  name: "OutLineBtn",
  components: {
    PretendardText,
  },
  props: {
    style: [String, Object],
    btnText: String,
    isWhite: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.btn-wrapper {
  position: relative;
  width: fit-content;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  border: 1px solid $g05;
  cursor: pointer;

  & .btn-text {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.6px;
  }
}
</style>
