import api from "./api";

export default class SupporterApi {
  static async get(data) {
    return await api.get(`supporters`, data);
  }

  static async getById(id) {
    return await api.get(`supporter/${id}`);
  }

  static async getSNS(data) {
    return await api.get(`participations`, data);
  }
}
