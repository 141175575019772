<template>
  <div class="item-wrapper">
    <div class="box" style="width: 350px; margin-right: 54px">
      <SummaryTitle title="교육과정 소개" :link="route.curriculum1" />
      <div class="info-warpper">
        <div class="curriculum1">
          <PretendardText class="title">집중이수제</PretendardText>
        </div>
        <div class="curriculum2">
          <PretendardText class="title">마이크로러닝</PretendardText>
        </div>
        <div class="curriculum3">
          <PretendardText class="title">현장 실무형 교육과정</PretendardText>
        </div>
      </div>
    </div>

    <div class="box" style="width: 396px; margin-right: 50px">
      <SummaryTitle title="개설과목" :link="route.curriculum2" />
      <div class="info-warpper">
        <div class="open-info-box">
          <PretendardText>전공</PretendardText>
          <div>
            <div class="open-subject-list" style="margin-bottom: 4px">
              <div class="open-subject">
                <PretendardText>영화/드라마</PretendardText>
              </div>
              <div class="open-subject">
                <PretendardText>웹툰/애니메이션</PretendardText>
              </div>
            </div>
            <div class="open-subject-list">
              <div class="open-subject">
                <PretendardText>문학/웹소설</PretendardText>
              </div>
              <div class="open-subject">
                <PretendardText>음악/공연</PretendardText>
              </div>
            </div>
          </div>
        </div>
        <div class="open-info-box">
          <PretendardText>세분화 트랙</PretendardText>
          <div>
            <div class="open-subject-list" style="margin-bottom: 4px">
              <div class="open-subject">
                <PretendardText>스토리빌더</PretendardText>
              </div>
              <div class="open-subject">
                <PretendardText>크리에이터</PretendardText>
              </div>
            </div>
            <div class="open-subject-list">
              <div class="open-subject">
                <PretendardText>인플루언서</PretendardText>
              </div>
              <div class="open-subject">
                <PretendardText>밸류에이터</PretendardText>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box" style="width: 350px">
      <SummaryTitle title="교육대상" :link="route.curriculum3" />
      <PretendardText class="edu-subject"
        >컨소시엄 참여대학 → 컨소시엄 간 공유 →<br />
        공동활용대학, 일반국민, 글로벌네트워크로 점진적 확대</PretendardText
      >
      <img src="../../../assets/img/header/edu_subject.png" alt="" />
    </div>
  </div>
</template>

<script>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import SummaryTitle from "@/components/molcules/header/SummaryTitle.vue";
import { route } from "@/router/route";

export default {
  name: "SummaryBox2",
  components: { PretendardText, SummaryTitle },
  data() {
    return { route };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-warpper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.curriculum {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-size: cover;

  & .title {
    color: $white;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.6px;
  }
}

.curriculum1 {
  @extend .curriculum;
  background: url("../../../assets/img/header/curriculum1.png") center center
    no-repeat;
}

.curriculum2 {
  @extend .curriculum;
  background: url("../../../assets/img/header/curriculum2.png") center center
    no-repeat;
}

.curriculum3 {
  @extend .curriculum;
  background: url("../../../assets/img/header/curriculum3.png") center center
    no-repeat;
}

.edu-subject {
  color: $g02;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.6px;
}

.open-info-box {
  display: flex;
  padding: 29.5px 20px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: $g08;

  & .title {
    color: $g01;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.6px;
  }
}

.open-subject-list {
  display: flex;
  gap: 4px;
  justify-content: flex-end;

  .open-subject {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    border: 1px solid $line;
    background-color: $white;
  }
}
</style>
