<script setup>
import { defineProps, toRefs, defineEmits } from "vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";

const emit = defineEmits(["clickedFunc", "valueClicekdFunc"]);

const props = defineProps({
  selectValue: String,
  style: [String, Object],
  containStyle: String,
  clicked: Boolean,
  options: {
    type: Array,
    required: false,
  },
});
const { style, selectValue, options, containStyle } = toRefs(props);
</script>

<template>
  <div class="select-wrapper" :style="style" @click="emit('clickedFunc')">
    <PretendardText class="selected-text">{{ selectValue }}</PretendardText>
    <img
      :class="clicked ? 'icon rotateToBottom' : 'icon'"
      :src="require(`@/assets/ico/plaza/bottom.svg`)"
      alt=""
    />

    <div v-if="clicked" class="select-item-contain" :style="containStyle">
      <div
        class="select-span"
        v-for="(item, index) in options"
        :key="index"
        @click="emit('valueClicekdFunc', item)"
      >
        <PretendardText class="span-text">{{ item.value }}</PretendardText>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.select-wrapper {
  background-color: transparent;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  .selected-text {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
    margin-right: 8px;

    white-space: nowrap;

    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    width: 16px;
    height: 16px;
    transition: all ease 0.2s;

    &.rotateToBottom {
      transform: rotate(180deg);
    }
  }

  .select-item-contain {
    position: absolute;
    top: 22px;
    left: -10px;
    width: 120px;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;

    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #adb4ba;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1); /*스크롤바 뒷 배경 색상*/
      border-radius: 4px;
      box-shadow: inset 0px 0px 5px white;
    }

    .select-span {
      padding-left: 10px;
      height: 35px;

      display: inline-block;
      white-space: nowrap;

      text-overflow: ellipsis;

      display: flex;
      flex-direction: row;
      align-items: center;

      &:hover {
        cursor: pointer;
        background-color: #e8e6e6;
        text-decoration: none;
      }

      .span-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;

        color: #191919;
        white-space: nowrap;
      }
    }
  }
}
</style>
