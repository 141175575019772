import api from "./api";

export default class ActivityApi {
  static async get(data) {
    return await api.get(`extracurriculars`, data);
  }

  static async getById(id) {
    return await api.get(`extracurricular/view/${id}`);
  }
}
