<script setup>
import { ref, onBeforeMount } from "vue";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import { route } from "@/router/route";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { displayHooks } from "@/hooks/useDisplay";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
const toast = useToast();

let useDisplayRef = ref(displayHooks);
let menuValue = ref("");

let mainSectionMenu = ref([]);

onBeforeMount(() => {
  getMenu();
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[1].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`curriculum${index + 1}`],
          });
        }

        if (item.homeLink === "/curriculum04") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <SubpageHeader />
  <MainSection
    bgUrl="curriculum"
    :options="mainSectionMenu"
    :menuValue="menuValue"
    mainHeader="교육과정"
    subHeader01="Home"
    subHeader02="교육과정"
  />

  <BodyContain
    :style="`padding-bottom: ${useDisplayRef.isMobile ? '60px' : '120px'}`"
  >
    <PretendardText class="intro-title">이수안내</PretendardText>
    <div class="blue-box-wrapper">
      <div class="blue-box">
        <PretendardText class="text"
          >학생들의 관련 전공 습득 능력의 차이가 있기 때문에<br />
          각 전공별･세부 트랙별 수준별 모듈화 교육과정 개발<br />
          (마이크로디그리, 부전공, 복수전공)</PretendardText
        >
      </div>
      <div class="blue-box">
        <PretendardText class="text"
          >글로벌 K-웨이브 교육과정을 구성하는
          <br v-if="useDisplayRef.isMobile" />심화/융합 교육과정인 로그웨이브의
          경우 전공별<br />
          (영화/드라마, 웹툰/애니메이션, 음악/공연, 문학/웹소설)
          <br v-if="useDisplayRef.isMobile" />초/중/고급의 교과과정을
          구별하여,<br />
          전공 습득 정도에 맞게 선택적으로 교과를
          <br v-if="useDisplayRef.isMobile" />선택할 수 있도록 교과 난이도를
          구성</PretendardText
        >
      </div>
    </div>
    <img
      class="table"
      :src="
        require(`../../../assets/img/intro/${
          !useDisplayRef.isDesktop ? 'm_' : ''
        }complete1.png`)
      "
    />
    <img
      class="table"
      :src="
        require(`../../../assets/img/intro/${
          !useDisplayRef.isDesktop ? 'm_' : ''
        }complete2.png`)
      "
    />
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.intro-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 50px;

  @include mobile {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.blue-box-wrapper {
  display: flex;
  gap: 30px;

  @include mobile {
    flex-direction: column;
    gap: 10px;
  }
}

.blue-box {
  display: flex;
  flex-basis: calc(50% - 15px);
  height: 120px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f7fafc;

  @include tablet {
    height: 160px;
  }

  @include mobile {
    flex-basis: 100%;
  }

  .text {
    color: $primary4;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.6px;

    @include mobile {
      font-size: 14px;
    }
  }
}

.table {
  width: 100%;
  margin-top: 80px;

  @include mobile {
    margin-top: 60px;
  }
}
</style>
