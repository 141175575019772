<script setup>
import { ref, defineProps } from "vue";

const props = defineProps({
  title: String,
  link: String,
});

const isHover = ref(false);

const linkRouter = () => {
  window.location.href = props.link;
};
</script>
<template>
  <div
    class="quick-menu"
    @click="linkRouter"
    @mouseover="isHover = true"
    @mouseleave="isHover = false"
  >
    {{ props.title }}
    <img
      :src="
        isHover
          ? require(`../../../assets/ico/home/quick_arrow_hover.png`)
          : require(`../../../assets/ico/home/quick_arrow_gray.png`)
      "
      alt=""
    />
  </div>
</template>
<style lang="scss" scoped>
.quick-menu {
  display: flex;
  width: 160px;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid $g05;
  color: $g04;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.6px;
  cursor: pointer;
}

.quick-menu:hover {
  color: $primary4;
  font-weight: 600;
}
</style>
