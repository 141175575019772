import { reactive, watchEffect } from "vue";

// 상태 변수 정의
export const displayHooks = reactive({
  width: window.innerWidth,
  isDesktop: window.innerWidth > 1200 ? true : false,
  isTablet: window.innerWidth < 1200 && window.innerWidth > 767 ? true : false,
  isMobile: window.innerWidth < 767 ? true : false,
});

const handleResize = () => {
  displayHooks.width = window.innerWidth;

  if (window.innerWidth > 1200) {
    displayHooks.isDesktop = true;
    displayHooks.isTablet = false;
    displayHooks.isMobile = false;
  } else if (window.innerWidth <= 1200 && window.innerWidth > 767) {
    displayHooks.isDesktop = false;
    displayHooks.isTablet = true;
    displayHooks.isMobile = false;
  } else {
    displayHooks.isDesktop = false;
    displayHooks.isTablet = false;
    displayHooks.isMobile = true;
  }
};

watchEffect(() => {
  window.addEventListener("resize", handleResize);
});
