<template>
  <div>
    <div
        v-for="(item, index) in list"
        :key="index"
        class="notice-wrapper"
        @click="detailRouter(item.id)"
    >
      <PretendardText class="text-16 title">{{ item.title }}</PretendardText>
      <PretendardText class="text-16">
        {{ format(new Date(item.createdAt), "yyyy-MM-dd") }}
      </PretendardText>
    </div>
  </div>
</template>

<script>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import BoardApi from "@/api/board";
import { ref } from "vue";
import { format } from "date-fns";
import { router } from "@/router";
import { route } from "@/router/route";

const boardId = ref(1);
export default {
  name: "NoticeCard",
  components: { PretendardText },
  props: {
    noticeId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const list = ref([]);

    const fetchNotice = async () => {
      try {
        const data = {
          boardId: 1,
          size: 3,
          page: 0,
          exposureFlag: true,
          useFlag: true,
        };

        const response = (await BoardApi.getContent(data)).data.data;

        if (response) {
          list.value = response.content || [];
        }
      } catch (error) {
        console.error("Failed to fetch notice", error);
      }
    };

    const detailRouter = async (id) => {
      router.push({ path: `${route.boardDetail}/${boardId.value}/${id}` });
    };
    const getEmergencyApi = async () => {
      try {
        const data = {
          boardId: props.noticeId,
          exposureFlag: true,
          emergencyFlag: true,
        };

        const response = (await BoardApi.getEmergencyContent(data)).data.data?.content;

        if (response) {
          const emergencyNotice = response.find((item) => item.emergencyFlag);
          if (emergencyNotice) {
            // Handle emergency notice if needed
          }
        }
      } catch (e) {
        console.error("Failed to fetch emergency content", e);
      }
    };

    fetchNotice();
    getEmergencyApi();

    return {
      list,
      detailRouter,
      format,
    };
  },
};
</script>

<style scoped>
.notice-wrapper {
  display: flex;
  padding: 15px 0px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;

  & .title {
    color: #044a85;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.6px;
    cursor: pointer;
  }

  & .date {
    color: #888;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.6px;
  }
}
.text-16 {
  color: $g04;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.6px;
}

</style>
