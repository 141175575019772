import { createRouter, createWebHistory } from "vue-router";

// 연결할 각 컴포넌트 import (src/views폴더 아래 컴포넌트들 생성해둠)
import MainHome from "../pages/MainHome";
import PrivacyPage from "../pages/privacyPage/PrivacyPage";

import introPage from "@/pages/intro/introPage.vue";
import visionPage from "@/pages/intro/vision/visionPage.vue";
import abilityPage from "@/pages/intro/ability/abilityPage.vue";
import runningPage from "@/pages/intro/running/runningPage.vue";
import universityPage from "@/pages/intro/university/universityPage.vue";
import CurriculumPage from "@/pages/curriculum/curriculumPage.vue";
import SubjectPage from "@/pages/curriculum/subject/subjectPage.vue";
import SubjectDetailPage from "@/pages/curriculum/subject/subjectDetailPage.vue";
import CompletePage from "@/pages/curriculum/complete/completePage.vue";
import TargetPage from "@/pages/curriculum/target/targetPage.vue";
import SharingPage from "@/pages/sharing/sharingPage.vue";
import KmoocPage from "@/pages/sharing/kmooc/kmoocPage.vue";
import ReferencePage from "@/pages/sharing/reference/referencePage.vue";
import ReferenceDetailPage from "@/pages/sharing/reference/referenceDetailPage.vue";
import KmoocDetailPage from "@/pages/sharing/kmooc/kmoocDetailPage.vue";

import CurriculumPage02Detail from "@/pages/curriculum/subject/CurriculumPage02Detail.vue";

import NoticePage from "../pages/plaza/noticePage";
import NoticeDetailPage from "../pages/plaza/noticeDetailPage";
import PressPage from "../pages/plaza/pressPage";
import PressDetailPage from "../pages/plaza/pressDetailPage";
import EmploymentPage from "../pages/plaza/employmentPage";
import FaqPage from "../pages/plaza/faqPage";
import SupportPage from "../pages/support/supportPage";
import SupporterPage from "../pages/support/supporterPage";
import ActivityPage from "../pages/support/activityPage";
import ActivityDetailPage from "../pages/support/activityDetailPage";

import BoardPage from "../pages/board/boardPage";
import BoardDetailPage from "../pages/board/boardDetailPage";
import { route } from "./route";

// 라우터 설계
const routes = [
  { path: route.default, component: MainHome },
  { path: route.privacy, component: PrivacyPage },

  { path: route.intro1, component: introPage },
  { path: route.intro2, component: visionPage },
  { path: route.intro3, component: abilityPage },
  { path: route.intro4, component: runningPage },
  { path: route.intro5, component: universityPage },

  { path: route.curriculum1, component: CurriculumPage },
  { path: route.curriculum2, component: SubjectPage },
  { path: route.curriculum3, component: TargetPage },
  { path: route.curriculum4, component: CompletePage },
  {
    path: `${route.subjectDetail}/:id`,
    component: SubjectDetailPage,
    props: true,
  },

  { path: route.sharing1, component: SharingPage },
  { path: route.sharing2, component: KmoocPage },
  { path: route.sharing3, component: ReferencePage },
  {
    path: `${route.kmoocDetail}/:id`,
    component: KmoocDetailPage,
    props: true,
  },
  {
    path: `${route.referenceDetail}/:id`,
    component: ReferenceDetailPage,
    props: true,
  },

  { path: route.plaza1, component: NoticePage },
  {
    path: `${route.noticeDetail}/:id`,
    component: NoticeDetailPage,
    props: true,
  },
  { path: route.plaza2, component: PressPage },
  {
    path: `${route.pressDetail}/:id`,
    component: PressDetailPage,
    props: true,
  },
  { path: route.plaza3, component: EmploymentPage },
  { path: route.plaza4, component: FaqPage },

  { path: route.support1, component: SupportPage },
  { path: route.support2, component: SupporterPage },
  { path: route.support3, component: ActivityPage },
  {
    path: `${route.activityDetail}/:id`,
    component: ActivityDetailPage,
    props: true,
  },

  {
    path: `${route.board}/:id`,
    component: BoardPage,
    props: true,
  },

  {
    path: `${route.boardDetail}/:id/:contentId`,
    component: BoardDetailPage,
    props: true,
  },
  {
    path: "/curriculum02Detail",
    name: "curriculum02Detail",
    component: CurriculumPage02Detail,
    props: (route) => ({
      sbcCd: route.query.sbcCd,
      sbcNm : route.query.sbcNm,
      majrCd : route.query.majrCd,
      trkCd : route.query.trkCd,
      year : route.query.year,
      smst : route.query.smst,
      level : route.query.level,
      credit : route.query.credit,
      teacher : route.query.teacher
    })
  },
];

// 라우터 생성
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // 페이지 이동 시 항상 맨 위로 스크롤
    return { top: 0 };
  },


});

// 라우터 추출 (main.js에서 import)
export { router };
