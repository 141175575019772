<script setup>
import { ref, onBeforeMount, defineProps, watch } from "vue";
import { useRouter } from "vue-router";
import { route } from "@/router/route";
import { format } from "date-fns";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import MainSection from "@/components/templates/mainSection.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import SearchInput from "@/components/molcules/input/searchInput.vue";
import BtmPagination from "@/components/molcules/pagination/btmPagination.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import EmptyTable from "@/components/organisms/emptyTable.vue";
import BoardApi from "@/api/board";
import { useToast } from "vue-toastification";
const toast = useToast();

import { displayHooks } from "@/hooks/useDisplay";
import MenuApi from "@/api/menu";

const props = defineProps({
  id: String,
});

const router = useRouter();
let useDisplayRef = ref(displayHooks);

let boardId = ref(0);
let menuValue = ref("");
let mainTitle = ref("");
let mainType = ref("");
let activeMenuIndex = ref(0);

let last = ref(false);
let first = ref(false);
let page = ref(0);
let maxPage = ref(0);
let step = ref(0);
let total = ref(0);
let listSize = ref(0);
let paginationSize = ref(0);

let keyword = ref("");
let mainSectionMenu = ref([]);

const goPrevPage = () => {
  if (!first.value) {
    page.value--;
    getApi();
  } else return;

  if (page.value < paginationSize.value * step.value) {
    step.value--;
  }
};

const goNextPage = () => {
  if (!last.value) {
    page.value++;
    getApi();
  } else return;

  if (page.value + 1 > paginationSize.value * (step.value + 1)) {
    step.value++;
  }
};

const goPage = (index) => {
  page.value = index;
  getApi();
};

onBeforeMount(() => {
  getMenu();
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await (await MenuApi.get(data)).data.data;
    const menuData = response.content.reverse();
    menuData.map((item, index) => {
      if (item.subMenus) {
        item.subMenus?.map((item2) => {
          if (item2.boardId === Number(props.id)) {
            activeMenuIndex.value = index;
          }
        });
      }
    });

    if (response) {
      const subMenu = menuData[activeMenuIndex.value].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          switch (item.menu?.title) {
            case "사업소개":
              mainType.value = "intro";
              break;
            case "교육과정":
              mainType.value = "curriculum";
              break;
            case "학생지원":
              mainType.value = "support";
              break;
            case "성과공유":
              mainType.value = "sharing";
              break;
            case "정보광장":
              mainType.value = "plaza";
              break;
            default:
              mainType.value = "intro";
          }

          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`${mainType.value}${index + 1}`],
          });
        }

        if (item.boardId === Number(props.id)) {
          menuValue.value = item.title;
          mainTitle.value = item.menu?.title;
          boardId.value = item.boardId;
          getBoardInfo(item.boardId);
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const getBoardInfo = async (id) => {
  try {
    const response = (await BoardApi.get(id)).data.data;

    listSize.value = response.listCount;
    paginationSize.value = response.pageCount;

    getApi(true);
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const detailRouter = async (id) => {
  router.push({ path: `${route.boardDetail}/${props.id}/${id}` });
};

const list = ref([]);

const getApi = async (initial) => {
  try {
    if (initial) {
      total.value = 0;
      page.value = 0;
      step.value = 0;
    }
    const response = (
      await BoardApi.getContent({
        boardId: boardId.value,
        size: listSize.value,
        page: page.value,
        keyword: keyword.value,
        useFlag: true,
      })
    ).data.data;

    if (response) {
      last.value = response.last;
      first.value = response.first;
      total.value = response.totalElements;

      const arr = [];

      response.content?.map((item) => {
        if (!item.emergencyFlag) {
          arr.push(item);
        } else {
          arr.unshift(item);
        }
      });

      list.value = arr;

      maxPage.value = Math.ceil(total.value / listSize.value);
      if (maxPage.value < paginationSize.value) {
        paginationSize.value = maxPage.value;
      }
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

watch(
  () => props.id,
  () => {
    getMenu();
  }
);
</script>

<template>
  <SubpageHeader />

  <MainSection
    :bgUrl="mainType"
    :options="mainSectionMenu"
    :menuValue="menuValue"
    :mainHeader="mainTitle"
    subHeader01="Home"
    :subHeader02="mainTitle"
  />

  <BodyContain>
    <div
      :class="useDisplayRef.isMobile ? 'flex-col' : 'flex-row-between'"
      :style="
        useDisplayRef.isMobile ? 'margin-bottom: 30px' : 'margin-bottom: 50px'
      "
    >
      <PretendardText class="header-title">{{ menuValue }}</PretendardText>
      <SearchInput
        v-model="keyword"
        placeholder="검색어를 입력하세요."
        @click="getBoardInfo(boardId)"
      />
    </div>

    <div class="table-header">
      <div class="table-header-title" style="justify-content: center">
        <PretendardText class="title">제목</PretendardText>
      </div>
      <div class="table-header-date" style="justify-content: center">
        <PretendardText class="title">등록일</PretendardText>
      </div>
    </div>

    <EmptyTable v-if="list.length <= 0" />
    <div
      v-else
      v-for="(item, index) in list"
      :key="index"
      class="table-item"
      @click="detailRouter(item.id)"
    >
      <div class="table-item-title">
        <div v-if="item.emergencyFlag" class="notice">긴급</div>
        <PretendardText class="title">{{ item.title }}</PretendardText>
      </div>
      <div class="table-item-date" style="justify-content: center">
        <PretendardText class="title">{{
          format(new Date(item.createdAt), "yyyy-MM-dd")
        }}</PretendardText>
      </div>
    </div>

    <BtmPagination
      :page="page"
      :maxPage="maxPage"
      :step="step"
      :last="last"
      :first="first"
      :total="total"
      :listSize="listSize"
      :paginationSize="paginationSize"
      @goPrevPage="goPrevPage"
      @goNextPage="goNextPage"
      @goPage="goPage"
    />
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.header-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  color: #111111;
}

.table-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  height: 60px;
  padding: 0px 15px;
  box-sizing: border-box;

  .table-header-title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > .title {
      color: #282828;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
      letter-spacing: -0.6px;
    }
  }

  .table-header-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 150px;

    & > .title {
      color: #282828;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
      letter-spacing: -0.6px;
    }
  }
}

.table-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  height: 60px;
  padding: 0px 15px;
  cursor: pointer;
  box-sizing: border-box;

  .table-item-title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > .notice {
      width: 40px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background-color: #044a85;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      letter-spacing: -0.6px;
      margin-right: 10px;
    }

    & > .title {
      color: #282828;
      text-align: left;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
      letter-spacing: -0.6px;
    }
  }

  .table-item-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 150px;

    & > .title {
      color: #717377;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
      letter-spacing: -0.6px;
    }
  }
}

@include mobile {
  .header-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #111111;
    margin-bottom: 15px;
  }

  .table-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    height: 60px;
    padding: 0px 10px;
    box-sizing: border-box;

    .table-header-title {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      & > .title {
        color: #282828;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        letter-spacing: -0.6px;
      }
    }

    .table-header-date {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 65px;

      & > .title {
        color: #282828;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        letter-spacing: -0.6px;
      }
    }
  }

  .table-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    height: 70px;
    padding: 0px 10px;
    cursor: pointer;
    box-sizing: border-box;

    .table-item-title {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      & > .notice {
        min-width: 40px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background-color: #044a85;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        letter-spacing: -0.6px;
        margin-right: 10px;
      }

      & > .title {
        color: #282828;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        letter-spacing: -0.6px;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .table-item-date {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 65px;

      & > .title {
        color: #717377;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
        letter-spacing: -0.6px;
      }
    }
  }
}
</style>
