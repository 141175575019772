export const consortium = [
  {
    title: "교수학습혁신센터",
    list: [
      "교육모델 개발·공유·확산",
      "교육모델 관련 연구 수행",
      "교육모델 성과 관리",
    ],
  },
  {
    title: "교육인프라혁신센터",
    list: ["물리적 인프라 구축 관리 총괄", "공유플랫폼 총괄관리"],
  },
  {
    title: "성과평가센터",
    list: [
      "학생역량진단 및 성과관리",
      "학업성취도 진단 결과 모니터링",
      "성과 평가 결과 환류 · 성과 확산",
    ],
  },
  {
    title: "문화융합커리어개발센터",
    list: [
      "수강생 진로설계, 역량개발 및 취업지원",
      "네트워킹 기반 멘토링 프로그램, 간담회, 채용상담 운영",
      "취창업 관련 연구조사 기반 확립 (채용현황 조사, 진로의식조사)",
      "학생서포터즈 운영지원, 소셜네트워크시스템 운영",
      "융합실기/실습 과정지도·멘토링\n진로취창업컨설팅,취창업/창작컨설팅",
    ],
  },
  {
    title: "글로벌연계·지원센터",
    list: [
      "글로벌 연계 및 지원 총괄",
      "글로벌 진출을 위한 통·번역 지원",
      "가족/협업기업·기관멘토 POOL 관리,연계",
      "글로벌 연계 취창업/창작 및 현장실습 지원",
    ],
  },
];

export const univList = [
  {
    title: "글로벌·문화 컨소시엄 리스트",
    list: [
      {
        univ: "단국대학교",
        url: "dku",
        link: "https://www.dankook.ac.kr/web/kor",
      },
      {
        univ: "동서대학교",
        url: "dsu",
        link: "https://www.dongseo.ac.kr/kr/",
      },
      {
        univ: "원광대학교",
        url: "wku",
        link: "https://www.wku.ac.kr/",
      },
      {
        univ: "청강문화산업대학교",
        url: "cku",
        link: "https://www.ck.ac.kr/",
      },
      {
        univ: "한서대학교",
        url: "hsu",
        link: "https://www.hanseo.ac.kr/intro2/intro.html",
      },
    ],
  },
  {
    title: "디지털컨소시엄",
    list: [
      {
        univ: "고려대학교(주관)",
        url: "ku",
        link: "https://www.korea.ac.kr/mbshome/mbs/university/index.do",
      },
      {
        univ: "숙명여자대학교",
        url: "skmu",
        link: "https://www.sookmyung.ac.kr/kr/index.do",
      },
      {
        univ: "국립순천대학교",
        url: "scnu",
        link: "https://www.scnu.ac.kr/SCNU/main.do?introChk=Y",
      },
      {
        univ: "영남대학교",
        url: "ynu",
        link: "https://homep.yu.ac.kr/index.php",
      },
      {
        univ: "충남대학교",
        url: "cnnu",
        link: "https://plus.cnu.ac.kr/html/kr/",
      },
    ],
  },
  {
    title: "환경컨소시엄",
    list: [
      {
        univ: "국민대학교(주관)",
        url: "kmu",
        link: "https://kookmin.ac.kr/user/index.do",
      },
      {
        univ: "덕성여자대학교",
        url: "dswu",
        link: "https://www.duksung.ac.kr/main.do",
      },
      {
        univ: "울산대학교",
        url: "wsu",
        link: "https://www.ulsan.ac.kr/kor/Main.do",
      },
      {
        univ: "인하대학교",
        url: "ihu",
        link: "https://www.inha.ac.kr/kr/index.do",
      },
      {
        univ: "조선대학교",
        url: "csu",
        link: "https://www3.chosun.ac.kr/chosun/index.do",
      },
    ],
  },
  {
    title: "위험사회컨소시엄",
    list: [
      {
        univ: "선문대학교(주관)",
        url: "smu",
        link: "https://lily.sunmoon.ac.kr/MainDefault3.aspx",
      },
      {
        univ: "세종대학교",
        url: "sju",
        link: "http://www.sejongpr.ac.kr/index.do",
      },
      {
        univ: "국립순천대학교",
        url: "scnu",
        link: "https://www.scnu.ac.kr/SCNU/main.do?introChk=Y",
      },
      {
        univ: "순천향대학교",
        url: "schu",
        link: "https://home.sch.ac.kr/sch/index.jsp",
      },
      {
        univ: "국립한밭대학교",
        url: "hbu",
        link: "https://www.hanbat.ac.kr/",
      },
    ],
  },
  {
    title: "인구구조컨소시엄",
    list: [
      {
        univ: "성균관대학교(주관)",
        url: "skku",
        link: "https://www.skku.edu/skku/index.do",
      },
      {
        univ: "가천대학교",
        url: "gcu",
        link: "https://www.gachon.ac.kr/kor/index.do",
      },
      {
        univ: "건양대학교",
        url: "kyu",
        link: "https://www.konyang.ac.kr/kor.do",
      },
      {
        univ: "충남대학교",
        url: "cnnu",
        link: "https://plus.cnu.ac.kr/html/kr/",
      },
      {
        univ: "한동대학교",
        url: "hgu",
        link: "https://www.handong.edu/kor/",
      },
    ],
  },
];
