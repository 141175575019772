<template>
  <div class="wrapper">
    <PretendardText class="title" v-html="title" :style="style" />
    <PretendardText class="content" v-html="content" :style="style" />
  </div>
</template>
<script>
import PretendardText from "@/components/atoms/text/PretendardText.vue";

export default {
  name: "KUniverseCard",
  components: {
    PretendardText,
  },
  props: {
    title: String,
    style: [String, Object],
    content: String,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  max-width: 390px;
  height: 390px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px rgba(40, 40, 40, 0.15);
  padding-top: 40px;

  @include mobile {
    max-width: 200px;
    height: 259px;
    padding-top: 20px;
  }
}
.title {
  color: $white;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.6px;
  text-align: center;
  margin-bottom: 15px;

  @include mobile {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.content {
  color: $white;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.6px;
  text-align: center;

  @include mobile {
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
  }
}
</style>
