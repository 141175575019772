<template>
  <div class="wrapper">
    <img src="../../assets/img/supporter_logo.png" alt="" />
    <PretendardText class="text">{{
      text ? text : "서포터즈 준비중입니다."
    }}</PretendardText>
  </div>
</template>

<script>
import PretendardText from "@/components/atoms/text/PretendardText.vue";

export default {
  name: "SupporterReady",
  components: { PretendardText },
  props: {
    text: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  background: #f7fafc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  .text {
    color: $g03;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.6px;
  }
}
</style>
