<script setup>
import { ref, onBeforeMount } from "vue";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { route } from "@/router/route";
import { displayHooks } from "@/hooks/useDisplay";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
const toast = useToast();

let useDisplayRef = ref(displayHooks);
let menuValue = ref("");

let mainSectionMenu = ref([]);

onBeforeMount(() => {
  getMenu();
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[0].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`intro${index + 1}`],
          });
        }

        if (item.homeLink === "/intro4") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <SubpageHeader />
  <MainSection
    bgUrl="intro"
    :options="mainSectionMenu"
    :menuValue="menuValue"
    mainHeader="사업소개"
    subHeader01="Home"
    subHeader02="사업소개"
  />

  <BodyContain
    :style="
      useDisplayRef.isMobile ? `padding-bottom: 60px` : `padding-bottom: 120px`
    "
  >
    <PretendardText class="intro-title">초연결형 We러닝</PretendardText>
    <div
      :class="isMobile ? `` : `flex-col`"
      :style="useDisplayRef.isMobile ? `gap: 10px` : `gap: 30px`"
    >
      <div class="box box1">
        <PretendardText class="title">디지털 인프라 공유</PretendardText>
        <PretendardText class="content"
          >크리버스 통합 플랫폼(Creavers Integrated Platform),<br />
          1:N 텔레프레즌스, 디지털 리빙랩 시어터 구축･운영</PretendardText
        >
      </div>
      <div class="box box2">
        <PretendardText class="title">물리적 인프라 공유</PretendardText>
        <PretendardText class="content"
          >멀티미디어･온라인 교육환경, 크리에이티브 스튜디오,<br />
          커뮤니케이션 오픈 스페이스 등 개선･신축,
          <br v-if="useDisplayRef.isMobile" />지자체 보유 인프라
          활용</PretendardText
        >
      </div>
      <div class="box box3">
        <PretendardText class="title">인재 역량 공유</PretendardText>
        <PretendardText class="content"
          >글로벌 K-컬처 융합연구소, AI 진로 코칭, 지역별 인재 매칭,<br />
          K-컬처 분야별 인재 매칭</PretendardText
        >
      </div>
    </div>
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.intro-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 50px;

  @include mobile {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.box {
  width: 100%;
  height: 300px;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 10px;

  @include mobile {
    padding: 20px;
  }

  & .title {
    color: $white;
    font-size: 24px;
    font-weight: 600;
    line-height: 120%; /* 16.8px */
    letter-spacing: -0.6px;

    @include mobile {
      font-size: 18px;
    }
  }

  & .content {
    color: $g08;
    font-size: 18px;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    letter-spacing: -0.6px;

    @include mobile {
      font-size: 14px;
    }
  }
}

.box1 {
  background-image: url("../../../assets/img/intro/running1.png");

  @include mobile {
    background-image: url("../../../assets/img/intro/m_running1.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.box2 {
  background-image: url("../../../assets/img/intro/running2.png");
  @include mobile {
    background-image: url("../../../assets/img/intro/m_running2.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.box3 {
  background-image: url("../../../assets/img/intro/running3.png");
  @include mobile {
    background-image: url("../../../assets/img/intro/m_running3.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}
</style>
