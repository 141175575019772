<script setup>
import { defineProps, ref } from "vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";

const props = defineProps({
  url: { type: String, required: true, default: "" },
  content: { type: String, required: true, default: "" },
});

const isHover = ref(false);
</script>

<template>
  <div class="border-gradient">
    <div
      class="box-wrapper"
      @mouseover="isHover = true"
      @mouseleave="isHover = false"
    >
      <div class="text-wrapper">
        <img
          :src="props.url"
          alt=""
          style="
            margin-bottom: 5px;
            max-width: 200px;
            max-height: 50px;
            object-fit: cover;
          "
        />
        <PretendardText class="content"
          ><span v-html="props.content"
        /></PretendardText>
      </div>
      <div class="flex-row-align-center" style="gap: 8px; margin-top: 25px">
        <img src="../../../assets/ico/intro/homepage.png" alt="" />
        <img src="../../../assets/ico/intro/blog.png" alt="" />
        <img src="../../../assets/ico/intro/youtube.png" alt="" />
        <img src="../../../assets/ico/intro/insta.png" alt="" />
        <img src="../../../assets/ico/intro/tel.png" alt="" />
      </div>
      <img
        v-if="isHover"
        class="univ-plus"
        src="../../../assets/ico/intro/univ_plus.png"
        alt=""
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.border-gradient {
  width: 100%;
  max-width: 386px;
  border-radius: 10px;
  border: 1px solid $line;

  @include mobile {
    max-width: 100%;
  }

  @include tablet {
    max-width: unset;
    flex-basis: calc(50% - 10px);
  }

  &:hover {
    border-radius: 10px;
    border: 1px solid transparent;
    background-image: linear-gradient(#fff, #fff),
      linear-gradient(90deg, #044a85 1.64%, #54966e 47.24%, #f6e382 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 0px 9.621px 26.457px 0px rgba(0, 0, 0, 0.15);
  }
}

.box-wrapper {
  display: flex;
  width: 100%;
  height: 280px;
  padding: 30px 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #fff;
  position: relative;
  cursor: pointer;
  border-radius: 10px;

  .text-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    img {
      align-self: center;
    }
  }
}

.content {
  color: $g04;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.6px;
  white-space: pre-wrap;
}

.univ-plus {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
