import api from "./api";

export default class NoticeApi {
  static async get(data) {
    return await api.get(`notices`, data);
  }

  static async getById(id) {
    return await api.get(`notice/view/${id}`);
  }
}
