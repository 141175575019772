export function downloadFile(response, filename) {
  const url = window.URL.createObjectURL(
    new Blob([response.data], {
      type: response.headers["content-type"],
    })
  );

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}`);
  link.style.cssText = "display:none";
  document.body.appendChild(link);
  link.click();
  link.remove();
}
