<script setup>
import { computed, ref, watch, onBeforeMount } from "vue";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import UnivBox from "@/components/organisms/intro/univBox.vue";
import UnivModal from "@/components/templates/modal/intro/univModal.vue";
import { route } from "@/router/route";
import { displayHooks } from "@/hooks/useDisplay";
import CooperationApi from "@/api/cooperation";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
const toast = useToast();

let useDisplayRef = ref(displayHooks);
let menuValue = ref("");

const modal = ref(0);

let mainSectionMenu = ref([]);

onBeforeMount(() => {
  getMenu();
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[0].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`intro${index + 1}`],
          });
        }

        if (item.homeLink === "/intro5") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const changeModal = (i) => {
  modal.value = i;
};

const closeModal = () => {
  modal.value = 0;
};

const isModalZero = computed(() => {
  return modal.value !== 0;
});

watch(modal, () => {
  if (modal.value !== 0) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
});

const coInfo = ref({});

onBeforeMount(() => {
  getApi(true);
});

const getApi = async () => {
  try {
    const response = await CooperationApi.get({ useFlag: true });
    if (response.data.data) {
      coInfo.value = response.data?.data?.content;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <SubpageHeader />
  <MainSection
    bgUrl="intro"
    :options="mainSectionMenu"
    :menuValue="menuValue"
    mainHeader="사업소개"
    subHeader01="Home"
    subHeader02="사업소개"
  />

  <BodyContain
    :style="
      useDisplayRef.isMobile ? `padding-bottom: 60px` : `padding-bottom: 120px`
    "
  >
    <PretendardText class="intro-title">참여대학</PretendardText>
    <PretendardText
      class="text-24"
      :style="
        useDisplayRef.isMobile ? `margin-bottom: 30px` : `margin-bottom: 40px`
      "
      >대학별 역량과 추진전략·과제</PretendardText
    >
    <img
      v-if="!useDisplayRef.isMobile"
      src="../../../assets/img/intro/recommend.png"
      alt=""
      style="margin-bottom: 80px"
    />
    <div class="flex-row-align-center" style="gap: 20px; flex-wrap: wrap">
      <UnivBox
        v-for="(item, index) in coInfo"
        :key="index"
        :url="item.imageUrl"
        :content="item.intro"
        @click="changeModal(index + 1)"
      />
    </div>
  </BodyContain>
  <MainFooter />
  <UnivModal
    v-if="isModalZero"
    :modalValue="coInfo[modal - 1]"
    @closeModal="closeModal"
  />
</template>

<style lang="scss" scoped>
.intro-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 50px;

  @include mobile {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.text-24 {
  color: $g02;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;
}
</style>
