<script setup>
import { defineProps, toRefs } from "vue";

const props = defineProps({
  style: [String, Object],
  innerStyle: String,
});
const { style, innerStyle } = toRefs(props);
</script>

<template>
  <div class="conatiner-wrapper" :style="style">
    <div class="conatiner" :style="innerStyle">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@include pc {
  .conatiner-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 120px 0px 193px 0px;
    background-color: #fff;
  }

  .conatiner {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}

@include tablet {
  .conatiner-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 120px 40px 193px 40px;
    box-sizing: border-box;
    background-color: #fff;
  }

  .conatiner {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}

@include mobile {
  .conatiner-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 60px 20px 85px 20px;
    box-sizing: border-box;
    background-color: #fff;
  }

  .conatiner {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
</style>
