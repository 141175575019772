import api from "./api";

export default class MenuApi {
  static async get(data) {
    return await api.get(`menus`, data);
  }
  static async getQuick(data) {
    return await api.get(`directs`, data);
  }
}
