<script setup>
import { defineProps, defineModel } from "vue";
const model = defineModel();

const props = defineProps({
  style: [String, Object],
  value: String,
  label: String,
  id: String,
  checked: Boolean,
});
</script>

<template>
  <div class="conatiner-wrapper" :style="props.style">
    <input
      type="radio"
      class="input-container"
      v-model="model"
      :value="props.value"
      :id="props.id"
      :checked="props.checked"
    />
    <label :for="props.id">{{ props.label }}</label>
  </div>
</template>

<style lang="scss" scoped>
.conatiner-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.input-container {
  appearance: none;
  width: 16px;
  height: 16px;
  margin-top: -1px;
  background: url("../../../assets/ico/btn/unchecked.png") center center
    no-repeat;
  cursor: pointer;

  &:checked {
    background: url("../../../assets/ico/btn/checked.png") center center
      no-repeat;
    border: none;
  }
}

label {
  color: $g02;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.6px;
  cursor: pointer;

  @include mobile {
    font-size: 14px;
  }
}
</style>
