<script setup>
import { ref, onBeforeMount, watch } from "vue";
import { useRouter } from "vue-router";
import { route } from "@/router/route";
import { format } from "date-fns";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import TabBar from "@/components/organisms/tab/tabBar.vue";
import SearchInput from "@/components/molcules/input/searchInput.vue";
import BtmPagination from "@/components/molcules/pagination/btmPagination.vue";
import SelectBox from "@/components/molcules/input/selectBox.vue";
import MenuApi from "@/api/menu";
import ActivityApi from "@/api/activity";
import { displayHooks } from "@/hooks/useDisplay";
import { useToast } from "vue-toastification";
import EmptyTable from "@/components/organisms/emptyTable.vue";
const toast = useToast();

const university = ref({ name: "단국대학교", value: "DANKOOK" });

const router = useRouter();
let useDisplayRef = ref(displayHooks);

let last = ref(false);
let first = ref(false);
let page = ref(0);
let maxPage = ref(0);
let step = ref(0);
let total = ref(0);
let listSize = ref(9);
let paginationSize = ref(5);

let keyword = ref("");
let mainSectionMenu = ref([]);

const goPrevPage = () => {
  if (!first.value) {
    page.value--;
    getApi();
  } else return;

  if (page.value < paginationSize.value * step.value) {
    step.value--;
  }
};

const goNextPage = () => {
  if (!last.value) {
    page.value++;
    getApi();
  } else return;

  if (page.value + 1 > paginationSize.value * (step.value + 1)) {
    step.value++;
  }
};

const goPage = (index) => {
  page.value = index;
  getApi();
};

onBeforeMount(() => {
  getMenu();
  getApi(true);
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[2].subMenus;
      subMenu.map((item, index) => {
        arr.push({
          value: item.title,
          label: item.title,
          routeName: route[`support${index + 1}`],
        });
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const list = ref([]);

const getApi = async (initial) => {
  try {
    if (initial) {
      total.value = 0;
      page.value = 0;
      step.value = 0;
      paginationSize.value = 5;
    }
    const response = await ActivityApi.get({
      size: listSize.value,
      page: page.value,
      keyword: keyword.value,
      school: university.value?.value,
      useFlag: true,
    });

    if (response.data.data) {
      last.value = response.data?.data?.last;
      first.value = response.data?.data?.first;
      list.value = response.data?.data?.content;
      total.value = response.data.data.totalElements;

      maxPage.value = Math.ceil(total.value / listSize.value);
      if (maxPage.value <= paginationSize.value) {
        paginationSize.value = maxPage.value;
      }
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const univList = ref([
  { name: "단국대학교", value: "DANKOOK" },
  { name: "동서대학교", value: "DONGSEO" },
  { name: "원광대학교", value: "WONKWANG" },
  { name: "청강대학교", value: "CHUNGKANG" },
  { name: "한서대학교", value: "HANSEO" },
]);

const setUniv = (selectedUniv) => {
  university.value = selectedUniv;
};

const detailRouter = async (id) => {
  router.push({ path: `${route.activityDetail}/${id}` });
};

watch(university, () => {
  getApi(true);
});
</script>

<template>
  <SubpageHeader />
  <MainSection
    bgUrl="support"
    :options="mainSectionMenu"
    menuValue="비교과 활동"
    mainHeader="학생지원"
    subHeader01="Home"
    subHeader02="학생지원"
  />

  <BodyContain
    :style="useDisplayRef.isMobile ? `padding-top: 30px` : `padding-top: 80px`"
  >
    <SelectBox
      v-if="useDisplayRef.isMobile"
      :list="univList"
      @setValue="setUniv"
      :value="university.name"
      style="background-color: #f4f4f4"
      textStyle="color: #044A85; font-weight: 600"
    />
    <div v-if="!useDisplayRef.isMobile" class="tab-container">
      <TabBar
        :active="university.value === `DANKOOK`"
        @click="setUniv(univList[0])"
        title="단국대학교"
        style="border-right: 0px"
      />
      <TabBar
        :active="university.value === `DONGSEO`"
        @click="setUniv(univList[1])"
        title="동서대학교"
        style="border-right: 0px"
      />
      <TabBar
        :active="university.value === `WONKWANG`"
        @click="setUniv(univList[2])"
        title="원광대학교"
      />
      <TabBar
        :active="university.value === `CHUNGKANG`"
        @click="setUniv(univList[3])"
        title="청강문화산업대학교"
      />
      <TabBar
        :active="university.value === `HANSEO`"
        @click="setUniv(univList[4])"
        title="한서대학교"
      />
    </div>

    <div
      class="top-title-wrapper"
      :class="useDisplayRef.isMobile ? 'flex-col' : 'flex-row-between'"
    >
      <PretendardText class="header-title">비교과 활동</PretendardText>
      <SearchInput
        v-model="keyword"
        placeholder="검색어를 입력하세요."
        @click="getApi(true)"
      />
    </div>

    <div class="list-wrapper">
      <EmptyTable v-if="list.length <= 0" />
      <div
        v-else
        v-for="(item, index) in list"
        :key="index"
        class="item"
        @click="detailRouter(item.id)"
      >
        <img
          :src="
            item.imageUrl
              ? item.imageUrl
              : require(`@/assets/img/img_empty.png`)
          "
        />
        <PretendardText class="title">{{ item.title }} </PretendardText>
        <PretendardText class="date"
          >등록일 : {{ format(new Date(item.createdAt), "yyyy-MM-dd") }}
        </PretendardText>
      </div>
    </div>
    <BtmPagination
      :page="page"
      :maxPage="maxPage"
      :step="step"
      :last="last"
      :first="first"
      :total="total"
      :listSize="listSize"
      :paginationSize="paginationSize"
      @goPrevPage="goPrevPage"
      @goNextPage="goNextPage"
      @goPage="goPage"
    />
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.header-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 20px;
    margin-bottom: 15px;
  }
}

.top-title-wrapper {
  margin-bottom: 50px;
  margin-top: 111px;
  @include mobile {
    margin-bottom: 45px;
    margin-top: 60px;
  }
}

.tab-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;

  width: 100%;
  height: 70px;
}

.list-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  .item {
    width: 386px;
    height: 330px;
    position: relative;
    cursor: pointer;
    border: 1px solid #ebebeb;
    border-radius: 12px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @include mobile {
      height: fit-content;
    }

    @include tablet {
      flex-basis: calc(50% - 10px);
      height: fit-content;
    }

    & > .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
      color: #282828;
      margin-top: 15px;
      flex: 1;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      @include mobile {
        margin: 15px 0;
      }
    }

    & > .date {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      color: #717377;
    }

    & > img {
      width: 100%;
      height: 188px;
    }
  }
}
</style>
