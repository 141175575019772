<template>
  <div class="wrapper" :style="{ backgroundColor: backgroundColor }">
    <div>
      <PretendardText :class="['content', { contentBlack: isBlack }]">{{
        content
      }}</PretendardText>
      <PretendardText :class="['title', { titleBlack: isBlack }]">{{
        title
      }}</PretendardText>
    </div>
    <img :src="require(`@/assets/ico/home/track${img}.png`)" alt="" />
  </div>
</template>
<script>
import PretendardText from "@/components/atoms/text/PretendardText.vue";

export default {
  name: "TrackTypeBox",
  components: {
    PretendardText,
  },
  props: {
    title: String,
    content: String,
    isBlack: Boolean,
    img: String,
    backgroundColor: String,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  max-width: 358px;
  height: 80px;
  border-radius: 10px;
  padding: 18px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include small {
    max-width: unset;
  }
}
.title {
  color: $white;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 16px;
  }
}
.content {
  color: $white;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.6px;
  margin-bottom: 3px;

  @include mobile {
    font-size: 12px;
  }
}

.titleBlack {
  color: $black;
}

.contentBlack {
  color: $g01;
}
</style>
