<script setup>
import { defineProps, ref } from "vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { displayHooks } from "@/hooks/useDisplay";

let useDisplayRef = ref(displayHooks);

const isOpen = ref(false);

const props = defineProps({
  title: { type: String, required: true, default: "" },
  level: { type: String, required: true, default: "" },
  credit: { type: [String, Number], Number: true, default: "" },
  teacher: { type: String, required: true, default: "" },
});
</script>

<template>
  <div
    class="box-wrapper"
    @click="!useDisplayRef.isMobile ? $emit('waveClick') : ``"
  >
    <div class="title-wrapper">
      <PretendardText
        class="title"
        @click="useDisplayRef.isMobile ? $emit('waveClick') : ``"
        >{{ props.title }}</PretendardText
      >
      <img
        v-if="useDisplayRef.isMobile"
        src="../../../assets/ico/btn/arrow_down.png"
        alt=""
        :class="isOpen ? `icon rotateToBottom` : `icon`"
        @click="isOpen = !isOpen"
      />
    </div>
    <div
      v-if="!useDisplayRef.isMobile || isOpen"
      class="flex-col m-content-wrapper"
      style="width: 100%; gap: 8px"
    >
      <div class="content-wrapper">
        <PretendardText class="text-14">수준</PretendardText>
        <PretendardText class="content">{{ props.level }}</PretendardText>
      </div>
      <div class="content-wrapper">
        <PretendardText class="text-14">학점</PretendardText>
        <PretendardText class="content">{{ props.credit }}</PretendardText>
      </div>
      <div class="content-wrapper">
        <PretendardText class="text-14">교강사</PretendardText>
        <PretendardText class="content">{{ props.teacher }}</PretendardText>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title-wrapper {
  @include mobile {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 15px 20px;
    border-bottom: 1px solid $line;
  }
}

.box-wrapper {
  display: flex;
  flex-basis: calc(100% / 3);
  width: 100%;
  max-width: 385px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(40, 40, 40, 0.15);
  padding: 20px;
  cursor: pointer;

  @include mobile {
    box-shadow: unset;
    padding: 0px;
  }
}

.title {
  color: $primary4;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.6px;
  margin-bottom: 20.5px;

  @include mobile {
    color: $black;
    font-size: 14px;
    margin-bottom: 0px;
  }
}

.text-14 {
  color: $black;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.6px;
}

.content {
  @extend .text-14;
  color: $g02;
  font-weight: 400;
}

.content-wrapper {
  width: 100%;
  display: flex;
  padding: 8px 15px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: #f7fafc;

  @include mobile {
    background: #fff;
  }
}

@include mobile {
  .m-content-wrapper {
    padding: 20px;
    gap: 8px;
    background: #f7fafc;
  }
}

.icon {
  width: 16px;
  height: 16px;
  transition: all ease 0.2s;

  &.rotateToBottom {
    transform: rotate(180deg);
  }
}
</style>
