<script setup>
import { ref, onBeforeMount } from "vue";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { route } from "@/router/route";
import { displayHooks } from "@/hooks/useDisplay";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
const toast = useToast();

let useDisplayRef = ref(displayHooks);
let menuValue = ref("");

onBeforeMount(() => {
  getMenu();
});

let mainSectionMenu = ref([]);

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[0].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`intro${index + 1}`],
          });
        }

        if (item.homeLink === "/intro3") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const blueBoxcontent = [
  {
    type: "Creativity",
    title: "창의력",
    content:
      "K-컬처의 정체성과 특성을 이해하고 재해석하여\n세계인이 공감할 수 있는 새로운 가치를 창출하는 능력",
  },
  {
    type: "Communication",
    title: "소통력",
    content:
      "다양한 문화권과 지역의 현황을 파악하고 향유집단 간\n소통･교류를 통해 문제를 해결하며 K-컬처 확산을 도모하는 능력",
  },
  {
    type: "Critical Thinking",
    title: "문제해결･분석력",
    content:
      "새로운 도전과 상황에서 문제를 식별하고 타당한 해결책을\n제시할 수 있는 문제해결 및 분석 능력",
  },
  {
    type: "Content Production and Technical Skills",
    title: "콘텐츠 제작･기술력",
    content:
      "지속 가능한 윤리적 전문가가 되기 위하여\n배우고 성찰하는 주도적 태도",
  },
  {
    type: "Convergence",
    title: "융합력",
    content:
      "다양한 지식과 경험을 능동적으로 결합하고 활용하여\nK-컬처 관련 이슈를 적극적으로 형성하고 주도하는 능력",
  },
];
</script>

<template>
  <SubpageHeader />
  <MainSection
    bgUrl="intro"
    :options="mainSectionMenu"
    :menuValue="menuValue"
    mainHeader="사업소개"
    subHeader01="Home"
    subHeader02="사업소개"
  />

  <BodyContain
    :style="
      useDisplayRef.isMobile ? `padding: 60px 0` : `padding-bottom: 120px`
    "
  >
    <div class="title-wrapper">
      <PretendardText class="intro-title">핵심역량</PretendardText>
      <PretendardText
        class="text-16"
        :style="
          useDisplayRef.isMobile ? `margin-bottom: 30px` : `margin-bottom: 50px`
        "
        >글로벌 K-컬처 인재에게 요구되는 창의력 (Creativity),
        <br v-if="useDisplayRef.isMobile" />
        소통력(Communication), 문제해결･분석력(Critical Thinking),
        <br v-if="useDisplayRef.isMobile" />콘텐츠 제작･기술력(Content
        Production and Technical Skills),<br v-if="useDisplayRef.isDesktop" />
        융합력(Convergence)을 갖춘 인재 양성</PretendardText
      >
    </div>
    <div style="display: flex; justify-content: center">
      <img
        src="../../../assets/img/intro/s_people2.png"
        alt=""
        :style="
          useDisplayRef.isMobile
            ? `width: 100%; margin-bottom: 30px`
            : `width: 666px`
        "
        style="filter: drop-shadow(10px 10px 36px rgba(0, 0, 0, 0.16));"
      />
    </div>
    <div class="blue-box-wrapper">
      <div
        v-for="(item, index) in blueBoxcontent"
        :key="index"
        class="blue-box"
      >
        <PretendardText class="type">{{ item.type }}</PretendardText>
        <PretendardText class="title">{{ item.title }}</PretendardText>
        <PretendardText class="content">{{ item.content }}</PretendardText>
      </div>
    </div>
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.intro-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 20px;

  @include mobile {
    font-size: 20px;
  }
}

.title-wrapper {
  @include mobile {
    padding: 0 20px;
  }
}

.text-16 {
  color: $g03;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 14px;
  }
}

.text-24 {
  color: $g02;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;
}

.blue-box-wrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @include mobile {
    padding: 0 20px;
  }
}

.blue-box {
  width: 386px;
  height: 150px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  border-radius: 10px;
  background: #f7fafc;

  @include tablet {
    width: 100%;
    flex-basis: calc(50% - 10px);
  }

  @include mobile {
    width: 100%;
  }

  & .type {
    color: #044a85;
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.6px;
  }

  & .title {
    color: $g01;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.6px;
  }

  & .content {
    color: $g04;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.6px;
    white-space: pre-wrap;
  }
}
</style>
