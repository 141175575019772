<template>
  <div class="item-wrapper">
    <div class="box">
      <SummaryTitle title="학생지원소개" :link="route.support1" />
      <div class="info-warpper">
        <div class="info-box">
          <PretendardText class="title">이수인증</PretendardText>
          <PretendardText class="text"
            >컨소시엄 참여 대학별 총장 명의의 인증서 및 학위 내역 발급 </PretendardText
          >
        </div>
        <div class="info-box">
          <PretendardText class="title">진로취창업 프로그램</PretendardText>
          <PretendardText class="text"
            >AI진로코칭 기반 학습/진로/취창업 컨설팅</PretendardText
          >
        </div>
        <div class="info-box">
          <PretendardText class="title">교육 기자재 지원</PretendardText>
          <PretendardText class="text"
            >VR/AR 및 홀로그램 강의실, 텔레프레즌스 강의실, 학습기자재 대여
            <br />자격증 취득, 공모전, 경진대회 지원 <br />K컬처 분야 자격증 취득,
            공모작품 제작, 경진대회 출품 지원</PretendardText
          >
        </div>
        <div class="info-box">
          <PretendardText class="title">현장실습 및 인턴십</PretendardText>
          <PretendardText class="text"
            >AI진로코칭매칭 실무 리빙랩(PBL/PjBL), 자기설계 현장실습 및
            인턴십</PretendardText
          >
        </div>
        <div class="info-box">
          <PretendardText class="title">비교과 프로그램</PretendardText>
          <PretendardText class="text"
            >팀프로젝트, 경시대회, 동아리, 튜터링, 탄뎀(Tandem) 학습등 활동
            지원</PretendardText
          >
        </div>
      </div>
    </div>

    <div class="box">
      <SummaryTitle title="서포터즈" :link="route.support2" />
      <div style="height: 100%">
        <SupporterReady />
      </div>
    </div>
  </div>
</template>

<script>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import SummaryTitle from "@/components/molcules/header/SummaryTitle.vue";
import SupporterReady from "../SupporterReady.vue";
import { route } from "@/router/route";

export default {
  name: "SummaryBox3",
  components: { PretendardText, SummaryTitle, SupporterReady },
  data() {
    return { route };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.item-wrapper {
  gap: 50px;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.info-warpper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  gap: 10px;
  border-radius: 10px;
  background: #f7fafc;

  & .info-box {
    display: flex;
    align-items: center;
    gap: 20px;

    & .title {
      display: flex;
      width: 135px;
      padding: 8px 0px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 50px;
      border: 1px solid #044a85;
      background: $white;
      color: #044a85;
      font-size: 14px;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: -0.6px;
    }

    & .text {
      color: $g03;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.6px;
    }
  }
}
</style>
