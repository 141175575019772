<template>
  <div class="item-wrapper">
    <div class="box1">
      <div>
        <PretendardText class="badge">우수성과</PretendardText>
        <PretendardText class="title"
          >글로벌 K-컬처 컨소시엄 사업단의<br />
          <span style="font-weight: 600; color: #044a85">우수성과</span>를
          확인하세요.</PretendardText
        >
      </div>
      <div class="more-wrapper" @click="menuRouter(route.sharing1)">
        <div class="circle"></div>
        <PretendardText class="more-text">더보기</PretendardText>
        <img src="../../../assets/ico/btn/more_arrow.png" alt="" />
      </div>
    </div>
    <div class="box2">
      <div>
        <PretendardText
          class="title"
          style="font-weight: 600; margin-bottom: 13px"
          >K-MOOC 수업들을<br />
          확인할 수 있어요.</PretendardText
        >
        <PretendardText class="text"
          ><span style="font-weight: 600">무크(MOOC)</span>란 Massive, Open,
          Online,<br />
          Course의 줄임말로 오픈형 온라인 학습 과정입니다.</PretendardText
        >
      </div>
      <div class="more-wrapper" @click="menuRouter(route.sharing2)">
        <div class="circle"></div>
        <PretendardText class="more-text">더보기</PretendardText>
        <img src="../../../assets/ico/btn/more_arrow.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { router } from "@/router";
import { route } from "@/router/route";

export default {
  name: "SummaryBox4",
  components: { PretendardText },
  data() {
    return { route };
  },
  methods: {
    menuRouter(link) {
      router.push({ path: link });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-wrapper {
  gap: 50px;
}

.box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 280px;
  display: flex;
  border-radius: 10px;
  padding: 30px;
  justify-content: space-between;
}

.title {
  color: $g02;
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.6px;
}

.text {
  color: $g02;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.6px;
}

.box1 {
  @extend .box;
  background: url("../../../assets/img/header/performance.png") center center
    no-repeat;
  background-size: cover;

  & .badge {
    width: 75px;
    height: 25px;
    display: flex;
    padding: 4px 14px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 999px;
    background: #044a85;
    color: $white;
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.6px;
    margin-bottom: 13px;
  }
}

.box2 {
  @extend .box;
  background: url("../../../assets/img/header/kmooc.png") center center
    no-repeat;
  background-size: cover;
}

.more-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  & .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $black;
    opacity: 0.1;
  }

  & .more-text {
    color: $black;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.6px;
    margin-left: -10px;
    margin-right: 10px;
  }
}
</style>
