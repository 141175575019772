<template>
  <div class="item-wrapper">
    <div class="box" style="width: 350px; margin-right: 54px">
<!--      <SummaryTitle title="공지사항 소개" :link="route.plaza1" />-->
      <SummaryTitle title="공지사항 소개" :link="boardLink" />
      <div class="info-warpper">
        <NoticeCard
        />
      </div>
    </div>

    <div class="box" style="width: 396px; margin-right: 50px">
      <SummaryTitle title="보도자료" :link="route.plaza2" />
      <div class="info-warpper">
        <NewsCard/>
<!--        <NewsCard-->
<!--            v-for="(item, index) in list"-->
<!--            :key="index"-->
<!--            :title="item.title"-->
<!--            :date="item.createdAt"-->
<!--        />-->
      </div>
    </div>

    <div class="box" style="width: 350px">
      <img
        style="cursor: pointer"
        src="../../../assets/img/header/question.png"
        alt=""
        @click="menuRouter(route.plaza4)"
      />
    </div>
  </div>
</template>

<script>
import SummaryTitle from "@/components/molcules/header/SummaryTitle.vue";
import NoticeCard from "@/components/molcules/header/NoticeCard.vue";
import NewsCard from "@/components/molcules/header/NewsCard.vue";
import { route } from "@/router/route";
import { router } from "@/router";
import PressApi from "@/api/press";

export default {
  name: "SummaryBox5",
  components: { SummaryTitle, NoticeCard, NewsCard },
  data() {
    return { route,
      boardLink: '/board/1',
      list: [],
      listSize: 10,
      page: 1,
      keyword: '',
      last: false,
      first: false,
      total: 0,
    };
  },
  methods: {
    menuRouter(link) {
      router.push({ path: link });
    },
  },
  async created() {
    const response = await PressApi.get({
      size: this.listSize,
      page: this.page,
      keyword: this.keyword,
      useFlag: true,
    });
    if (response.data.data) {
      this.last = response.data?.data?.last;
      this.first = response.data?.data?.first;
      this.list = response.data?.data?.content;
      this.total = response.data.data.totalElements;
    }
    console.log(this.list);
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-warpper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}
</style>
