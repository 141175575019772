<template>
  <div>
    <img
      class="scroll_top_ico"
      src="../../../assets/ico/scroll_top.png"
      alt=""
      @click="scrollTop"
    />
  </div>
</template>

<script>
export default {
  name: "ScrollTop",
  methods: {
    scrollTop: function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll_top_ico {
  cursor: pointer;
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 50;
}
</style>
