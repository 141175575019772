<script setup>
import { defineProps, ref, defineModel } from "vue";
const model = defineModel();

const props = defineProps({
  style: [String, Object],
  inputStyle: String,
  placeholder: String,
  disabled: { type: Boolean, default: false },
});

let input_flag = ref("#e5e8eb");
</script>

<template>
  <div class="conatiner-wrapper" :style="props.style">
    <textarea
      :style="props.inputStyle"
      class="input-container"
      :placeholder="props.placeholder"
      v-model="model"
      @focus="input_flag = '#007CFF'"
      @blur="input_flag = '#e5e8eb'"
      :disabled="props.disabled"
    />
  </div>
</template>

<style lang="scss" scoped>
.conatiner-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid v-bind(input_flag);
  border-radius: 6px;
}

.input-container {
  width: 100%;
  height: 100%;
  border: 0px;
  box-sizing: border-box;
  padding: 15px 10px;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  color: $g01;
  border-radius: 6px;
  resize: none;

  &:focus {
    background: #fff;
    outline: none;
    border: 0px;
  }

  :disabled {
    background-color: #f2f4f6;
  }

  &::placeholder {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    color: #717377;
  }
}

.input-container::-webkit-scrollbar {
  width: 18px; /* 스크롤바의 너비 */
}

.input-container::-webkit-scrollbar-thumb {
  width: 6px;
  background: $primary4; /* 스크롤바의 색상 */
  border-radius: 999px;
  border: 6px solid #ffffff;
}

.input-container::-webkit-scrollbar-track {
  border-radius: 999px;
  background: #d9d9d9;
  border: 6px solid #ffffff;
}
</style>
