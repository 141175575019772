<template>
  <div class="pretendard-text" :style="style">
    <slot />
  </div>
</template>

<script>
export default {
  name: "PretendardText",
  props: {
    style: [String, Object],
  },
};
</script>

<style scoped>
.pretendard-text {
  font-family: Pretendard;
  font-size: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
</style>
