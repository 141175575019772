<script setup>
import { onBeforeMount, ref } from "vue";
import { menu } from "@/constants/menu";
import PretendardText from "../../atoms/text/PretendardText.vue";
import { route } from "@/router/route";
import { router } from "@/router";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
const toast = useToast();

const list = ref([]);
// const emit = defineEmits(["modalClose"]);

const menuRouter = (item, index, index2) => {
  if (item.contentsFlag) {
    router.push({ path: route.board + `/${item.boardId}` });
    return;
  }
  router.push({ path: route[`${menu[index].link}${index2 + 1}`] });
};

onBeforeMount(() => {
  getMenu();
});

const getMenu = async () => {
  try {
    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      list.value = response.data.data.content.reverse();
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <div class="modal-container">
    <img
      @click="$emit('modalClose')"
      alt=""
      src="../../../assets/ico/x.png"
      class="close-btn"
    />
    <img
      alt=""
      src="../../../assets/img/header_modal_bg.png"
      class="modal-bg"
      spq
      style="z-index: 1"
    />
    <div class="modal-wrapper">
      <div v-for="(item, index) in list" :key="index" class="menu-wrapper">
        <PretendardText class="title">
          {{ item.title }}
        </PretendardText>
        <div class="horizontal-line" />
        <div class="menu-list-wrapper">
          <div
            v-for="(item2, index2) in item.subMenus"
            :key="index2"
            style="display: flex; align-items: center; gap: 8px"
            @click="$emit('modalClose'), menuRouter(item2, index, index2)"
          >
            <div class="modal-dot" />
            <PretendardText class="modal-text">
              {{ item2.title }}
            </PretendardText>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.modal-wrapper {
  display: flex;

  & > .menu-wrapper {
    width: 300px;
    position: relative;
    z-index: 10;

    & > .title {
      color: $black;
      font-family: Pretendard;
      font-size: 35px;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -0.6px;
    }

    & > .horizontal-line {
      width: 100%;
      height: 1px;
      background: $line;
      margin: 32.5px 0;
    }

    & > .menu-list-wrapper {
      display: flex;
      flex-direction: column;
      gap: 30px;

      & .modal-text {
        color: $g03;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.6px;
        cursor: pointer;
      }

      & .modal-dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #d9d9d9;
      }
    }
  }
}

.close-btn {
  position: absolute;
  top: 33px;
  right: 58px;
  cursor: pointer;
}

.modal-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
