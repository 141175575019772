<script setup>
import { defineComponent, ref, watch, onBeforeMount } from "vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import ConsortiumListModal from "@/components/templates/modal/consortiumListModal.vue";
import BaseApi from "@/api/base";
import { router } from "@/router";
import { route } from "@/router/route";
import { displayHooks } from "@/hooks/useDisplay";
import { useToast } from "vue-toastification";

const toast = useToast();

const isOpen = ref(false);
const baseInfo = ref({});

const privacyRouter = () => {
  router.push({ path: route.privacy });
};

const openModal = () => {
  isOpen.value = true;
};

const closeModal = () => {
  isOpen.value = false;
};

watch(isOpen, (newValue) => {
  if (newValue) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
});

defineComponent({
  name: "MainFooter",
  components: {
    PretendardText,
    ConsortiumListModal,
  },
  setup() {
    return {
      isOpen,
      privacyRouter,
      openModal,
      closeModal,
      displayHooks,
    };
  },
});

onBeforeMount(() => {
  getApi();
});

const getApi = async () => {
  try {
    const response = await BaseApi.get();
    if (response.data.data) {
      baseInfo.value = response.data.data;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <div class="first-wrapper">
    <div class="item-wrapper">
      <div class="footer-link">
        <PretendardText class="title">교육플랫폼</PretendardText>
        <div class="line" />
        <PretendardText class="title" @click="privacyRouter"
          >개인정보처리방침</PretendardText
        >
      </div>
      <div class="consortium" @click="openModal">
        <PretendardText class="title">컨소시엄 리스트</PretendardText>
        <img src="../../../assets/ico/footer_consortium.png" alt="" />
      </div>
    </div>
  </div>
  <div class="second-wrapper">
    <div class="item-wrapper">
      <div class="left">
        <img src="../../../assets/img/logo_gray.png" alt="" />
        <div>
          <PretendardText class="text"
            >{{ baseInfo.keywords }} | {{ baseInfo.title }}</PretendardText
          >
          <PretendardText class="text">
            <span v-html="baseInfo.copyRight1" />
          </PretendardText>
        </div>
      </div>
      <div class="right">
        <a href="https://www.nrf.re.kr/index" target="blank">
          <img
            v-if="!displayHooks.isMobile"
            src="../../../assets/img/NRF.png"
            alt=""
          />
          <img v-else src="../../../assets/img/m_NRF.png" alt="" />
        </a>
        <a href="https://www.moe.go.kr/main.do?s=moe" target="blank">
          <img
            v-if="!displayHooks.isMobile"
            src="../../../assets/img/ministry_education.png"
            alt=""
          />
          <img
            v-else
            src="../../../assets/img/m_ministry_education.png"
            alt=""
          />
        </a>
      </div>
    </div>
  </div>
  <ConsortiumListModal v-if="isOpen" @closeModal="closeModal" />
</template>
<!-- 
<script>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import ConsortiumListModal from "@/components/templates/modal/consortiumListModal.vue";
import { router } from "@/router";
import { route } from "@/router/route";
import { displayHooks } from "@/hooks/useDisplay";

export default {
  name: "MainFooter",
  components: {
    PretendardText,
    ConsortiumListModal,
  },
  data() {
    return {
      isOpen: false,
      displayHooks,
    };
  },
  methods: {
    privacyRouter() {
      router.push({ path: route.privacy });
    },
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
};
</script> -->

<style lang="scss" scoped>
.first-wrapper {
  width: 100%;
  padding: 13px 0;
  border-top: 1px solid $line;
  background: #f6f6f6;
  display: flex;
  justify-content: center;

  & .title {
    color: $g04;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.6px;
    cursor: pointer;
  }

  & .item-wrapper {
    display: flex;
    justify-content: space-between;

    & .line {
      width: 1px;
      height: 12px;
      background-color: $g05;
    }

    & .footer-link {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    @include tablet {
      padding: 0 20px;
    }

    @include mobile {
      padding: 0 20px;
    }
  }

  & .consortium {
    display: flex;
    padding: 6px 15px;
    align-items: center;
    gap: 4px;
    border-radius: 999px;
    background: $g04;
    cursor: pointer;

    & .title {
      color: $white;
    }
  }
}
.second-wrapper {
  width: 100%;
  padding: 30px 0;
  background: #eaeaea;
  display: flex;
  justify-content: center;
  position: relative;

  @include mobile {
    padding: 20px;
  }

  & .item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .left {
      display: flex;
      align-items: center;
      gap: 30px;

      & > img {
        height: fit-content;

        @include mobile {
          height: 19px;
        }
      }

      & .text {
        color: #777;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -0.6px;

        @include mobile {
          font-size: 12px;
          line-height: 150%;
        }
      }

      @include mobile {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
      }
    }

    & .right {
      & > img {
        cursor: pointer;

        @include mobile {
          height: 19px;
        }
      }

      @include mobile {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        gap: 10px;
      }
    }
  }
}
</style>
