import api from "./api";

export default class BoardApi {
  static async get(id) {
    return await api.get(`board/${id}`);
  }
  static async getBoard() {
    return await api.get(`boards`);
  }
  static async getContent(data) {
    return await api.get(`boardContents`, data);
  }
  static async getEmergencyContent(data) {
    return await api.get(`boardContents/main`, data);
  }
  static async getPrevContent(contentId, data) {
    return await api.get(`prev/boardContent/${contentId}`, data);
  }
  static async getNextContent(contentId, data) {
    return await api.get(`next/boardContent/${contentId}`, data);
  }
  static async getContentById(id) {
    return await api.get(`boardContent/${id}`);
  }
}
