<script setup>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { router } from "@/router";
import { route } from "@/router/route";
import { displayHooks } from "@/hooks/useDisplay";
import { ref, onBeforeMount } from "vue";
import { format } from "date-fns";
import { useToast } from "vue-toastification";
import BoardApi from "@/api/board";
const toast = useToast();

let useDisplayRef = ref(displayHooks);

let last = ref(false);
let first = ref(false);
let page = ref(0);
let list = ref([]);
let emergency = ref([]);
const boardId = ref(1);

const goNotice = () => {
  // router.push(route.plaza1);
  router.push({ path: route.board + `/${boardId.value}`});
};

onBeforeMount(() => {
  getBoard();
});

const goPrevPage = () => {
  if (!first.value) {
    page.value--;
    getApi();
  } else return;
};

const goNextPage = () => {
  if (!last.value) {
    page.value++;
    getApi();
  } else return;
};

const getBoard = async () => {
  try {
    const response = (await BoardApi.getBoard()).data.data.content;
    if (response.title === "공지사항") {
      boardId.value = response.id;
    }

    getApi(true);
    getEmergencyApi();
  } catch (error) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const getApi = async (initial) => {
  try {
    if (initial) {
      page.value = 0;
    }
    const data = {
      boardId: boardId.value,
      size: 3,
      page: page.value,
      exposureFlag: true,
      useFlag: true,
    };

    const response = (await BoardApi.getContent(data)).data.data;

    if (response) {
      last.value = response.last;
      first.value = response.first;
      list.value = response.content;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const getEmergencyApi = async () => {
  try {
    const data = {
      boardId: boardId.value,
      // size: 10,
      exposureFlag: true,
      emergencyFlag: true,
    };

    const response = (await BoardApi.getEmergencyContent(data)).data.data
      ?.content;

    if (response) {
      response.map((item) => {
        if (item.emergencyFlag) {
          return (emergency.value = item);
        }
      });
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const detailRouter = async (id) => {
  router.push({ path: `${route.boardDetail}/${boardId.value}/${id}` });
};
</script>

<template>
  <div class="flex-col notice-box-wrapper" style="gap: 20px">
    <div class="flex-row-between top-bar">
      <PretendardText class="title">공지사항</PretendardText>
      <div class="flex-row-align-center" style="gap: 8px">
        <img
          src="../../../assets/ico/home/arrow_left.png"
          alt=""
          @click="goPrevPage"
        />
        <img
          src="../../../assets/ico/home/arrow_right.png"
          alt=""
          @click="goNextPage"
        />
      </div>
    </div>
    <div class="box">
      <div class="emergency" @click="detailRouter(emergency.id)">
        <img src="../../../assets/ico/home/notice_alarm.png" />
        <PretendardText class="title">{{ emergency.title }}</PretendardText>
      </div>
      <div>
        <div
          v-for="(item, index) in list"
          :key="index"
          class="notice-wrapper"
          @click="detailRouter(item.id)"
        >
          <PretendardText class="text-16 title">{{
            item.title
          }}</PretendardText>
          <PretendardText class="text-16">{{
            format(new Date(item.createdAt), "yyyy-MM-dd")
          }}</PretendardText>
        </div>
      </div>
      <div
        v-if="!useDisplayRef.isMobile"
        class="more-wrapper"
        @click="goNotice"
      >
        <div class="circle"></div>
        <PretendardText class="more-text">더보기</PretendardText>
        <img src="../../../assets/ico/btn/more_arrow.png" alt="" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-bar {
  align-items: center;

  .title {
    @include mobile {
      font-size: 18px;
    }
  }

  img {
    cursor: pointer;
  }
}

.notice-box-wrapper {
  width: 100%;
  max-width: 590px;

  @include small {
    max-width: 100%;
  }
}

.title {
  color: $g02;
  font-size: 20px;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: -0.6px;
}

.notice-wrapper {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $line;
  cursor: pointer;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .title {
    max-width: 412px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}

.text-16 {
  color: $g04;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.6px;
}

.box {
  display: flex;
  width: 100%;
  height: 305px;
  padding: 20px;
  flex-direction: column;
  gap: 17px;
  border-radius: 10px;
  background: $white;
  box-shadow: 0px 2px 10px 0px rgba(40, 40, 40, 0.15);

  @include mobile {
    gap: 10px;
    height: fit-content;
  }
}

.emergency {
  display: flex;
  height: 50px;
  padding: 13px 10px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: 8px;
  background: var(
    --GRADIENT-01,
    linear-gradient(90deg, #044a85 1.64%, #54966e 47.24%, #f6e382 100%)
  );
  cursor: pointer;

  .title {
    color: $white;
    font-size: 18px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;

    @include mobile {
      font-size: 14px;
    }
  }
}

.more-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  & .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $primary4;
    opacity: 0.1;
  }

  & .more-text {
    color: $black;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.6px;
    margin-left: -10px;
    margin-right: 10px;
  }
}
</style>
