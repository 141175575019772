<script setup>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { defineProps } from "vue";

const props = defineProps({
  page: Number,
  maxPage: Number,
  step: Number,
  last: Boolean,
  first: Boolean,
  total: Number,
  listSize: Number,
  paginationSize: Number,
});
</script>

<template>
  <div v-if="paginationSize" class="pagination-wrapper">
    <div class="arrow-container">
      <img
        class="icon"
        src="@/assets/ico/plaza/left_arrow.svg"
        :style="props.first ? `cursor: default` : ``"
        @click="$emit('goPrevPage')"
      />
    </div>
    <div class="number-wrapper">
      <PretendardText
        v-for="index in paginationSize"
        :key="index"
        class="page-text"
        :style="
          page + 1 === step * paginationSize + index ? `color: #044a85` : ``
        "
        @click="$emit('goPage', step * paginationSize + index - 1)"
        ><span v-if="step * paginationSize + index <= maxPage">
          {{ step * paginationSize + index }}
        </span>
      </PretendardText>
    </div>
    <div class="arrow-container">
      <img
        class="icon"
        src="@/assets/ico/plaza/right_arrow.svg"
        :style="props.last ? `cursor: default` : ``"
        @click="$emit('goNextPage')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pagination-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  .arrow-container {
    display: flex;
    align-items: center;

    .icon {
      cursor: pointer;
    }
  }

  .number-wrapper {
    display: flex;
    align-items: center;

    .page-text {
      width: 32px;
      text-align: center;

      color: #717377;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      cursor: pointer;
    }
  }
}

@include mobile {
  .pagination-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    .arrow-container {
      display: flex;
      align-items: center;

      .icon {
        cursor: pointer;
      }
    }

    .number-wrapper {
      display: flex;
      align-items: center;

      .page-text {
        width: 32px;
        text-align: center;

        color: #717377;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.8px;
        cursor: pointer;
      }
    }
  }
}
</style>
