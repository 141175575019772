<script setup>
import { ref, onBeforeMount } from "vue";
import { route } from "@/router/route";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { useToast } from "vue-toastification";
const toast = useToast();

import { displayHooks } from "@/hooks/useDisplay";
import MenuApi from "@/api/menu";
let useDisplayRef = ref(displayHooks);

let mainSectionMenu = ref([]);

onBeforeMount(() => {
  getMenu();
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[2].subMenus;
      subMenu.map((item, index) => {
        return arr.push({
          value: item.title,
          label: item.title,
          routeName: route[`support${index + 1}`],
        });
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <SubpageHeader />

  <MainSection
    bgUrl="support"
    :options="mainSectionMenu"
    menuValue="지원 소개"
    mainHeader="학생지원"
    subHeader01="Home"
    subHeader02="학생지원"
    containStyle="min-width:100px"
  />

  <BodyContain
    :style="
      useDisplayRef.isMobile ? `padding-bottom: 60px` : `padding-bottom: 141px`
    "
  >
    <div class="flex-row-between">
      <PretendardText class="header-title">통합 학생지원</PretendardText>
    </div>
    <div class="content-wrapper">
      <div
        class="box-wrapper"
        :style="
          !useDisplayRef.isDesktop ? `margin-right: 10px` : `margin-right: 30px`
        "
      >
        <img src="@/assets/img/support/support01.png" />
        <PretendardText class="title">이수인증</PretendardText>
        <PretendardText class="content"
          >컨소시엄사업단 대학별 <br v-if="useDisplayRef.isMobile" />총장명의로
          발급<br />
          (인증서, 졸업장 및 <br v-if="useDisplayRef.isMobile" />졸업증명서)</PretendardText
        >
      </div>
      <div class="box-wrapper">
        <img src="@/assets/img/support/support02.png" />
        <PretendardText class="title">교육 기자재 지원</PretendardText>
        <PretendardText class="content"
          >VR/AR 및 홀로그램 강의실,<br />
          텔레프레즌스 강의실, <br v-if="useDisplayRef.isMobile" />학습기자재
          대여</PretendardText
        >
      </div>
    </div>
    <div
      class="content-wrapper"
      :style="
        !useDisplayRef.isDesktop ? `margin-top: 10px` : `margin-top: 30px`
      "
    >
      <div
        class="box-wrapper"
        :style="
          !useDisplayRef.isDesktop ? `margin-right: 10px` : `margin-right: 30px`
        "
      >
        <img src="@/assets/img/support/support03.png" />
        <PretendardText class="title">비교과 프로그램</PretendardText>
        <PretendardText class="content"
          >팀프로젝트와 경시대회, <br v-if="useDisplayRef.isMobile" />동아리,
          튜터링,<br />
          탄뎀(Tandem) 학습 등 <br v-if="useDisplayRef.isMobile" />다양한 비교과
          활동 지원</PretendardText
        >
      </div>
      <div
        class="box-wrapper"
        :style="
          !useDisplayRef.isDesktop ? `margin-right: 0px` : `margin-right: 30px`
        "
      >
        <img src="@/assets/img/support/support04.png" />
        <PretendardText class="title">현장 실습 및 인턴십</PretendardText>
        <PretendardText class="content"
          >실무 리빙랩(PBL/PjBL)<br v-if="useDisplayRef.isMobile" />
          프로젝트,<br />
          자기설계 현장실습, 인턴십</PretendardText
        >
      </div>
      <div class="box-wrapper"
           :style="
          !useDisplayRef.isDesktop ? `margin-right: 0px` : `margin-right: 30px`
        "
      >
        <img src="@/assets/img/support/support05.png" />
          <PretendardText class="title">취창업 프로그램</PretendardText>
          <PretendardText class="content"
            >AI진로코칭 기반 학습/진로/취창업
            <br v-if="useDisplayRef.isMobile" />컨설팅<br
              v-if="!useDisplayRef.isMobile"
            />
            자격증취득, 공모전, 경진대회
            <br v-if="useDisplayRef.isMobile" />지원<br
              v-if="!useDisplayRef.isMobile"
            />
            K-컬처 분야 자격증 취득,
            <br v-if="useDisplayRef.isMobile" />공모작품 제작, 경진대회 출품
            지원
          </PretendardText>
      </div>
    </div>
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.header-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 50px;

  @include mobile {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include mobile {
    flex-wrap: wrap;
  }

  @include tablet {
    flex-wrap: wrap;
  }

  .box-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;
    height: 300px;
    background: #f4f4f4;
    border-radius: 12px;

    @include mobile {
      flex-basis: calc(50% - 5px);
    }

    @include tablet {
      flex-basis: calc(50% - 5px);
    }

    & > img {
      width: 100px;
      height: 100px;
      margin-bottom: 20px;

      @include mobile {
        width: 80px;
        height: 80px;
      }
    }

    & .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 120%;
      color: #111111;
      margin-bottom: 10px;

      @include mobile {
        font-size: 16px;
      }
    }

    & .content {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      color: #4d5054;
      text-align: center;
    }
  }

  .box-wrapper-last {
    @include mobile {
      margin-top: 10px;
      flex-basis: 100%;
      flex-direction: row;
      gap: 15px;
      height: fit-content;
      padding: 20px 0;

      & .content {
        text-align: left;
      }
    }

    @include tablet {
      margin-top: 10px;
      flex-basis: 100%;
      flex-direction: row;
      gap: 15px;
      height: fit-content;
      padding: 20px 0;

      & .content {
        text-align: left;
      }
    }

    & > img {
      margin-bottom: 0px;
    }
  }
}
</style>
