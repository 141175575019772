<script setup>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
import { route } from "@/router/route";
import PressApi from "@/api/press";
import { useToast } from "vue-toastification";
import { format } from "date-fns";

const toast = useToast();
const router = useRouter();

const title = ref('');
const createdAt = ref('');
const cardId = ref(null);
const imgUrl =ref('');
onBeforeMount(() => {
  getApi();
});

const detailRouter = async (id) => {
  router.push({ path: `${route.pressDetail}/${id}` });
};

const getApi = async () => {
  try {
    const data = {
      size: 10,
      page: 0,
      exposureFlag: true,
      useFlag: true,
    };

    const response = (await PressApi.get(data)).data.data;

    if (response && response.content.length > 0) {
      title.value = response.content[0].title;
      createdAt.value = format(new Date(response.content[0].createdAt), "yyyy-MM-dd");
      cardId.value = response.content[0].id;
      imgUrl.value = response.content[0].imageUrl || '';
    }
    console.log(response);
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <div class="news-wrapper">
    <img :src="imgUrl ? imgUrl : require('@/assets/img/img_empty.png')" alt="" />
    <div class="news-meta" @click="detailRouter(cardId)">
      <PretendardText class="title">{{ title }}</PretendardText>
      <PretendardText class="date">{{ createdAt }}</PretendardText>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.news-wrapper {
  display: flex;
  flex-direction: column;

  & img {
    width: 100%;
    height: 214px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }

  & .news-meta {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 15px;
    background: #f7fafc;
    border-radius: 0 0 10px 10px;

    & .title {
      color: #044a85;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: -0.6px;
    }

    & .date {
      color: #888; /* Assuming $g04 is a color variable */
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.6px;
    }
  }
}
</style>
