<script setup>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { univList } from "@/constants/consortium";
import { defineEmits, ref } from "vue";
import { displayHooks } from "@/hooks/useDisplay";
import { onClickOutside } from "@vueuse/core";
const emit = defineEmits(["closeModal"]);

const target = ref(null);

onClickOutside(target, () => {
  emit("closeModal");
});

let useDisplayRef = ref(displayHooks);

const isOpen = ref(-1);

const changeOpen = (i) => {
  if (isOpen.value === i) {
    isOpen.value = -1;
    return;
  }
  isOpen.value = i;
};
</script>

<template>
  <div class="modal-dim">
    <div class="modal-scroll-container">
      <div ref="target" class="modal-container">
        <div class="flex-row-between top-banner" style="margin-bottom: 15px">
          <PretendardText class="text-24">컨소시엄 리스트</PretendardText>
          <img
            src="../../../assets/ico/x.png"
            style="object-fit: contain; cursor: pointer"
            alt=""
            @click="emit('closeModal')"
          />
        </div>
        <div v-for="(item, index) in univList" :key="index">
          <div class="title-toggle">
            <div class="title-wrapper">
              <div class="circle" />
              <PretendardText class="text-16">{{ item.title }}</PretendardText>
            </div>
            <img
              :class="isOpen === index ? `icon rotateToBottom` : `icon`"
              v-if="useDisplayRef.isMobile"
              src="../../../assets/ico/btn/arrow_down.png"
              alt=""
              @click="changeOpen(index)"
            />
          </div>
          <div
            class="univ-wrapper"
            v-if="!useDisplayRef.isMobile || isOpen === index"
          >
            <div
              v-for="(item2, index2) in item.list"
              :key="index2"
              class="univ-card"
            >
              <a :href="item2.link" target="blank" class="univ-box-a">
                <div class="univ-box">
                  <img
                    :src="require(`@/assets/ico/univ/${item2.url}.png`)"
                    alt=""
                  />
                </div>
                <div class="name-wrapper">
                  <PretendardText class="text-16">{{
                    item2.univ
                  }}</PretendardText>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          class="flex-row-align-center"
          style="width: 100%; margin-top: 15px; justify-content: center"
        >
          <div class="btn" @click="emit('closeModal')">
            <PretendardText class="text-16" style="color: #fff"
              >확인</PretendardText
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-scroll-container {
  width: 100%;
  max-height: 100vh;
  display: flex;
  padding: 64px 0;
  justify-content: center;
  overflow: auto;

  @include mobile {
    padding: 27px 20px;
  }
}

.modal-container {
  width: 100%;
  max-width: 1200px;
  border-radius: 10px;
  background-color: $white;
  padding: 20px;
  padding-right: 0;
  overflow: auto;

  @include mobile {
    overflow: unset;
    padding: 0;
    height: fit-content;
    padding-bottom: 20px;
  }

  .top-banner {
    padding: 20px 24px;
    align-items: center;
  }
}

.modal-container::-webkit-scrollbar {
  width: 20px; /* 스크롤바의 너비 */
}

.modal-container::-webkit-scrollbar-thumb {
  background: $primary4; /* 스크롤바의 색상 */
  border-radius: 999px;
  border: 7px solid #ffffff;
}

.modal-container::-webkit-scrollbar-track {
  border-radius: 999px;
  background: #fff;
}

.text-24 {
  color: $g02;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 14px;
    font-weight: 700;
  }
}

.text-16 {
  color: $g02;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 14px;
  }
}

.title-wrapper {
  padding: 15px 4px;
  display: flex;
  align-items: center;
  gap: 6px;

  @include mobile {
    padding: 0px;
  }

  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $primary4;
  }
}

.title-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    padding: 15px;
  }
}

.btn {
  width: 76px;
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  background: $primary4;
  cursor: pointer;
}

.univ-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @include mobile {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0 10px;
  }

  .univ-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 216px;

    @include mobile {
      flex-basis: calc(50% - 5px);
    }

    .univ-box-a {
      width: 100%;
      cursor: pointer;
    }

    .univ-box {
      width: 100%;
      height: 155.72px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $g08;

      @include mobile {
        height: 120px;
      }

      img {
        max-width: 100%;
      }
    }

    .name-wrapper {
      padding: 10px 0 15px 0;
      display: flex;
      justify-content: center;

      @include mobile {
        padding: 10px 0;
      }
    }
  }
}

.icon {
  transition: all ease 0.2s;

  &.rotateToBottom {
    transform: rotate(180deg);
  }
}
</style>
