<script setup>
import { ref, onBeforeMount } from "vue";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import { route } from "@/router/route";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { displayHooks } from "@/hooks/useDisplay";
import MenuApi from "@/api/menu";
import SharingApi from "@/api/sharing";
import { useToast } from "vue-toastification";
import EmptyTable from "@/components/organisms/emptyTable.vue";
import BtmPagination from "@/components/molcules/pagination/btmPagination.vue";

const toast = useToast();

let useDisplayRef = ref(displayHooks);
const isOpen = ref(0);

let menuValue = ref("");

let last = ref(false);
let first = ref(false);
let page = ref(0);
let maxPage = ref(0);
let step = ref(0);
let total = ref(0);
let listSize = ref(5);
let paginationSize = ref(5);

let mainSectionMenu = ref([]);

const goPrevPage = () => {
  if (!first.value) {
    page.value--;
    getApi();
  } else return;

  if (page.value < paginationSize.value * step.value) {
    step.value--;
  }
};

const goNextPage = () => {
  if (!last.value) {
    page.value++;
    getApi();
  } else return;

  if (page.value + 1 > paginationSize.value * (step.value + 1)) {
    step.value++;
  }
};

const goPage = (index) => {
  page.value = index;
  getApi();
};

onBeforeMount(() => {
  getMenu();
  getApi();
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = (await MenuApi.get(data)).data.data;
    const menuData = response.content.reverse();

    if (response) {
      const subMenu = menuData[3].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`sharing${index + 1}`],
          });
        }

        if (item.homeLink === "/out-come1") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const list = ref([]);

const getApi = async (initial) => {
  try {
    if (initial) {
      total.value = 0;
      page.value = 0;
      step.value = 0;
      paginationSize.value = 5;
    }
    const response = await SharingApi.get({
      size: listSize.value,
      page: page.value,
      useFlag: true,
    });

    if (response.data.data) {
      last.value = response.data?.data?.last;
      first.value = response.data?.data?.first;
      list.value = response.data?.data?.content;
      total.value = response.data.data.totalElements;

      maxPage.value = Math.ceil(total.value / listSize.value);
      if (maxPage.value <= paginationSize.value) {
        paginationSize.value = maxPage.value;
      }
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const changeOpen = (index) => {
  if (isOpen.value === index) {
    isOpen.value = -1;
  } else {
    isOpen.value = index;
  }
};
</script>

<template>
  <SubpageHeader />
  <MainSection
    bgUrl="sharing"
    :options="mainSectionMenu"
    :menuValue="menuValue"
    mainHeader="성과공유"
    subHeader01="Home"
    subHeader02="성과공유"
  />

  <BodyContain
    :style="`padding-bottom: ${useDisplayRef.isMobile ? '60px' : '120px'}`"
  >
    <PretendardText class="intro-title">우수성과</PretendardText>
    <EmptyTable v-if="list.length <= 0" />
    <div
      v-else
      v-for="(item, index) in list"
      :key="index"
      class="accordion-wrapper"
    >
      <div class="title-wrapper" @click="changeOpen(index)">
        <PretendardText
          class="text-16"
          style="font-weight: 500; color: #282828"
          >{{ item.title }}</PretendardText
        >
        <img
          src="../../assets/ico/btn/arrow_down.png"
          alt=""
          :class="isOpen === index ? `icon rotateToBottom` : `icon`"
        />
      </div>
      <div v-if="isOpen === index" class="content-container">
        <div class="content-wrapper">
          <PretendardText class="text-14">
            <div v-html="item.content" />
          </PretendardText>
        </div>
      </div>
    </div>
    <BtmPagination
      :page="page"
      :maxPage="maxPage"
      :step="step"
      :last="last"
      :first="first"
      :total="total"
      :listSize="listSize"
      :paginationSize="paginationSize"
      @goPrevPage="goPrevPage"
      @goNextPage="goNextPage"
      @goPage="goPage"
    />
    <PretendardText class="intro-title" style="margin-top: 80px"
      >우수 운영 현황</PretendardText
    >
    <div class="status-wrapper">
      <PretendardText class="text-14"
        >해당 대시보드는 올 해 목표값 대비 달성값으로
        표현됩니다.</PretendardText
      >
      <div class="status-box-wrapper">
        <div class="box">
          <PretendardText class="text-16">
            융합인재 양성분야 교육과정 개발(건)
          </PretendardText>
          <div class="status-bar-container">
            <div class="status-bar-wrapper">
              <div
                class="status-bar"
                style="
                  height: 50%;
                  background: linear-gradient(
                    171deg,
                    #04b0e2 1.7%,
                    #00c6d2 100%
                  );
                "
              >
                <PretendardText class="value">00건</PretendardText>
              </div>
            </div>
            <div class="status-bar-wrapper">
              <div
                class="status-bar"
                style="
                  height: 100%;
                  background: linear-gradient(
                    171deg,
                    #04b0e2 1.7%,
                    #00c6d2 100%
                  );
                "
              >
                <PretendardText class="value">00건</PretendardText>
              </div>
            </div>
          </div>
          <div class="bottom-wrapper">
            <PretendardText class="text-14" style="#111"
              >교육과정</PretendardText
            >
            <PretendardText class="text-14" style="#111"
              >정규 교과목</PretendardText
            >
          </div>
        </div>
        <div class="box">
          <PretendardText class="text-16">
            융합인재 양성분야 교육과정 운영(건)
          </PretendardText>
          <div class="status-bar-container">
            <div class="status-bar-wrapper">
              <div
                class="status-bar"
                style="
                  height: 50%;
                  background: linear-gradient(
                    170deg,
                    #8f6cf4 1.28%,
                    #04b0e2 100%
                  );
                "
              >
                <PretendardText class="value">00건</PretendardText>
              </div>
            </div>
            <div class="status-bar-wrapper">
              <div
                class="status-bar"
                style="
                  height: 100%;
                  background: linear-gradient(
                    170deg,
                    #8f6cf4 1.28%,
                    #04b0e2 100%
                  );
                "
              >
                <PretendardText class="value">00건</PretendardText>
              </div>
            </div>
          </div>
          <div class="bottom-wrapper">
            <PretendardText class="text-14" style="#111"
              >교육과정</PretendardText
            >
            <PretendardText class="text-14" style="#111"
              >정규 교과목</PretendardText
            >
          </div>
        </div>
        <div class="box">
          <PretendardText class="text-16">
            융합인재 양성분야 정규 교과목 이수자(명)
          </PretendardText>
          <div class="status-bar-container">
            <div class="status-bar-wrapper">
              <div
                class="status-bar"
                style="
                  height: 80%;
                  background: linear-gradient(
                    171deg,
                    #04b0e2 1.7%,
                    #00c6d2 100%
                  );
                "
              >
                <PretendardText class="value">00건</PretendardText>
              </div>
            </div>
          </div>
          <div
            class="bottom-wrapper"
            style="justify-content: center; padding-left: 0"
          >
            <PretendardText class="text-14" style="#111"
              >정규 교과목</PretendardText
            >
          </div>
        </div>
      </div>
    </div>
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.intro-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 50px;

  @include mobile {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.accordion-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid $line;

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    gap: 10px;
    height: 70px;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
  }

  .content-wrapper {
    border-top: 1px solid $line;
    display: flex;
    width: 100%;
    padding: 20px 15px;
    align-items: flex-start;
    gap: 10px;
    background: #f9f9f9;
  }
}

.text-16 {
  color: $black;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
}

.text-14 {
  color: $g03;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.6px;
}

.status-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;

  .status-box-wrapper {
    width: 100%;
    display: flex;
    gap: 23px;

    @include mobile {
      flex-direction: column;
      gap: 20px;
    }

    .box {
      width: 100%;
      background: $g08;
      padding: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .status-bar-container {
    width: 146px;
    display: flex;
    justify-content: center;
    gap: 20px;
    border-bottom: 1px solid #d9d9d9;
    padding: 0 3px;
    margin-top: 21px;
  }

  .status-bar-wrapper {
    width: 60px;
    height: 122px;
    border-radius: 10px 10px 0px 0px;
    background: $g06;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .status-bar {
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      justify-content: center;
      padding-top: 10px;

      .value {
        color: $white;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
      }
    }
  }
}

.bottom-wrapper {
  width: 146px;
  display: flex;
  gap: 26px;
  padding-left: 9px;
  margin-top: 11px;
}

.icon {
  width: 16px;
  height: 16px;
  transition: all ease 0.2s;

  &.rotateToBottom {
    transform: rotate(180deg);
  }
}
</style>
