<script setup>
import { defineProps, ref, defineModel } from "vue";
const model = defineModel();

const props = defineProps({
  type: String,
  style: [String, Object],
  placeholder: String,
  disabled: { type: Boolean, default: false },
});

let input_flag = ref("#e5e8eb");
</script>

<template>
  <div class="conatiner-wrapper" :style="props.style">
    <input
      :type="type ? type : `text`"
      class="input-container"
      :placeholder="props.placeholder"
      v-model="model"
      @focus="input_flag = '#007CFF'"
      @blur="input_flag = '#e5e8eb'"
      :disabled="props.disabled"
    />
  </div>
</template>

<style lang="scss" scoped>
.conatiner-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid v-bind(input_flag);
  border-radius: 6px;
}

.input-container {
  width: 100%;
  border: 0px;
  box-sizing: border-box;
  height: 55px;
  padding: 17.5px 10px;
  font-size: 14px;
  font-weight: 400;
  color: $g01;
  border-radius: 6px;

  &:focus {
    background: #fff;
    outline: none;
    border: 0px;
  }

  :disabled {
    background-color: #f2f4f6;
  }

  &::placeholder {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    color: #717377;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
