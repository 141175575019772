<script setup>
import { onMounted, ref, onBeforeMount } from "vue";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { route } from "@/router/route";
import { displayHooks } from "@/hooks/useDisplay";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
const toast = useToast();

let useDisplayRef = ref(displayHooks);
let menuValue = ref("");

let mainSectionMenu = ref([]);

onBeforeMount(() => {
  getMenu();
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[1].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`curriculum${index + 1}`],
          });
        }

        if (item.homeLink === "/curriculum01") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const item1 = ref(null);
const item1Observe = ref(false);

const item2 = ref(null);
const item2Observe = ref(false);

const item3 = ref(null);
const item3Observe = ref(false);

const observeRef = (ref, refType) => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          refType.value = true;
        }
      });
    },
    {
      threshold: 0, // 50% 이상이 보일 때 콜백 실행
    }
  );

  if (ref.value) {
    observer.observe(ref.value);
  }
};

onMounted(() => {
  observeRef(item1, item1Observe);
  observeRef(item2, item2Observe);
  observeRef(item3, item3Observe);
});
</script>

<template>
  <SubpageHeader />
  <MainSection
    bgUrl="curriculum"
    :options="mainSectionMenu"
    :menuValue="menuValue"
    mainHeader="교육과정"
    subHeader01="Home"
    subHeader02="교육과정"
  />

  <BodyContain
    :style="
      useDisplayRef.isMobile ? `padding: 60px 20px` : `padding-bottom: 141px`
    "
  >
    <PretendardText class="intro-title">교육과정 소개</PretendardText>
    <PretendardText class="text-24" style="margin-bottom: 30px"
      >교육과정, 교육방법, 교육인프라 혁신을 통해 창의적 청년들이 학습하고
      활동하며<br />
      성장하는 융합교육의 장을 구현합니다.</PretendardText
    >
    <div class="curriculum-intro">
      <img
        ref="item1"
        :class="{ 'slide-in': item1Observe }"
        src="../../assets/img/curriculum/curriculum1.png"
        alt=""
      />
      <div class="curr-wrapper">
        <PretendardText class="text-24">
          집중이수제는 무엇일까요?
        </PretendardText>
        <div class="content-wrapper">
          <div class="circle" />
          <PretendardText class="text-16">
            학생들의 학업 부담을 낮추고, 유연한 학습 참여를<br />지원하기 위해
            온라인 수업에 기반을 둔 집중이수제를 도입했습니다.
          </PretendardText>
        </div>
        <div class="content-wrapper">
          <div class="circle" />
          <PretendardText class="text-16">
            <span class="strong"
              >본인이 원하는 시점에 교과목별 기준(학점 15시간 이상)<br />
              범위 내에서 자유롭게 수강</span
            >할 수 있습니다.
          </PretendardText>
        </div>
      </div>
    </div>
    <div class="curriculum-reverse" style="align-items: center">
      <div class="curr-wrapper">
        <PretendardText class="text-24">
          마이크로러닝은 무엇일까요?
        </PretendardText>
        <div class="content-wrapper">
          <div class="circle" />
          <PretendardText class="text-16">
            마이크로러닝은 bite-sized learning으로 불리며,<br />
            짧은 단위의 콘텐츠를 통해 학습 부담은 낮추고 학습 효과는 최대화하기
            위하여 <br />
            짧은 단위의 콘텐츠로 학습하는 형태를 의미합니다.
          </PretendardText>
        </div>
        <div class="content-wrapper">
          <div class="circle" />
          <PretendardText class="text-16">
            취업에 실질적인 도움이 되는 실무 역량을 강화하고 K-컬처 분야 진로
            설계에<br />
            필요한 기초소양을 함양할 수 있는 마이크로러닝형 교과목을
            구성하였습니다.
          </PretendardText>
        </div>
      </div>
      <img
        ref="item2"
        :class="{ 'slide-in': item2Observe }"
        src="../../assets/img/curriculum/curriculum2.png"
        alt=""
      />
    </div>
    <div
      class="curriculum-intro"
      :style="useDisplayRef.isDesktop ? `margin-bottom: 80px` : ``"
    >
      <img
        ref="item3"
        :class="{ 'slide-in': item3Observe }"
        src="../../assets/img/curriculum/curriculum3.png"
        alt=""
      />
      <div class="curr-wrapper">
        <PretendardText class="text-24">
          리빙랩 과정은 무엇일까요?
        </PretendardText>
        <div class="content-wrapper">
          <div class="circle" />
          <PretendardText class="text-16">
            K-컬처 관련 산업체, 지역사회, 글로벌 커뮤니티와 연계한 교과목을<br />
            트랙별로 개발하여 운영합니다.
          </PretendardText>
        </div>
        <div class="content-wrapper">
          <div class="circle" />
          <PretendardText class="text-16">
            학생주도형 문제해결/프로젝트 수행을 기반으로 하는<br />
            참여형 학습과정(PBL/PjBL)에 해당하며 이를 통해 실무감각을 습득할 수
            있습니다.
          </PretendardText>
        </div>
      </div>
    </div>
    <PretendardText class="intro-title flex-col" style="gap: 20px"
      >인재양성 혁신융합대학<PretendardText
        v-if="!useDisplayRef.isMobile"
        class="text-24"
        style="font-weight: 500"
        >HUSS 인문사회융합인재 양성사업<br />
        글로벌·문화 분야<br />
        글로벌 K-컬처 선도 융합인재양성사업단</PretendardText
      ></PretendardText
    >
    <PretendardText
      v-if="useDisplayRef.isMobile"
      class="text-24"
      style="font-weight: 700; margin-bottom: 30px"
      >HUSS 인문사회융합인재 양성사업<br />
      글로벌·문화 분야<br />
      글로벌 K-컬처 선도 융합인재양성사업단</PretendardText
    >
    <div
      :class="!useDisplayRef.isDesktop ? `flex-col` : `flex-row-align-center`"
      style="gap: 30px"
    >
      <div class="fusion-univ-wrapper">
        <img src="../../assets/ico/curriculums/fusion_univ1.png" alt="" />
        <div class="content-wrapper">
          <PretendardText
            class="text-16"
            style="font-weight: 700; color: #282828"
          >
            인문사회기반의 글로벌 K-컬처<br v-if="!useDisplayRef.isTablet" />
            5C 인재 5,000명 양성
          </PretendardText>
          <PretendardText class="text-14">
            교육과정, 교육방법, 교육인프라 혁신을 통해 글로벌안목과<br
              v-if="!useDisplayRef.isTablet"
            />
            창의적 감각을 갖춘 글로벌 K-컬처 융합인재 양성
          </PretendardText>
        </div>
      </div>
      <div class="fusion-univ-wrapper">
        <img src="../../assets/ico/curriculums/fusion_univ2.png" alt="" />
        <div class="content-wrapper">
          <PretendardText
            class="text-16"
            style="font-weight: 700; color: #282828"
          >
            기초소양교육과정에 해당하는 캐치웨이브와<br
              v-if="!useDisplayRef.isTablet"
            />
            심화/융합 교육과정에 해당하는 로그웨이브로 구성된<br />
            &lt;글로벌 K-웨이브&gt; 교육과정 운영
          </PretendardText>
          <PretendardText class="text-14">
            대학별특성화 요소를 살려 전공별/트랙별 글로벌 K-웨이브<br
              v-if="!useDisplayRef.isTablet"
            />
            교육과정을 공동 개발, 운영
          </PretendardText>
        </div>
      </div>
      <div class="fusion-univ-wrapper">
        <img src="../../assets/ico/curriculums/fusion_univ3.png" alt="" />
        <div class="content-wrapper">
          <PretendardText
            class="text-16"
            style="font-weight: 700; color: #282828"
          >
            통합플랫폼 기반 집중이수제 등 유연학기제 시행
          </PretendardText>
          <PretendardText class="text-14">
            집중이수제를 통한 탄력적 학사 운영,<br
              v-if="!useDisplayRef.isTablet"
            />
            통합플랫폼운영을 통한 온라인 이론 및 실습 강의 실시
          </PretendardText>
        </div>
      </div>
    </div>
    <PretendardText
      class="intro-title"
      :style="useDisplayRef.isMobile ? `margin-top: 60px` : `margin-top: 80px`"
      >글로벌 K-컬처 융합학부</PretendardText
    >
    <PretendardText
      class="text-24"
      :style="
        useDisplayRef.isMobile ? `margin-bottom: 20px` : `margin-bottom: 30px`
      "
    >
      4개의 전공 분야와 4개의 트랙 운영
    </PretendardText>
    <div class="major-wrapper" style="">
      <div class="track-wrapper">
        <img src="../../assets/ico/curriculums/track1.png" alt="" style="height: 73px;" />
        <PretendardText class="text-14">
          글로벌 K-컬처 <br v-if="!useDisplayRef.isDesktop" />영화/드라마 전공
        </PretendardText>
      </div>
      <div class="track-wrapper">
        <img src="../../assets/ico/curriculums/track2.png" alt="" style="height: 73px;" />
        <PretendardText class="text-14">
          글로벌 K-컬처 <br v-if="!useDisplayRef.isDesktop" />웹툰/애니메이션 전공
        </PretendardText>
      </div>
      <div class="track-wrapper">
        <img src="../../assets/ico/curriculums/track3.png" alt="" style="height: 73px;" />
        <PretendardText class="text-14">
          글로벌 K-컬처 <br v-if="!useDisplayRef.isDesktop" />음악/공연 전공
        </PretendardText>
      </div>
      <div class="track-wrapper">
        <img src="../../assets/ico/curriculums/track4.png" alt="" style="height: 73px;"/>
        <PretendardText class="text-14">
          글로벌 K-컬처 <br v-if="!useDisplayRef.isDesktop" />문학/웹소설 전공
        </PretendardText>
      </div>
    </div>
    <PretendardText
      class="intro-title"
      :style="useDisplayRef.isMobile ? `margin-top: 60px` : `margin-top: 80px`"
      >글로벌 K-컬처 융합전공 운영</PretendardText
    >
    <div
      class="flex-row-align-center major-wrapper"
      :style="useDisplayRef.isMobile ? `gap: 10px` : `gap: 30px`"
    >
      <div
        class="major-box"
        :style="`background-image: url(${require('../../assets/img/curriculum/major1.png')})`"
      >
        <PretendardText class="content"
          >입학계열 및 학과에 관계없이<br />
          글로벌 K-컬처 융합학부 내
          <br v-if="!useDisplayRef.isDesktop" />융합전공으로 학위 이수
          가능</PretendardText
        >
      </div>
      <div
        class="major-box"
        :style="`background-image: url(${require('../../assets/img/curriculum/major2.png')})`"
      >
        <PretendardText class="content"
          >글로벌 K-컬처 융합학부에서<br />
          교과목 이수 시 다전공 이수학점으로 인정</PretendardText
        >
      </div>
    </div>
    <PretendardText
      class="intro-title"
      :style="useDisplayRef.isMobile ? `margin-top: 60px` : `margin-top: 80px`"
      >글로벌 K-컬처 융합인재 양성을 위한<br />
      &lt;글로벌 K-웨이브 교육과정&gt;</PretendardText
    >
    <div
      :class="useDisplayRef.isMobile ? `flex-col` : `flex-row-align-center`"
      :style="useDisplayRef.isMobile ? `gap: 10px` : `gap: 30px`"
    >
      <div class="wave-wrapper">
        <div class="type-wrapper">기초소양</div>
        <PretendardText class="title">캐치웨이브 (Catch Wave)</PretendardText>
        <div class="flex-col" style="gap: 10px">
          <div class="content-wrapper">
            <PretendardText
              class="text-14"
              style="font-size: 14px !important; color: #111; font-weight: 600"
              >글로벌 K-컬처 101</PretendardText
            >
            <div class="flex-col" style="gap: 5px">
              <PretendardText class="text-14">· 진로설계</PretendardText>
              <PretendardText class="text-14"
                >· 마이크로러닝형 콘텐츠 제공</PretendardText
              >
            </div>
          </div>
          <div class="content-wrapper">
            <PretendardText
              class="text-14"
              style="font-size: 14px !important; color: #111; font-weight: 600"
              >공통교과목</PretendardText
            >
            <div class="flex-col" style="gap: 5px">
              <PretendardText class="text-14">· 기초공통</PretendardText>
              <PretendardText class="text-14"
                >· K-컬처 이해 증진, 사고표현력 향상</PretendardText
              >
            </div>
          </div>
        </div>
      </div>
      <div class="wave-wrapper">
        <div class="type-wrapper">심화융합</div>
        <PretendardText class="title">로그웨이브 (Rogue Wave)</PretendardText>
        <div class="flex-col" style="gap: 10px">
          <div class="content-wrapper">
            <PretendardText
              class="text-14"
              style="font-size: 14px !important; color: #111; font-weight: 600"
              >전공</PretendardText
            >
            <div class="flex-col" style="gap: 5px">
              <PretendardText class="text-14"
                >· 영화/드라마, 웹툰/애니메이션, 음악/공연,
                문학/웹소설</PretendardText
              >
            </div>
          </div>
          <div class="content-wrapper">
            <PretendardText
              class="text-14"
              style="font-size: 14px !important; color: #111; font-weight: 600"
              >세부트랙</PretendardText
            >
            <div class="flex-col" style="gap: 5px">
              <PretendardText class="text-14"
                >· Story-Builder, Creator, Influencer, Valuator</PretendardText
              >
            </div>
          </div>
          <div class="content-wrapper">
            <PretendardText
              class="text-14"
              style="font-size: 14px !important; color: #111; font-weight: 600"
              >수준별 · 단계별 교육</PretendardText
            >
            <div class="flex-col" style="gap: 5px">
              <PretendardText class="text-14"
                >· 초급, 중급, 고급</PretendardText
              >
            </div>
          </div>
          <div class="content-wrapper">
            <PretendardText
              class="text-14"
              style="font-size: 14px !important; color: #111; font-weight: 600"
              >리빙랩 과정</PretendardText
            >
            <div class="flex-col" style="gap: 5px">
              <PretendardText class="text-14"
                >· 초급 리빙랩, 실무 리빙랩</PretendardText
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.intro-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 50px;

  @include mobile {
    padding-bottom: 15px;
    margin-bottom: 30px;
    font-size: 20px;
  }
}

.curriculum-intro {
  gap: 91px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include mobile {
    gap: 15px;
    flex-direction: column;
    margin-bottom: 30px;
  }

  @include tablet {
    gap: 50px;
  }

  img {
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 50%;
    }
  }

  .content-wrapper {
    margin-bottom: 15px;

    @include mobile {
      margin-bottom: 10px;
    }
  }
}

.curriculum-reverse {
  gap: 91px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    gap: 15px;
    flex-direction: column-reverse;
    margin-bottom: 30px;
  }

  @include tablet {
    gap: 50px;
  }

  img {
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 50%;
    }
  }
}

.text-14 {
  color: $g02;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 12px;
  }
}

.text-16 {
  color: $g03;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 14px;
  }
}

.text-24 {
  color: $g02;
  font-size: 24px;
  font-weight: 700;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 20px;
  }
}

.curr-wrapper {
  .text-24 {
    color: #044a85;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .content-wrapper {
    display: flex;
    gap: 5px;

    .circle {
      width: 4px;
      height: 4px;
      background-color: #044a85;
      border-radius: 50%;
      margin-top: 9px;
    }

    .strong {
      color: $black;
      font-weight: 600;
    }
  }
}

.major-wrapper {
  display: flex;
  align-items: center;
  align-items: center;
  gap: 20px;

  @include mobile {
    flex-wrap: wrap;
    gap: 10px;
  }

  .track-wrapper {
    flex-basis: calc(50% - 5px);
  }

  .text-14 {
    font-size: 14px !important;
    text-align: center;
  }
}

.fusion-univ-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
  height: 300px;
  padding: 30px;
  gap: 30px;
  border-radius: 10px;
  background: #f7fafc;

  @include mobile {
    padding: 20px;
    height: fit-content;
    gap: 10px;
  }

  @include tablet {
    max-width: 100%;
    height: fit-content;
  }

  img {
    width: 90px;

    @include mobile {
      width: 40px;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.track-wrapper {
  width: 100%;
  max-width: 285px;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 999px;
  background: #f6f6f6;
}

.major-wrapper {
  justify-content: center;

  .major-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;

    @include mobile {
      height: 200px;
    }

    .content {
      color: $white;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: -0.6px;
    }
  }
}

.wave-wrapper {
  display: flex;
  width: 100%;
  min-height: 458px;
  padding: 15px;
  flex-direction: column;
  border-radius: 10px;
  background: #f7fafc;

  @include mobile {
    padding: 20px;
    min-height: fit-content;
  }

  .type-wrapper {
    width: 71px;
    display: inline-flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: $primary4;
    color: $white;
    font-family: Pretendard;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.6px;
    margin-bottom: 5px;
  }

  .title {
    color: $black;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.6px;
    margin-bottom: 15px;

    @include mobile {
      font-size: 16px;
    }
  }

  .content-wrapper {
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid $line;
    background: #fff;

    .text-14 {
      color: $g03;
    }
  }
}
</style>
