<template>
  <PretendardText :class="['tab-wrapper', { activeTab: active }]">{{
    title
  }}</PretendardText>
</template>
<script>
import PretendardText from "@/components/atoms/text/PretendardText.vue";

export default {
  name: "TabBar",
  components: {
    PretendardText,
  },
  props: {
    title: String,
    active: Boolean,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.tab-wrapper {
  padding: 0px 10px 12px 10px;
  text-align: center;
  color: $g01;
  font-size: 18px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.6px;
  cursor: pointer;

  @include mobile {
    font-size: 14px;
    padding: 0px 10px 15px 10px;
  }
}

.activeTab {
  font-weight: 700;
  color: #044a85;
  box-shadow: 0px 1px 0px 0px #044a85;
}
</style>
