<script setup>
import { defineProps, ref } from "vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";

const props = defineProps({
  list: Array,
  value: String,
  style: [String, Object],
  textStyle: String,
});

const isOpen = ref(false);
</script>

<template>
  <div class="selectbox-wrapper" @click="isOpen = !isOpen">
    <div class="conatiner-wrapper" :style="style">
      <PretendardText class="title" :style="textStyle">{{
        props.value
      }}</PretendardText>
      <img
        :class="isOpen ? `icon rotateToBottom` : `icon`"
        src="../../../assets/ico/btn/arrow_down.png"
        alt=""
      />
    </div>
    <div v-if="isOpen" class="subMenu-wrapper">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="subMenu"
        @click="$emit('setValue', item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.selectbox-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}

.conatiner-wrapper {
  width: 100%;
  max-height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  padding: 17.5px 10px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
}

.title {
  color: $g01;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.6px;
}

.subMenu-wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 10px 0px rgba(40, 40, 40, 0.15);
  border-radius: 6px;
  border: 1px solid #e5e8eb;
  background: #fff;
  border-collapse: collapse;
  position: absolute;
  width: 100%;
  top: 57px;
  z-index: 5;

  .subMenu {
    display: flex;
    height: 55px;
    padding: 0px 10px;
    align-items: center;
    border-bottom: 1px solid #e5e8eb;
    background: #fff;
    color: $g01;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.6px;
    border-radius: 6px;
  }
}

.icon {
  width: 16px;
  height: 16px;
  transition: all ease 0.2s;

  &.rotateToBottom {
    transform: rotate(180deg);
  }
}
</style>
