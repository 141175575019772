<template>
  <div class="item-wrapper">
<!--    <div class="box" style="width: 350px; margin-right: 54px">-->
<!--      <SummaryTitle title="사업소개" :link="route.intro1" />-->
<!--      <div class="youtube-wrapper">-->
<!--        <iframe-->
<!--          width="100%"-->
<!--          height="188px"-->
<!--          src="https://www.youtube.com/embed/jKRS8wQ5-4c?si=M2NEHMcOvzfquqxS"-->
<!--          title="YouTube video player"-->
<!--          frameborder="0"-->
<!--          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"-->
<!--          allowfullscreen-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->

    <div class="box" style="width: 396px; margin-right: 54px">
      <SummaryTitle title="비전 및 목표" :link="route.intro2" />
      <div class="vision-wrapper">
        <VisionBox
          title="VISION"
          content="<span
          >글로벌 안목으로 지역과 세계를 연결하고,<br />창의적 감각으로 문화와 산업을 매개하는,<br />글로벌
          K-컬처 융합인재 양성</span>"
          src="vision.png"
        />
      </div>
      <div class="vision-wrapper">
        <VisionBox
          title="목표"
          content="<span
          >지역 소멸의 위기를 지역 상생의 기회로 전환<br/>인문적 통찰과 예술적 표현의 융합을 통한 가치 창출<br/>인문사회계열 학생들의 진로 개척 지원</span>"
          src="goal.png"
        />
      </div>
    </div>

    <div class="box" style="width: 350px">
      <SummaryTitle title="초연결형 We러닝" :link="route.intro4" />
      <div style="width: 100%; height: 100%">
        <img src="../../../assets/img/header/running.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import SummaryTitle from "@/components/molcules/header/SummaryTitle.vue";
import VisionBox from "@/components/molcules/header/VisionBox.vue";
import { route } from "@/router/route";

export default {
  name: "SummaryBox1",
  components: { SummaryTitle, VisionBox },
  data() {
    return {
      route,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.youtube-wrapper iframe {
  border-radius: 10px;
}

.vision-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
