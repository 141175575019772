<script setup>
import { ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { route } from "@/router/route";
import { format } from "date-fns";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import MainSection from "@/components/templates/mainSection.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import SearchInput from "@/components/molcules/input/searchInput.vue";
import BtmPagination from "@/components/molcules/pagination/btmPagination.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import PressApi from "@/api/press";

import { displayHooks } from "@/hooks/useDisplay";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
import EmptyTable from "@/components/organisms/emptyTable.vue";
const toast = useToast();

const router = useRouter();
let useDisplayRef = ref(displayHooks);
let menuValue = ref("");

let last = ref(false);
let first = ref(false);
let page = ref(0);
let maxPage = ref(0);
let step = ref(0);
let total = ref(0);
let listSize = ref(10);
let paginationSize = ref(5);

let keyword = ref("");
let mainSectionMenu = ref([]);

const goPrevPage = () => {
  if (!first.value) {
    page.value--;
    getApi();
  } else return;

  if (page.value < paginationSize.value * step.value) {
    step.value--;
  }
};

const goNextPage = () => {
  if (!last.value) {
    page.value++;
    getApi();
  } else return;

  if (page.value + 1 > paginationSize.value * (step.value + 1)) {
    step.value++;
  }
};

const goPage = (index) => {
  page.value = index;
  getApi();
};

onBeforeMount(() => {
  getMenu();
  getApi(true);
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[4].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`plaza${index + 1}`],
          });
        }

        if (item.homeLink === "/square2") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const detailRouter = async (id) => {
  router.push({ path: `${route.pressDetail}/${id}` });
};

const list = ref([]);

const getApi = async (initial) => {
  try {
    if (initial) {
      total.value = 0;
      page.value = 0;
      step.value = 0;
      paginationSize.value = 5;
    }
    const response = await PressApi.get({
      size: listSize.value,
      page: page.value,
      keyword: keyword.value,
      useFlag: true,
    });

    if (response.data.data) {
      last.value = response.data?.data?.last;
      first.value = response.data?.data?.first;
      list.value = response.data?.data?.content;
      total.value = response.data.data.totalElements;

      maxPage.value = Math.ceil(total.value / listSize.value);
      if (maxPage.value <= paginationSize.value) {
        paginationSize.value = maxPage.value;
      }
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <SubpageHeader />

  <MainSection
    bgUrl="plaza"
    :options="mainSectionMenu"
    :menuValue="menuValue"
    mainHeader="정보광장"
    subHeader01="Home"
    subHeader02="정보광장"
  />

  <BodyContain>
    <div
      :class="useDisplayRef.isMobile ? 'flex-col' : 'flex-row-between'"
      :style="
        useDisplayRef.isMobile ? 'margin-bottom: 30px' : 'margin-bottom: 50px'
      "
    >
      <PretendardText class="header-title">보도자료</PretendardText>
      <SearchInput
        v-model="keyword"
        placeholder="검색어를 입력하세요."
        @click="getApi(true)"
      />
    </div>

    <div class="table-header">
      <div class="table-header-title" style="justify-content: center">
        <PretendardText class="title">제목</PretendardText>
      </div>
      <div
        v-if="!useDisplayRef.isMobile"
        class="table-header-date"
        style="justify-content: center; margin-right: 10px"
      >
        <PretendardText class="title">원문보기</PretendardText>
      </div>
      <div
        class="table-header-date"
        :style="
          useDisplayRef.isMobile
            ? 'justify-content: center'
            : 'justify-content: center; margin-right: 10px'
        "
      >
        <PretendardText class="title">발행처</PretendardText>
      </div>
      <div
        v-if="!useDisplayRef.isMobile"
        class="table-header-date"
        style="justify-content: center"
      >
        <PretendardText class="title">등록일</PretendardText>
      </div>
    </div>

    <EmptyTable v-if="list.length <= 0" />
    <div v-else v-for="(item, index) in list" :key="index" class="table-item">
      <div class="table-item-title" @click="detailRouter(item.id)">
        <PretendardText class="title">{{ item.title }}</PretendardText>
      </div>
      <div v-if="useDisplayRef.isMobile" class="table-item-date">
        <div class="link-wrapper">
          <PretendardText class="link-title">원문보기</PretendardText>
          <img src="@/assets/ico/plaza/link.svg" />
        </div>
        <PretendardText class="title">머니투데이원데이</PretendardText>
      </div>

      <div
        class="table-item-date"
        style="justify-content: center; margin-right: 10px"
        v-if="!useDisplayRef.isMobile"
      >
        <a :href="item.originalURL" target="blank">
          <div class="link-wrapper">
            <PretendardText class="link-title">원문보기</PretendardText>
            <img src="@/assets/ico/plaza/link.svg" />
          </div>
        </a>
      </div>
      <div
        @click="detailRouter(item.id)"
        class="table-item-date"
        style="justify-content: center; margin-right: 10px"
        v-if="!useDisplayRef.isMobile"
      >
        <PretendardText class="title">{{ item.pressName }}</PretendardText>
      </div>
      <div
        @click="detailRouter(item.id)"
        class="table-item-date"
        style="justify-content: center"
        v-if="!useDisplayRef.isMobile"
      >
        <PretendardText class="title">{{
          format(new Date(item.createdAt), "yyyy-MM-dd")
        }}</PretendardText>
      </div>
    </div>

    <BtmPagination
      :page="page"
      :maxPage="maxPage"
      :step="step"
      :last="last"
      :first="first"
      :total="total"
      :listSize="listSize"
      :paginationSize="paginationSize"
      @goPrevPage="goPrevPage"
      @goNextPage="goNextPage"
      @goPage="goPage"
    />
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.header-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  color: #111111;
}

.table-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  height: 60px;
  padding: 0px 15px;
  box-sizing: border-box;

  .table-header-title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > .title {
      color: #282828;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
      letter-spacing: -0.6px;
    }
  }

  .table-header-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 150px;

    & > .title {
      color: #282828;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
      letter-spacing: -0.6px;
    }
  }
}

.table-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  height: 60px;
  padding: 0px 15px;
  cursor: pointer;
  box-sizing: border-box;

  .table-item-title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > .notice {
      width: 40px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background-color: #044a85;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      letter-spacing: -0.6px;
      margin-right: 10px;
    }

    & > .title {
      color: #282828;
      text-align: left;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
      letter-spacing: -0.6px;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .table-item-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 150px;

    .link-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 8px;
      background-color: #f2f4f6;
      border-radius: 6px;
      cursor: pointer;

      & > .link-title {
        color: #111111;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        letter-spacing: -0.6px;
        margin-right: 4px;
      }
    }

    & > .title {
      color: #717377;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
      letter-spacing: -0.6px;
    }
  }
}

@include mobile {
  .header-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #111111;
    margin-bottom: 15px;
  }

  .table-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    height: 60px;
    padding: 0px 10px;
    box-sizing: border-box;

    .table-header-title {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;

      & > .title {
        color: #282828;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        letter-spacing: -0.6px;
      }
    }

    .table-header-date {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 85px;

      & > .title {
        color: #282828;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        letter-spacing: -0.6px;
      }
    }
  }

  .table-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    height: 70px;
    padding: 0px 10px;
    cursor: pointer;
    box-sizing: border-box;

    .table-item-title {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      & > .notice {
        min-width: 40px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background-color: #044a85;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        letter-spacing: -0.6px;
        margin-right: 10px;
      }

      & > .title {
        color: #282828;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        letter-spacing: -0.6px;
        margin-right: 10px;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .table-item-date {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 85px;

      .link-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px;
        background-color: #f2f4f6;
        border-radius: 6px;
        cursor: pointer;

        & > .link-title {
          color: #111111;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 16.8px;
          letter-spacing: -0.6px;
          margin-right: 4px;
        }
      }

      & > .title {
        color: #717377;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
        letter-spacing: -0.6px;
        margin-top: 10px;
      }
    }
  }
}
</style>
