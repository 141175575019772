<script setup>
import MainHeader from "@/components/organisms/header/MainHeader.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import KUniverseCard from "@/components/organisms/main/KUniverseCard.vue";
import TrackTypeBox from "@/components/organisms/main/TrackTypeBox.vue";
import OutLineBtn from "@/components/atoms/button/outLineBtn.vue";
import TabBar from "@/components/organisms/main/TabBar.vue";
import AccordianItem from "@/components/organisms/main/Accordian.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import NoticeBox from "@/components/organisms/main/noticeBox.vue";
import NewsBox from "@/components/organisms/main/newsBox.vue";
import QuickBtn from "@/components/organisms/main/quickBtn.vue";
import InfraModal from "@/components/templates/modal/infraModal.vue";
import { displayHooks } from "@/hooks/useDisplay";
import { ref, onMounted, onBeforeMount, watch } from "vue";
import { router } from "@/router";
import { route } from "@/router/route";
import FaqApi from "@/api/faq";
import InfraApi from "@/api/infra";
import { useToast } from "vue-toastification";
import MenuApi from "@/api/menu";
import CooperationApi from "@/api/cooperation";
import { register } from "swiper/element/bundle";

register();

const swiper = ref(null);
const toast = useToast();

let useDisplayRef = ref(displayHooks);

let tabState = ref(1);
const info = ref(null);
const about = ref(null);
const career = ref(null);
const curriculum = ref(null);
const infra = ref(null);
const faq = ref(null);
const activeRef = ref("info");
const quickOpen = ref(false);

const infroModalNum = ref(-1);

const closeModal = () => {
  infroModalNum.value = -1;
};

const observeRef = (ref, refType) => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          activeRef.value = refType;
        }
      });
    },
    {
      threshold: 0.95,
    }
  );

  if (ref.value) {
    observer.observe(ref.value);
  }
};

onMounted(() => {
  observeRef(info, "info");
  observeRef(about, "about");
  observeRef(career, "career");
  observeRef(curriculum, "curriculum");
  observeRef(infra, "infra");
  observeRef(faq, "faq");
});

const scrollToRef = (ref) => {
  if (ref === "info") info.value?.scrollIntoView({ behavior: "smooth" });
  if (ref === "about") about.value?.scrollIntoView({ behavior: "smooth" });
  if (ref === "career") career.value?.scrollIntoView({ behavior: "smooth" });
  if (ref === "curriculum")
    curriculum.value?.scrollIntoView({ behavior: "smooth" });
  if (ref === "infra") infra.value?.scrollIntoView({ behavior: "smooth" });
  if (ref === "faq") faq.value?.scrollIntoView({ behavior: "smooth" });
};

const changeTab = (num) => {
  tabState.value = num;
};

const faqRouter = () => {
  router.push({ path: `${route.plaza4}` });
};

const list = ref([]);

onBeforeMount(() => {
  getApi();
  getQuickMenu();
  getInfra();
  getCooperation(true);
});

const getApi = async () => {
  try {
    const response = await FaqApi.get({
      size: 3,
      page: 0,
      faqCategory: `FAQ${tabState.value}`,
    });
    list.value = response.data?.data?.content;
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const quickMenu = ref([]);

const getQuickMenu = async () => {
  try {
    const response = (await MenuApi.getQuick()).data.data.content;

    quickMenu.value = response;
  } catch (error) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const infraList = ref([]);

const getInfra = async () => {
  try {
    const response = (await InfraApi.get({ useFlag: true })).data.data.content;
    infraList.value = response;
  } catch (error) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const coInfo = ref({});

const getCooperation = async () => {
  try {
    const response = await CooperationApi.get({ useFlag: true });

    if (response.data.data) {
      coInfo.value = response.data?.data?.content;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

watch(tabState, () => {
  getApi();
});
</script>

<template>
  <MainHeader />
  <div class="home-section1">
    <div :style="useDisplayRef.isDesktop ? `width: 1200px` : `width: 100%`">
      <PretendardText class="home-title"
        >글로벌 역량, 창의적감각,<br />콘텐츠 기술력을 갖춘<br />K-컬처 융합인재
        양성</PretendardText
      >
    </div>
    <img
      alt=""
      src="../assets/ico/home/people1.png"
      class="people1 up-and-down"
    />
    <img
      alt=""
      src="../assets/ico/home/people2.png"
      class="people2 up-and-down2"
    />
    <img
      alt=""
      src="../assets/ico/home/people3.png"
      class="people3 up-and-down3"
    />
    <img
      alt=""
      src="../assets/ico/home/people4.png"
      class="people4 up-and-down4"
    />
    <img alt="" src="../assets/ico/home/k.png" class="k" />
    <img
      v-if="useDisplayRef.isMobile"
      alt=""
      src="../assets/ico/home/m_mask.png"
      class="m-mask"
    />
    <div class="gradient-wrapper" />
  </div>
  <div class="home-section2" ref="info">
    <div class="item-wrapper">
      <NewsBox />
      <NoticeBox />
    </div>
  </div>
  <div class="home-section3" ref="about">
    <div class="item-wrapper">
      <PretendardText class="title"
        >Creative U를 위한 K-Universe</PretendardText
      >
      <PretendardText class="caption"
        >글로벌 안목으로 지역과 세계를 연결하고,
        <br v-if="useDisplayRef.isMobile" />창의적 감각으로 문화와 산업을
        매개하는,<br />글로벌 K-컬처 융합인재를 양성합니다.</PretendardText
      >
      <div class="kuniverse-card-wrapper">
        <KUniverseCard
          class="card card1"
          :title="
            useDisplayRef.isMobile
              ? `<span>지역 소멸의 위기를<br/>지역 상생의 기회로 전환</span>`
              : `<span>지역 소멸의 위기를 지역 상생의 기회로 전환</span>`
          "
          :content="
            useDisplayRef.isMobile
              ? `지역에 기반을 두고 <br/>세계 무대에서 활동하는<br/>K-컬처 융합인재를 육성하여 <br/>지역 소멸의 위기를<br/>극복하고 대학·지역·산업이 <br/>상생하는 균형 발전 실현에 기여`
              : `지역에 기반을 두고 세계 무대에서 활동하는<br/>K-컬처 융합인재를 육성하여 지역 소멸의 위기를<br/>극복하고 대학·지역·산업이 상생하는 균형 발전 실현에 기여`
          "
        />
        <KUniverseCard
          class="card card2"
          :title="
            useDisplayRef.isMobile
              ? `<span>인문적 통찰과 예술적 표현의<br/>융합을 통한 가치 창출</span>`
              : `<span>인문적 통찰과 예술적 표현의 융합을 통한 가치 창출</span>`
          "
          :content="
            useDisplayRef.isMobile
              ? `창의력, 소통력, 문제해결·분석력,<br/>콘텐츠 제작·기술력, 융합력을<br/>고루 갖춘 인재를 양성하여<br/>K-컬처를 선도하는 새로운 가치 창출`
              : `창의력, 소통력, 문제해결·분석력, 콘텐츠 제작·기술력, 융합력을<br/>고루 갖춘 인재를 양성하여 K-컬처를 선도하는 새로운 가치 창출`
          "
        />
        <KUniverseCard
          style="color: #191919"
          class="card card3"
          :title="
            useDisplayRef.isMobile
              ? `<span>인문사회계열 학생들의<br/>진로 개척 지원</span>`
              : `<span>인문사회계열 학생들의 진로 개척 지원</span>`
          "
          :content="
            useDisplayRef.isMobile
              ? `글로벌 한국을 통해 다양하게<br/>진로를 모색하고, 창의적 감각으로<br/>실무 역량을 습득하여, 글로벌 K-컬처<br/>전문가로 성장하도록 지원`
              : `글로벌 한국을 통해 다양하게 진로를 모색하고, 창의적 감각으로<br/>실무 역량을 습득하여, 글로벌 K-컬처 전문가로 성장하도록 지원`
          "
        />
      </div>
      <div class="kuniverse-box">
        <PretendardText class="title"
          >글로벌 K-컬처 선도 융합인재양성 컨소시엄</PretendardText
        >
        <div class="universe-wrapper">
          <div v-for="(item, index) in coInfo" :key="index" class="univ-box">
            <img alt="" :src="item.imageUrl" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="home-section4" ref="career">
    <div class="item-wrapper">
      <PretendardText class="title"
        >K-컬처 선도에 기여할<br v-if="useDisplayRef.isMobile" />진로 유형별
        트랙
      </PretendardText>
      <PretendardText
        class="caption"
        :style="useDisplayRef.isMobile ? `margin-bottom: 30px` : ``"
        >K-컬처 산업 발전에 필요한 역량별 혁신가
        <br v-if="useDisplayRef.isMobile" />그룹 양성을 위한 트랙별
        교육과정입니다.</PretendardText
      >
      <div class="track-wrapper">
        <img
          v-if="!useDisplayRef.isMobile"
          alt=""
          src="../../src/assets/img/main/track.png"
        />
        <img
          v-if="useDisplayRef.isMobile"
          alt=""
          src="../../src/assets/img/main/m_track.png"
        />
        <div class="track-info">
          <div class="track-title-wrapper" style="margin-bottom: 5px">
            <div class="line" />
            <PretendardText class="track-title">진로유형</PretendardText>
            <div class="line" />
          </div>
          <TrackTypeBox
            backgroundColor="#044A85"
            content="K-컬처의 원형을 찾는"
            title="Story-Builder"
            :isBlack="false"
            img="1"
          />
          <TrackTypeBox
            backgroundColor="#54966E"
            content="K-컬처에 생명력을 더하는"
            title="Creator"
            :isBlack="false"
            img="2"
          />
          <TrackTypeBox
            backgroundColor="#F6E382"
            content="K-컬처를 더 많은 사람들에게 전하는"
            title="Influencer"
            isBlack
            img="3"
          />
          <TrackTypeBox
            backgroundColor="#DDD"
            content="K-컬처에 날개를 달아주는"
            title="Valuator"
            isBlack
            img="4"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="home-section5" ref="curriculum">
    <div class="item-wrapper">
      <PretendardText class="title">K-웨이브 교육과정</PretendardText>
      <PretendardText class="caption"
        >글로벌 K-컬처 분야 융합인재 양성을 위한
        <br v-if="useDisplayRef.isMobile" />글로벌 K-웨이브 교육과정입니다.<br />캐치웨이브(기초소양
        교육과정)와 <br v-if="useDisplayRef.isMobile" />로그웨이브(심화/융합
        교육과정) 교육과정이 <br v-if="useDisplayRef.isMobile" />준비되어
        있습니다.
      </PretendardText>
      <div class="info-box-wrapper">
        <div class="info-box">
          <PretendardText class="title">캐치웨이브</PretendardText>
          <PretendardText class="content"
            >K-컬처 분야 전문 인재에게<br />요구되는 기초소양을 함양하는
            교육과정입니다.</PretendardText
          >
          <img class="img1" src="../../src/assets/img/main/wave1.png" alt="" />
        </div>
        <div class="info-box">
          <PretendardText class="title">로그웨이브</PretendardText>
          <PretendardText class="content"
            >4가지 전공(영화/드라마, 웹툰/애니메이션, 음악/공연,
            <br v-if="useDisplayRef.isMobile" />문학/웹소설)을 포함하는<br />K-컬처
            전문지식을 함양하는 <br v-if="useDisplayRef.isMobile" />심화/융합
            교육과정 및 전공별 세분화 트랙<br />(스토리빌더, 크리에이터,
            인플루언서, 밸류에이터) <br v-if="useDisplayRef.isMobile" />모듈화
            교육과정입니다.</PretendardText
          >
          <img
            class="img2"
            v-if="!useDisplayRef.isMobile"
            src="../../src/assets/img/main/wave2.png"
            alt=""
          />
          <img
            class="img2"
            v-if="useDisplayRef.isMobile"
            src="../../src/assets/img/main/m_wave2.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
  <div class="home-section6">
    <div class="item-wrapper">
      <div class="infra" ref="infra">
        <PretendardText class="title"
          >특성화 인프라 및 공동 활용</PretendardText
        >
        <PretendardText class="caption"
          >참여대학 간 인프라 공유를 통해 교육 효과를 상승시킵니다.<br />공동
          인프라 활용을 통해 K-컬처 융합인재
          <br v-if="useDisplayRef.isMobile" />교육사업의 성과를 확산하고
          공유합니다.
        </PretendardText>
        <div v-if="infraList.length <= 5" class="infra-container">
          <div
            v-for="(item, index) in infraList"
            :key="index"
            class="infra-wrapper flex-col-center"
            @click="infroModalNum = item.id"
          >
            <div class="title-box">
              <PretendardText class="text-16">{{
                item.university
              }}</PretendardText>
              <PretendardText class="text-14">{{ item.space }}</PretendardText>
            </div>
            <img
              :src="
                item.imageUrl
                  ? item.imageUrl
                  : require(`../assets/img/img_empty.png`)
              "
              :style="item.id === 1 ? { 'object-position': 'right' } : {}"
              alt=""
            />
          </div>
        </div>
        <div v-if="infraList.length > 5" class="infra-container">
          <swiper-container
            ref="swiper"
            :slides-per-view="1"
            :loop="true"
            :centered-slides="true"
            @swiperslidechange="onSlideChange"
            :navigation="{
              nextEl: '.infra-swiper-button-next',
              prevEl: '.infra-swiper-button-prev',
            }"
            :breakpoints="{
              768: {
                slidesPerView: 5,
              },
            }"
            style="width: 100%"
          >
            <swiper-slide
              v-for="(item, index) in infraList"
              :key="index"
              class="swiper-slide"
            >
              <div class="infra-wrapper flex-col-center">
                <div class="title-box">
                  <PretendardText class="text-16">{{
                    item.university
                  }}</PretendardText>
                  <PretendardText class="text-14">{{
                    item.space
                  }}</PretendardText>
                </div>
                <img
                  :src="
                    item.imageUrl
                      ? item.imageUrl
                      : require(`../assets/img/img_empty.png`)
                  "
                  :style="item.id === 1 ? { 'object-position': 'right' } : {}"
                  alt=""
                /></div
            ></swiper-slide>
          </swiper-container>
          <img
            class="infra-swiper-button infra-swiper-button-prev"
            src="../assets/ico/intro/arrow_left.png"
            alt=""
          />
          <img
            class="infra-swiper-button infra-swiper-button-next"
            src="../assets/ico/intro/arrow_right.png"
            alt=""
          />
        </div>
      </div>
      <div class="faq-box" ref="faq">
        <img
          v-if="!useDisplayRef.isMobile"
          src="../../src/assets/img/main/faq_bg.png"
          alt=""
        />
        <img
          v-if="useDisplayRef.isMobile"
          src="../../src/assets/img/main/m_faq_bg.png"
          alt=""
        />
        <PretendardText class="title">FAQ</PretendardText>
        <PretendardText class="caption"
          >궁금한 내용은 자주 묻는 질문을 통해 확인 가능합니다.
        </PretendardText>
        <OutLineBtn
          style="z-index: 2"
          :isWhite="true"
          btnText="문의하기"
          @click="faqRouter"
        />
      </div>
      <PretendardText
        class="title"
        :style="
          useDisplayRef.isMobile ? `margin-bottom: 30px` : `margin-bottom: 50px`
        "
        >글로벌 K-컬처에 대한 <br v-if="useDisplayRef.isMobile" />모든것을
        확인해보세요.</PretendardText
      >
      <div class="tab-container">
        <TabBar
          :active="tabState === 1"
          @click="changeTab(1)"
          title="혁신융합대학"
        />
        <TabBar
          :active="tabState === 2"
          @click="changeTab(2)"
          title="K-컬처 전공 안내"
        />
        <TabBar
          :active="tabState === 3"
          @click="changeTab(3)"
          title="수강신청/학점제한"
        />
      </div>
      <div class="accordian-wrapper">
        <AccordianItem
          v-for="(item, index) in list"
          :key="index"
          :title="item.title"
          :content="item.content"
        />
      </div>
    </div>
  </div>
  <MainFooter />
  <div v-if="useDisplayRef.isDesktop" class="side-bar">
    <div class="side-menu">
      <div class="menu-wrapper" @click="scrollToRef(`info`)">
        <div class="circle-wrapper">
          <img
            v-if="activeRef === `info`"
            src="../assets/ico/home/active_section.png"
          />
          <div v-else class="circle" />
        </div>
        <PretendardText
          :class="[`menu`, { 'menu-active': activeRef === 'info' }]"
          >INFORMATION</PretendardText
        >
      </div>
      <div class="menu-wrapper" @click="scrollToRef(`about`)">
        <div class="circle-wrapper">
          <img
            v-if="activeRef === `about`"
            src="../assets/ico/home/active_section.png"
          />
          <div v-else class="circle" />
        </div>
        <PretendardText
          :class="[`menu`, { 'menu-active': activeRef === 'about' }]"
          >ABOUT HUSS</PretendardText
        >
      </div>
      <div class="menu-wrapper" @click="scrollToRef(`career`)">
        <div class="circle-wrapper">
          <img
            v-if="activeRef === `career`"
            src="../assets/ico/home/active_section.png"
          />
          <div v-else class="circle" />
        </div>
        <PretendardText
          :class="[`menu`, { 'menu-active': activeRef === 'career' }]"
          >CAREER</PretendardText
        >
      </div>
      <div class="menu-wrapper" @click="scrollToRef(`curriculum`)">
        <div class="circle-wrapper">
          <img
            v-if="activeRef === `curriculum`"
            src="../assets/ico/home/active_section.png"
          />
          <div v-else class="circle" />
        </div>
        <PretendardText
          :class="[`menu`, { 'menu-active': activeRef === 'curriculum' }]"
          >CURRICULUM</PretendardText
        >
      </div>
      <div class="menu-wrapper" @click="scrollToRef(`infra`)">
        <div class="circle-wrapper">
          <img
            v-if="activeRef === `infra`"
            src="../assets/ico/home/active_section.png"
          />
          <div v-else class="circle" />
        </div>
        <PretendardText
          :class="[`menu`, { 'menu-active': activeRef === 'infra' }]"
          >INFRA</PretendardText
        >
      </div>
      <div class="menu-wrapper" @click="scrollToRef(`faq`)">
        <div class="circle-wrapper">
          <img
            v-if="activeRef === `faq`"
            src="../assets/ico/home/active_section.png"
          />
          <div v-else class="circle" />
        </div>
        <PretendardText
          :class="[`menu`, { 'menu-active': activeRef === 'faq' }]"
          >FAQ</PretendardText
        >
      </div>
    </div>
    <div class="quick-btn-wrapper">
      <div
        v-if="quickOpen === false"
        class="quick-btn"
        @click="quickOpen = true"
      >
        바로가기<img src="../assets/ico/home/quick_arrow.png" alt="" />
      </div>
      <div class="quick-x-wrapper" v-if="quickOpen">
        <div class="quick-x" @click="quickOpen = false">
          <img src="../assets/ico/home/quick_x.png" alt="" />
        </div>
        <div class="quick-menu-wrapper">
          <QuickBtn
            v-for="(item, index) in quickMenu"
            :key="index"
            :title="item.title"
            :link="item.link"
          />
        </div>
      </div>
    </div>
  </div>
  <InfraModal
    v-if="infroModalNum !== -1"
    :infraModalNum="infroModalNum"
    @closeModal="closeModal"
  />
</template>

<style lang="scss" scoped>
.home-section1 {
  width: 100%;
  height: 570px;
  background-image: url("../assets/ico/home/bg.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding-top: 198px;
  position: relative;

  @include small {
    padding: 0 20px;
    padding-top: 33px;
    background-position: bottom -80px right -70px;
  }

  @include tablet {
    padding: 0 40px;
  }

  & .home-title {
    color: $g01;
    font-size: 45px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.6px;

    @include mobile {
      font-size: 28px;
    }
  }
  & .people1 {
    position: absolute;
    top: 30px;
    right: 538px;
    width: 346px;
    height: 300px;

    @include mobile {
      width: 187px;
      height: 163px;
      top: 223px;
      right: unset;
      left: 20px;
    }
    @include tablet {
      width: 187px;
      height: 163px;
      top: 223px;
      right: unset;
      left: 120px;
    }
  }
  & .people2 {
    position: absolute;
    top: 18px;
    right: 176px;
    width: 280px;
    height: 290px;

    @include mobile {
      width: 147px;
      height: 152px;
      top: 202px;
      right: 16px;
    }
    @include tablet {
      width: 147px;
      height: 152px;
      top: 202px;
      right: 160px;
    }
  }
  & .people3 {
    position: absolute;
    bottom: 22px;
    right: 411px;
    width: 282px;
    height: 248px;

    @include mobile {
      width: 149px;
      height: 131px;
      bottom: 33px;
      right: unset;
      left: 53px;
    }

    @include tablet {
      width: 149px;
      height: 131px;
      bottom: 33px;
      right: unset;
      left: 153px;
    }
  }

  & .people4 {
    position: absolute;
    bottom: 17px;
    right: 61px;
    width: 271px;
    height: 333px;

    @include mobile {
      width: 148px;
      height: 182px;
      bottom: 24px;
      right: 34px;
    }

    @include tablet {
      width: 148px;
      height: 182px;
      bottom: 24px;
      right: 134px;
    }
  }

  & .k {
    position: absolute;
    bottom: 75px;
    right: 647px;
    width: 99px;
    height: 107px;

    @include mobile {
      width: 33px;
      height: 36px;
      bottom: 91px;
      right: unset;
      left: 34px;
    }

    @include tablet {
      width: 33px;
      height: 36px;
      bottom: 91px;
      right: unset;
      left: 134px;
    }
  }

  & .m-mask {
    @include mobile {
      position: absolute;
      top: -50px;
      left: 34px;
    }
  }

  & .gradient-wrapper {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 919px;
    height: 151px;
    background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 37.42%);

    @include mobile {
      width: 100%;
      height: 100px;
    }
  }
}
.home-section2 {
  width: 100%;
  height: 421px;
  display: flex;
  justify-content: center;

  @include small {
    height: unset;
  }

  .item-wrapper {
    gap: 20px;

    @include small {
      width: 100%;
      flex-direction: column;
      padding: 0 20px;
      margin-top: 60px;
      gap: 30px;
    }

    @include tablet {
      padding: 0 40px;
    }
  }
}
.home-section3 {
  background-image: url("../assets/ico/home/bg2.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 63px 0 115px 0;
  display: flex;
  justify-content: center;

  @include mobile {
    padding: 60px 20px;
  }
  @include tablet {
    padding: 60px 40px;
  }

  & > .item-wrapper {
    flex-direction: column;
    align-items: center;
  }
}
.kuniverse-card-wrapper {
  width: 100%;
  display: flex;
  gap: 15px;
  margin-bottom: 40px;

  @include small {
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: 10px;
    margin-bottom: 30px;
  }

  .card {
    @include small {
      flex: 0 0 auto;
    }
  }

  & > .card1 {
    background-image: url("../../src/assets/img/main/kuniverse1.png");
    @include small {
      background-size: cover;
      background-position: bottom right;
    }
  }
  & > .card2 {
    background-image: url("../../src/assets/img/main/kuniverse2.png");
    @include small {
      background-size: cover;
      background-position: bottom right;
    }
  }
  & > .card3 {
    background-image: url("../../src/assets/img/main/kuniverse3.png");
    @include small {
      background-size: cover;
      background-position: bottom right;
    }
  }
}
.kuniverse-card-wrapper::-webkit-scrollbar {
  display: none;
}

.kuniverse-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 25px;
  width: 100%;
  gap: 15px;
  border-radius: 10px;
  background: #f7fafc;
  box-shadow: 0px 7px 13px 0px rgba(0, 0, 0, 0.15);

  & > .title {
    color: #222;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.6px;

    @include mobile {
      font-size: 16px;
    }
  }

  & > .universe-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    & > .univ-box {
      flex-basis: calc(20% - 10px);
      height: 55px;
      max-height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;

      img {
        object-fit: contain;
        max-height: 100%;
      }

      @include mobile {
        flex-basis: unset !important;
        width: 100%;
      }
    }

    @include mobile {
      flex-direction: column;
      flex-wrap: unset;
      width: 100%;
    }
  }
}

.home-section4 {
  background: #f7fafc;
  padding: 80px 0;
  display: flex;
  justify-content: center;

  @include mobile {
    padding: 60px 20px;
  }

  & > .item-wrapper {
    flex-direction: column;
    align-items: center;

    & > .track-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 73px;

      @include mobile {
        flex-direction: column;
        gap: 30px;
      }

      @include tablet {
        flex-direction: column;
        gap: 60px;
        padding: 0 40px;
      }

      & > .track-info {
        & > .track-title-wrapper {
          display: flex;
          align-items: center;
          gap: 20px;

          & > .line {
            height: 1px;
            width: 100%;
            background-color: $g06;

            @include mobile {
            }
          }

          & > .track-title {
            color: #222;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 120%; /* 19.2px */
            letter-spacing: -0.6px;
            min-width: max-content;

            @include mobile {
              font-size: 14px;
            }
          }
        }

        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }
  }
}

.home-section5 {
  background: #f6e38210;
  padding: 136px 0 145px 0;
  display: flex;
  justify-content: center;

  @include mobile {
    padding: 60px 20px;
  }

  @include tablet {
    padding: 60px 40px;
  }

  & > .item-wrapper {
    flex-direction: column;
    align-items: center;

    .info-box-wrapper {
      width: 100%;
      display: flex;
      gap: 20px;

      @include mobile {
        flex-direction: column;
      }
    }

    & .info-box {
      width: 100%;
      height: 510px;
      border-radius: 10px;
      border: 1px solid $line;
      background: $white;
      box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.15);
      padding: 40px;
      position: relative;
      overflow: hidden;

      @include mobile {
        padding: 20px;
        height: 335px;
      }

      & .title {
        color: $g01;
        font-size: 24px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.6px;
        margin-bottom: 14px;

        @include mobile {
          text-align: left;
          font-size: 16px;
          margin-bottom: 10px;
        }
      }

      & .content {
        color: $g03;
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.6px;

        @include mobile {
          font-size: 14px;
        }
      }

      & .img1 {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        @include mobile {
          width: 246px;
          height: 222px;
          left: 59px;
          transform: unset;
        }
      }

      & .img2 {
        position: absolute;
        bottom: 14px;
        left: 50%;
        transform: translateX(-50%);

        @include mobile {
          width: 100%;
          left: 0;
          bottom: 6px;
          transform: unset;
        }
      }
    }
  }
}

.home-section6 {
  background: $white;
  padding: 0 0 145px 0;
  display: flex;
  justify-content: center;

  @include mobile {
    padding: 60px 20px 49px 20px;
  }

  @include tablet {
    padding: 60px 40px;
  }

  & > .item-wrapper {
    flex-direction: column;
    align-items: center;

    @include small {
      width: 100%;
    }

    & .supporter-box {
      width: 100%;
      height: 330px;
      margin-bottom: 120px;

      @include mobile {
        margin-bottom: 60px;
      }
    }

    & .faq-box {
      width: 100%;
      height: 250px;
      flex-shrink: 0;
      border-radius: 0 90px 0 90px;
      background: $gradient;
      position: relative;
      margin: 120px 0;
      padding: 60px 70px;
      overflow: hidden;

      @include mobile {
        height: 335px;
        padding: 20px;
        margin: 60px 0;
      }

      & img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        @include mobile {
          object-fit: cover;
          left: unset;
          right: 0;
        }
      }

      & .title {
        text-align: left;
        color: $white;
        margin-bottom: 10px;

        @include mobile {
          font-size: 20px;
        }
      }

      & .caption {
        text-align: left;
        font-size: 16px;
        color: $white;
        margin-bottom: 30px;

        @include mobile {
          position: relative;
          font-size: 14px;
          margin-bottom: 20px;
          z-index: 5;
        }
      }
    }
    & .tab-container {
      display: flex;
      gap: 35px;
      width: 100%;
      border-bottom: 1px solid $line;
      margin-bottom: 50px;

      @include mobile {
        gap: 0;
        justify-content: center;
      }
    }
    & > .accordian-wrapper {
      border-top: 2px solid #044a85;
      width: 100%;
    }
  }
}

.title {
  color: $g01;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.6px;
  margin-bottom: 15px;

  @include mobile {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.caption {
  color: $g03;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.6px;
  margin-bottom: 50px;

  @include mobile {
    font-size: 14px;
    margin-bottom: 30px;
  }
}

.side-bar {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: fixed;
  left: 35px;
  top: 50%;
  transform: translateY(-50%);

  .side-menu {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .menu-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      .circle-wrapper {
        width: 15px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .circle {
        width: 8px;
        height: 8px;
        background-color: #777777;
        border-radius: 50%;
      }
    }

    .menu {
      color: #777;
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
    }

    .menu-active {
      color: $primary4;
      font-weight: 600;
    }
  }
}

.quick-btn-wrapper {
  position: relative;
}

.quick-btn {
  width: 91px;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  background: $primary4;
  color: $white;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.6px;
  cursor: pointer;
}

.quick-x-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.quick-x {
  width: 32px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: $primary4;
  cursor: pointer;
}

.quick-menu-wrapper {
  position: absolute;
  top: 44px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.infra-wrapper {
  border-radius: 10px;
  border: 1px solid $line;
  background-color: #fff;
  width: 230px;
  margin-bottom: 120px;
  cursor: pointer;

  @include small {
    flex: 0 0 auto !important;
  }

  & > .title-box {
    @extend .flex-col-center;
    padding: 18px 24px;
    gap: 5px;
    height: 100px;
  }

  & img {
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-radius: 0 0 10px 10px;
  }

  .text-16 {
    color: $black;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%; /* 16.8px */
    letter-spacing: -0.6px;
  }

  .text-14 {
    color: $g02;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    letter-spacing: -0.6px;
  }
}

.infra {
  padding-top: 118px;
  width: 100%;
}

.infra-container {
  display: flex;
  gap: 13px;
  width: 100%;
  position: relative;
  justify-content: center;

  @include small {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.infra-container::-webkit-scrollbar {
  display: none;
}

.swiper-slide {
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;

  .infra-wrapper {
    margin-bottom: 0;
  }
}

.infra-swiper-button {
  cursor: pointer;
  position: absolute;
  top: 45%;
  z-index: 10;
  width: 24px;
  height: 24px;

  @include mobile {
  }
}

.infra-swiper-button-prev {
  left: -24px;

  @include mobile {
    left: 0;
  }
}

.infra-swiper-button-next {
  right: -24px;

  @include mobile {
    right: 0;
  }
}
</style>
