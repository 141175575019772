<script setup>
import { ref, defineEmits } from "vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";

const file = ref("");

const emit = defineEmits(["changeFile"]);

const getFileName = async (files) => {
  file.value = files[0];
  emit("changeFile", files[0]);
};

const delFile = () => {
  file.value = "";
};
</script>

<template>
  <div class="conatiner-wrapper">
    <input type="file" id="file" @change="getFileName($event.target.files)" />
    <label for="file">파일 선택</label>
    <div class="file-name-wrapper">
      <PretendardText class="file-name">{{ file?.name }}</PretendardText>
      <img
        v-if="file?.name"
        src="../../../assets/ico/x.png"
        alt=""
        @click="delFile"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.conatiner-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  @include mobile {
    align-items: flex-start;
    flex-direction: column;
  }
}

input[type="file"] {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}

label {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  border: 1px solid $g05;
  color: $primary4;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.6px;
}

.file-name-wrapper {
  display: flex;
  gap: 2px;
  align-items: center;

  .file-name {
    color: $g04;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.6px;
  }

  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
</style>
