<script setup>
import {ref, onBeforeMount, reactive, nextTick} from "vue";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainSection from "@/components/templates/mainSection.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import SubjectBox from "@/components/organisms/curriculum/subjectBox.vue";
import { route } from "@/router/route";
import { router } from "@/router";
import MenuApi from "@/api/menu";
import SubjectApi from "@/api/subject";
import { useToast } from "vue-toastification";
import { displayHooks } from "@/hooks/useDisplay";
const toast = useToast();

let useDisplayRef = ref(displayHooks);

let mainSectionMenu = ref([]);
let menuValue = ref("");

const catchWave = ref([]);
const logWave = ref([]);

const semesterList = ref([{ semester: 2024, value: "2024년도" },
  { semester: 2023, value: "2023년도" }]);

let semesterValue = ref(2024);
let semesterCategoryValue = ref([]);
let dataview = ref("all");
const showAllCW = ref(false);
const showAllLW = ref(false);
const isVisible = ref(false);

const showAllCatchWave = () => {
  if (!showAllCW.value) {
    const remainingItems = state.catchWave.length - state.visibleCount;
    state.visibleCount += Math.min(remainingItems, 9);
    if (remainingItems <= 9) {
      showAllCW.value = true;
    }
  }
  state.displayedCatchWave = state.catchWave.slice(0, state.visibleCount);
};

const showAllLogWave = () => {
  if (!showAllLW.value) {
    const remainingItems = state.logWave.length - state.visibleCount;
    state.visibleCount += Math.min(remainingItems, 9);
    if (remainingItems <= 9) {
      showAllLW.value = true;
    }
  }
  state.displayedLogWave = state.logWave.slice(0, state.visibleCount);

};


onBeforeMount(() => {
  const test = new URLSearchParams(window.location.search).get("dataview");
  if (test) {
    dataview.value = test;
  }

  getMenu();
  semesterCategoryValue.value = ["NONE"];
  getSubjectByExternal();
});

function filterInit() {
  semesterValue.value = 2024;
  semesterCategoryValue.value = ["NONE"];
}
function filterFunc() {

  // setTabValue();
  getSubjectByExternal();
}
const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[1].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`curriculum${index + 1}`],
          });
        }

        if (item.homeLink === "/curriculum02") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
const state = reactive({
  catchWave: [],
  logWave: [],
  displayedCatchWave: [],
  displayedLogWave: [],
  visibleCount: 9,
});
function toggleModal() {
  isVisible.value = !isVisible.value;
  if (isVisible.value) {
    nextTick(() => {
      centerPopup();
    });
  }
}
function centerPopup() {
  let popupInner = document.querySelector("#subject .inner");
  let popupHeight = popupInner.clientHeight;
  let windowHeight = window.innerHeight;
  let marginForCenter = (windowHeight - popupHeight) / 2;
  if (windowHeight <= popupHeight) {
    marginForCenter = 100;
  }
  popupInner.style.marginTop = marginForCenter + "px";
}
const getSubjectByExternal =  async () => {
  try {
    let data = {
      courseCategory: "전체",
      page: 1,
      size: 9,
      sort: "id,ASC",
      useFlag: true,
      semester: semesterValue.value,
      semesterCategory: semesterCategoryValue.value.join(','), // 배열을 문자열로 변환
    };
    const response = (await SubjectApi.getForExternal(data)).data.result.list;
      const groupedResponse =  response.reduce((acc, current) => {
      const sbcCd = current.sbc_cd;
      acc[sbcCd] = acc[sbcCd] ?? [];
      acc[sbcCd].push(current);
      return acc;
    }, {});

    const logArr = [];
    const catchArr = [];
    Object.values(groupedResponse).forEach(values => {
      if (values[0].majr_tynm === "캐치 웨이브") {
        catchArr.push(values[0]);
      }
      else if (values[0].majr_tynm === "로그 웨이브" || values[0].majr_tynm === "로그웨이브") {
        logArr.push(values[0]);
      }
    });

    // for (let i = 0; i < response.length; i++) {
    //   const item = response[i];
    //   if (item.majr_tynm === "캐치 웨이브") {
    //     catchArr.push(item);
    //   } else if (item.majr_tynm === "로그 웨이브" || item.majr_tynm === "로그웨이브") {
    //     logArr.push(item);
    //   }
    // }
    // for (let i =0; i < groupedResponse.length; i++){
    //
    //
    // }


    catchWave.value = catchArr;
    state.catchWave = catchWave.value;
    state.displayedCatchWave = state.catchWave.slice(0, state.visibleCount);
    logWave.value = logArr;
    state.logWave = logWave.value;
    state.displayedLogWave = state.logWave.slice(0, state.visibleCount);
  } catch (error) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
          toastClassName: "toast-wrapper",
          position: "top-center",
          timeout: 2000,
          hideProgressBar: true,
          closeButton: false,
          icon: false,
        }
    );
  }
}

function detailRouter(item) {
  router.push({
    name: 'curriculum02Detail',
    query: {
      sbcCd: item.sbc_cd,
      sbcNm : item.sbc_krnm,
      majrCd : item.majr_cd,
      trkCd : item.trk_cd,
    //
      level : item.grd_cd,
      credit : item.gdpt_val,
      teacher : item.usr_krnm,
    },

  });
}

</script>

<template>
  <SubpageHeader />
  <MainSection
      bgUrl="curriculum"
      :options="mainSectionMenu"
      :menuValue="menuValue"
      mainHeader="교육과정"
      subHeader01="Home"
      subHeader02="교육과정"
  />

  <BodyContain style="padding-bottom: 120px">
    <div class="art-inner">
      <div class="art-top">
        <PretendardText class="intro-title">개설 과목</PretendardText>
      </div>
      <div class="select-wrap">
        <a class="btn subject" @click="toggleModal">학기 별 개설과목 보기</a>
      </div>
    </div>
    <PretendardText class="intro-title">캐치웨이브</PretendardText>
    <div class="subject-wrapper">
      <SubjectBox
          v-for="(item, index) in state.displayedCatchWave"
          :key="index"
          :title="item.sbc_krnm"
          :level="item.grd_cd"
          :credit="item.gdpt_val"
          :teacher="item.usr_krnm"
          @waveClick="detailRouter(item)"/>
      <div class="more-wrapper">
        <button class="more" v-if="catchWave.length > 9 && !showAllCW" @click="showAllCatchWave">더보기</button>
      </div>
    </div>
    <PretendardText
        class="intro-title"
        :style="`margin-top: ${useDisplayRef.isMobile ? '60px' : '80px'}`"
    >로그웨이브</PretendardText>
    <div class="subject-wrapper">
      <SubjectBox
          v-for="(item, index) in state.displayedLogWave"
          :key="index"
          :title="item.sbc_krnm"
          :level="item.grd_cd"
          :credit="item.gdpt_val"
          :teacher="item.usr_krnm"
          @waveClick="detailRouter(item)"
      />
      <div class="more-wrapper">
        <button class="more" v-if="logWave.length > 9 && !showAllLW" @click="showAllLogWave">더보기</button>
      </div>
    </div>
    <!--    팝업 추가 -->
    <div class="popup" id="subject" v-if="isVisible">
      <div class="inner" style="display: block">
        <span class="line"></span>
        <div class="semester-pop">
          <p class="tit">학기 선택
            <img class="btn close"
                 alt="" @click="toggleModal"
                 src="../../../assets/ico/btn/ico-pop-close.png"
                 style="float: right"/>
          </p>
          <div class="select">
            <select v-model="semesterValue">
              <option v-for="(item, index) in semesterList" :key="index" :value="item.semester">
                {{ item.value }}
              </option>
            </select>
          </div>
          <div class="chk-wrap" style="text-align: left">
            <label class="lab1">
              <input
                  type="checkbox"
                  v-model="semesterCategoryValue"
                  value="NONE"
              />
              <i></i>
              <s></s>
              <span>전체</span>
            </label>
            <label class="lab2">
              <input
                  type="checkbox"
                  v-model="semesterCategoryValue"
                  value="ONE"
              />
              <i></i>
              <s></s>
              <span>1학기</span>
            </label>
            <label class="lab3">
              <input
                  type="checkbox"
                  v-model="semesterCategoryValue"
                  value="TWO"
              />
              <i></i>
              <s></s>
              <span>2학기</span>
            </label>
            <label class="lab4">
              <input
                  type="checkbox"
                  v-model="semesterCategoryValue"
                  value="SUMMER"
              />
              <i></i>
              <s></s>
              <span>하계 계절학기</span>
            </label>
            <label class="lab5">
              <input
                  type="checkbox"
                  v-model="semesterCategoryValue"
                  value="WINTER"
              />
              <i></i>
              <s></s>
              <span>동계 계절학기</span>
            </label>
          </div>

          <div class="submit-wrap">
            <a class="btn reset" @click="filterInit()"><i></i>
              초기화</a>
            <a class="btn submit" @click="filterFunc()">적용하기</a>
          </div>
        </div>
      </div>
    </div>
  </BodyContain>
  <MainFooter />
</template>

<style lang="scss" scoped>
.intro-title {
  width: 100%;
  color: $black;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  margin-bottom: 50px;

  @include mobile {
    font-size: 20px;
    margin-bottom: 30px;
  }
}
.subject-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @include mobile {
    flex-direction: column;
    gap: 0px;
  }
}

.art-inner {
  padding: 0rem;
  max-width: 100%;
  position: relative;

}
.select{
  margin-bottom: 0.8666666rem;
}

.select-wrap {
  float: right;
  margin-bottom: 0.83333333rem;
}

.select-wrap .subject {
  font-size: 1rem;
  line-height: 0.77777778rem;
  color: #282828;
  height: 3.8rem;
  width: 13rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  padding: 0.36111111rem 1.33333333rem 0.261111rem 0.7555556rem;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 0.27777778rem;
  overflow: hidden;
  margin-bottom: 0.83333333rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0px 2px 10px 0px rgba(40, 40, 40, 0.15);
}

.select-wrap .subject:after {
  background-image: url('../../../../public/images/mo/curr/ico-gt.png');
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  left: auto;
  bottom: auto;
  margin-left: 10.5rem;
}
.more-wrapper{
  text-align: center;
  width: 100%;
}
.more {
  border-radius: 10px;
  display: inline-block;
  width: 10rem;
  border: 1px solid #d9d9d9;
  font-weight: 700;
  margin-top: 0.4rem;
  height: 5rem;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(40, 40, 40, 0.15);
  font-size: 1.2rem;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: -50;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.inner {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 35rem;
  height: 28rem;
  max-width: 90%;
  position: relative;

}

.semester-pop .tit {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 0.8rem;
  font-weight: 800;
  color: #044a85;
  margin-bottom: 2rem;
  text-align: center;
  margin-top: 1.2rem;
}

.popup .inner .line {
  background: linear-gradient(90deg, #044a85 1.64%, #021f85 100%);
  display: block;
  height: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}


.select select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 3.5rem;
  font-size: 1.3rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('@/assets/ico/btn/arrow_down.png') no-repeat right 10px center;
  background-size: 1.5rem;
}
.select select option {
  font-size: 1.0rem;
}
.select::after {
  background: url('@/assets/ico/btn/arrow_down.png') no-repeat center;
  background-size: contain;  position: absolute;
  top: 25%;
  right: 40px;
  transform: translateY(-50%);
  font-size: 1.5rem;
  pointer-events: none;
}

.chk-wrap {
  margin-bottom: 2rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #d9d9d9;
}

.chk-wrap label {
  display: inline-block;
  vertical-align: middle;
  padding-top: 0.81666667rem;
  padding-right: 0.55555556rem;
  padding-bottom: 0.41666667rem;
  padding-left: 0.75555556rem;
  box-sizing: border-box;
  text-align: left;
  width: 10.896rem;
  height: 2.9rem;
  position: relative;
  margin-left: -1px;
  margin-bottom: -1px;
  font-weight: 480;
}

.chk-wrap label s{
  width: 100%;
  height: 100%;
  border: 1px solid #d9d9d9;
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  box-sizing: border-box;

}
.chk-wrap input[type="checkbox"] {
  margin-right: 10px;
}

.submit-wrap {
  padding-top: 0.8333333rem;
  display: flex;
  justify-content: space-between;
}

.btn {
  font-weight: 700;
  display: inline-block;
  padding: 16px 20px;
  text-decoration: none;
  border-radius: 4px;
  text-align: center;
  color: #4d5054;
  border: 1px solid #d9d9d9;
  width: 8rem;
  height: 3.5rem;
}
.btn.reset {
  border-radius: 0.5777778rem;
}
.btn.reset i{
  background-image: url('../../../../public/images/mo/curr/ico-reset.png');
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.3rem;
  height: 1.3rem;
  vertical-align: middle;
  margin-top: 3px;
}
.btn.reset:hover{
  background: #D9D9D9;
}
//.btn.close {
//  width: 25px;
//  float: right;
//  height: 25px;
//  padding: 0px;
//  background: darkgray;
//  cursor: pointer;
//}
.btn.close {
  width: 35px;
  height: 35px;
  padding: 0;
  background-color: #021f85;
  cursor: pointer;
  position: absolute;
  top: 0px;
  border-radius: 0 0.13888889rem 0.13888889rem 0;
  border-color: #021f85;
  right: -35px;
}
.btn i {
  margin-right: 5px;
}
.btn.submit{
  font-weight: 700;
  display: inline-block;
  padding: 16px 20px;
  text-decoration: none;
  border-radius: 4px;
  text-align: center;
  color: #044a85;
  border: 1px solid #044a85;
  width: 8rem;
  height: 3.5rem;
  border-radius: 0.5777778rem;
}
.btn.submit:hover {
  background-color: #f2fcff;
}
</style>
