export const menu = [
  {
    title: "사업소개",
    link: "intro",
  },
  {
    title: "교육과정",
    link: "curriculum",
  },
  {
    title: "학생지원",
    link: "support",
  },
  {
    title: "성과공유",
    link: "sharing",
  },
  {
    title: "정보광장",
    link: "plaza",
  },
];
