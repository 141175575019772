<script setup>
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import { defineProps } from "vue";
import { format } from "date-fns";

const props = defineProps({
  item: Object,
});
</script>

<template>
  <div class="flex-col news-card">
    <img
      :src="
        item.imageUrl
          ? item.imageUrl
          : require(`../../../assets/img/img_empty.png`)
      "
      alt=""
    />
    <div class="flex-col meta-wrapper">
      <PretendardText class="text-16">
        {{ props.item?.title }}
      </PretendardText>
      <PretendardText class="text-14">
        {{ format(new Date(item.createdAt), "yyyy-MM-dd") }}
      </PretendardText>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.news-card {
  min-width: 240px;
  height: 100%;
  border-radius: 10px;
  background: #f7fafc;

  @include mobile {
    min-width: 190px;
  }

  img {
    max-height: 130px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }
}

.meta-wrapper {
  padding: 15px 20px;
  gap: 13px;
  border-top: 1px solid $line;
}

.text-16 {
  color: $primary4;
  font-size: 16px;
  font-weight: 600;
  line-height: 130%; /* 16.8px */
  letter-spacing: -0.6px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all;

  @include mobile {
    font-size: 14px;
  }
}

.text-14 {
  color: $g04;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.6px;

  @include mobile {
    font-size: 12px;
  }
}
</style>
