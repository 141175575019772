import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router/index.js";
import { displayHooks } from "./hooks/useDisplay";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App);
app.use(router); // 라우터 사용
app.use(Toast);
app.use(displayHooks);
app.mount("#app");
