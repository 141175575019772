<script setup>
import { ref, onBeforeMount, onMounted, onUnmounted } from "vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import HeaderModal from "./HeaderModal.vue";
import HeaderSubMenu from "./HeaderSubMenu.vue";
import MHeaderMenu from "./MHeaderMenu.vue";
import { displayHooks } from "@/hooks/useDisplay";
import { menu } from "@/constants/menu";
import { route } from "@/router/route";
import { router } from "@/router";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
const toast = useToast();

const headerModalOpen = ref(false);
const headerMobileOpen = ref(false);
const headerHover = ref(false);
const isScrolled = ref(false);
const activeMenu = ref(-1);
const whiteBackground = "#ffffff";

const list = ref([]);
const subMenu = ref([]);

const modalOpen = () => {
  headerModalOpen.value = true;
};
const modalClose = () => {
  headerModalOpen.value = false;
};
const mobileOpen = () => {
  headerMobileOpen.value = true;
};
const mobileClose = () => {
  headerMobileOpen.value = false;
};

const changeActiveMenu = (index) => {
  subMenu.value = list.value[index].subMenus;
  if (subMenu.value.length > 0) {
    activeMenu.value = index;
  }
};

const changeDeActiveMenu = () => {
  activeMenu.value = -1;
};
const headerMouseOver = () => {
  headerHover.value = true;
};
const headerMouseLeave = () => {
  headerHover.value = false;
};
const defaultRouter = () => {
  router.push({ path: `${route.default}` });
};
const menuRouter = (item, index) => {
  if (item.subMenus[0]?.boardId) {
    router.push({ path: route.board + `/${item.subMenus[0]?.boardId}` });
    return;
  }
  router.push({ path: route[`${menu[index].link}1`] });
};

onBeforeMount(() => {
  getMenu();
});

const getMenu = async () => {
  try {
    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      list.value = response.data.data.content.reverse();
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const handleScroll = () => {
  if ((isScrolled.value = window.scrollY > 0)) {
    isScrolled.value = true;
  } else {
    isScrolled.value = false;
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
  <div
    class="header-wrapper"
    @mouseover="headerMouseOver"
    @mouseleave="headerMouseLeave"
    v-if="displayHooks.isDesktop"
    :style="{
      backgroundColor:
        isScrolled || headerHover ? whiteBackground : 'transparent',
    }"
  >
    <div class="header-left">
      <img
        alt=""
        :src="
          headerHover || isScrolled
            ? require(`../../../assets/img/logo_color.png`)
            : require(`../../../assets/img/logo_white.png`)
        "
        style="cursor: pointer"
        @click="defaultRouter"
      />
      <div class="submenu-container" @mouseleave="changeDeActiveMenu">
        <div class="menu-wrapper">
          <PretendardText
            v-for="(item, index) in list"
            :key="index"
            :class="[
              `menuText`,
              { 'header-hover-text': headerHover || isScrolled },
            ]"
            @mouseover="changeActiveMenu(index)"
            @click="menuRouter(item, index)"
            >{{ item.title }}</PretendardText
          >
        </div>
        <HeaderSubMenu
          :activeMenu="activeMenu"
          :subMenu="subMenu"
          @changeDeActiveMenu="changeDeActiveMenu"
        />
      </div>
    </div>
    <div class="header-right">
      <div class="sns-wrapper">
        <!--        <a-->
        <!--          href="https://blog.naver.com/dkuhuss"-->
        <!--          target="blank"-->
        <!--         >-->
        <!--          <img-->
        <!--            alt=""-->
        <!--            src="../../../assets/ico/blog.png"-->
        <!--            style="cursor: pointer"-->
        <!--          />-->
        <!--        </a>-->
        <!--        <a-->
        <!--          href="https://www.instagram.com/huss_dku?igsh=cjN5cDR1eHQ3aWE3&utm_source=qr"-->
        <!--          target="blank"-->
        <!--        >-->
        <!--          <img-->
        <!--            alt=""-->
        <!--            src="../../../assets/ico/insta.png"-->
        <!--            style="cursor: pointer"-->
        <!--          />-->
        <!--        </a>-->
        <!--        <a href="https://www.youtube.com/@huss_dku" target="blank">-->
        <!--          <img-->
        <!--            alt=""-->
        <!--            src="../../../assets/ico/youtube.png"-->
        <!--            style="cursor: pointer"-->
        <!--          />-->
        <!--        </a>-->
        <a href="https://portal.k-culture.ac.kr/" target="blank">
          <img src="../../../assets/ico/i_lms_w.svg"  cursor="pointer" height="30px"  width="30px" />
        </a>
      </div>
      <img
        @click="modalOpen()"
        alt=""
        :src="
          headerHover || isScrolled
            ? require(`../../../assets/ico/header_btn.png`)
            : require(`../../../assets/ico/header_btn_white.png`)
        "
        style="cursor: pointer"
      />
    </div>
  </div>
  <div
    class="mobile-header-wrapper"
    :style="{
      backgroundColor: isScrolled ? whiteBackground : 'transparent',
    }"
  >
    <img
      v-if="!displayHooks.isDesktop"
      :src="
        require(`@/assets/ico/btn/${isScrolled ? 'main' : 'sub'}_hamberger.png`)
      "
      alt=""
      @click="mobileOpen()"
    />
  </div>
  <MHeaderMenu
    v-if="headerMobileOpen"
    :subMenu="subMenu"
    @modalClose="mobileClose"
  />
  <HeaderModal v-if="headerModalOpen" @modalClose="modalClose" />
</template>

<style lang="scss" scoped>
.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 0 50px;
  background: #ffffff00;
  /* box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25); */
  position: sticky;
  top: 0;
  z-index: 3;
  margin-bottom: -90px;

  &:hover {
    background-color: $white;
  }

  & > .header-left {
    display: flex;
    align-items: center;
    gap: 153px;
    height: 100%;

    & .submenu-container {
      height: 100%;
    }

    & .menu-wrapper {
      display: flex;
      align-items: center;
      height: 100%;

      & > .menuText {
        color: $white;
        font-size: 18px;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.6px;
        cursor: pointer;
        padding: 0 20px;
        height: 100%;
        display: flex;
        align-items: center;

        &:first-child {
          padding-left: 0;
        }

        &:hover {
          color: #044a85;
        }
      }

      & > .header-hover-text {
        color: $g02;
      }
    }
  }

  & > .header-right {
    display: flex;
    align-items: center;
    gap: 30px;

    & > .sns-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;
    }
  }
}
.mobile-header-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 10px 16px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
}
</style>
