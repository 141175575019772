<script setup>
import { ref, onBeforeMount } from "vue";
import { route } from "@/router/route";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import MainSection from "@/components/templates/mainSection.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";

import { displayHooks } from "@/hooks/useDisplay";
import MenuApi from "@/api/menu";
import { useToast } from "vue-toastification";
const toast = useToast();

let useDisplayRef = ref(displayHooks);
let menuValue = ref("");

let mainSectionMenu = ref([]);

onBeforeMount(() => {
  getMenu();
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[4].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`plaza${index + 1}`],
          });
        }

        if (item.homeLink === "/square3") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};
</script>

<template>
  <SubpageHeader />

  <MainSection
    bgUrl="plaza"
    :options="mainSectionMenu"
    :menuValue="menuValue"
    mainHeader="정보광장"
    subHeader01="Home"
    subHeader02="정보광장"
  />

  <BodyContain
    :style="
      useDisplayRef.isMobile ? 'padding-bottom: 78px' : 'padding-bottom: 141px'
    "
  >
    <div
      class="flex-row-between"
      :style="
        useDisplayRef.isMobile
          ? 'padding-bottom: 15px; border-bottom: 1px solid #ebebeb'
          : 'padding-bottom: 20px; border-bottom: 1px solid #ebebeb'
      "
    >
      <PretendardText class="header-title">취업정보</PretendardText>
    </div>
    <div class="img-wrapper">
      <PretendardText class="title">글로벌 K-컬처넷</PretendardText>
      <PretendardText class="content"
        >지역과 세계를 연결하는 <br />
        글로벌 K-컬러넷에서 취업정보를 확인하세요.</PretendardText
      >

      <PretendardText class="btn">바로가기</PretendardText>
    </div>
  </BodyContain>

  <MainFooter />
</template>

<style lang="scss" scoped>
.header-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  color: #111111;
}

.img-wrapper {
  width: 100%;
  height: 316px;
  padding: 0px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  margin-top: 50px;
  box-sizing: border-box;

  background: url("@/assets/img/plaza/job_info.png");
  background-size: cover;
  background-position: center;

  & > .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 24.48px;
    color: #ebebeb;
    margin-bottom: 10px;
  }

  & > .content {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    color: #ffffff;
  }

  & > .btn {
    background-color: #f2f4f6;
    border-radius: 6px;
    padding: 4px 8px;
    cursor: pointer;
    align-self: baseline;
    margin-top: 34px;

    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #111111;
  }
}

@include mobile {
  .header-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #111111;
  }

  .img-wrapper {
    width: 100%;
    height: 525px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    margin-top: 45px;
    box-sizing: border-box;

    background: url("@/assets/img/plaza/job_info_m.png");
    background-size: cover;
    background-position: center;

    & > .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 24.48px;
      color: #ebebeb;
      margin-bottom: 10px;
    }

    & > .content {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      color: #ffffff;
    }

    & > .btn {
      background-color: #f2f4f6;
      border-radius: 6px;
      padding: 4px 8px;
      cursor: pointer;
      align-self: baseline;
      margin-top: 34px;

      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      color: #111111;
    }
  }
}
</style>
