import axios from "axios";

const url = "https://k-culture.ac.kr/api";
// const url = "http://192.168.1.5:8080/api";
const externalApiURI = "https://portal.k-culture.ac.kr/api";
const externalBaseURL = `${externalApiURI}/`;
const baseURL = `${url}/`;

export const _axios = axios.create({
  baseURL,
});

export const externalAxios = axios.create({
  baseURL: externalBaseURL,
});


export default class api {
  static async getAuthorizeHeader() {
    const token = null;
    // const token = await AsyncStorage.getItem('accessToken');
    // const token =
    //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3QwNkB0ZXN0LmNvbSIsIm5pY2tOYW1lIjoi6rmA6ri464-ZIiwiaWF0IjoxNjcxNzkxMTk0LCJleHAiOjE2NzE4Nzc1OTQsImlzcyI6ImdhdHNhZW5nIn0.ROXrNyS5iyhtbh5Uyg64uElRP5bL_i64xa6wFNVnCqQ';

    return token
      ? {
          ...(token ? { Authorization: `${token}` } : {}),
        }
      : {};
  }

  static async send({ url, method, params, data, headers }) {
    return _axios
      .request({
        url,
        method,
        params,
        data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          // 'Content-Type': 'multipart/form-data',
          ...(await api.getAuthorizeHeader()),
          ...headers,
        },
      })
      .then(function (response) {
        // 성공 핸들링
        return {
          data: response.data,
          status: response.status,
          headers: response.headers,
        };
      })
      .catch(function (ex) {
        // 에러 핸들링

        throw ex;
      })
      .finally(function () {
        // 항상 실행되는 영역
      });
  }

  static async sendExternal({ url, method, params, data, headers }) {
    return externalAxios
        .request({
          url,
          method,
          params,
          data,
          headers: {
            'Content-Type' : 'application/json',
            // "Access-Control-Allow-Origin": "*",
            // 'Content-Type': 'multipart/form-data',
            ...(await api.getAuthorizeHeader()),
            ...headers,
          },
        })
        .then(function (response) {
          // 성공 핸들링
          return {
            data: response.data,
            status: response.status,
            headers: response.headers,
          };
        })
        .catch(function (ex) {
          // 에러 핸들링

          throw ex;
        })
        .finally(function () {
          // 항상 실행되는 영역
        });
  }

  static async get(url, params, data) {
    return await api.send({ method: "GET", url, params, data });
  }

  static async del(url, params, data) {
    return await api.send({ method: "DELETE", url, params, data });
  }
  static async postExternal(url, data, params, headers) {
    return await api.sendExternal({ method: "POST", url, data, params, headers });
  }
  static async post(url, data, params, headers) {
    return await api.send({ method: "POST", url, data, params, headers });
  }

  static async put(url, data, params, headers) {
    return await api.send({ method: "PUT", url, data, params, headers });
  }

  static async patch(url, data, params, headers) {
    return await api.send({ method: "PATCH", url, data, params, headers });
  }

  static async fileForm(url, file, method, params) {
    let formData = new FormData();
    formData.append("image", file);

    return await api.send({
      url,
      method: method || "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      params: params,
    });
  }
}
