<script setup>
import { defineProps, toRefs } from "vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";

const props = defineProps({
  title: String,
  active: Boolean,
  style: [String, Object],
});
const { title, active, style } = toRefs(props);
</script>

<template>
  <PretendardText
    :style="style"
    :class="['tab-wrapper', { activeTab: active }]"
    >{{ title }}</PretendardText
  >
</template>

<style lang="scss" scoped>
.tab-wrapper {
  height: 70px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebebeb;

  padding: 0px 10px;

  text-align: center;
  color: #717377;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  letter-spacing: -0.6px;
  cursor: pointer;
}

.activeTab {
  font-weight: 700;
  color: #044a85;
  background-color: #f4f4f4;
}

@include tablet {
  .tab-wrapper {
    height: 70px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ebebeb;

    text-align: center;
    color: #717377;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.6px;
    cursor: pointer;
    white-space: pre-wrap;

    padding: 0px 10px;
  }

  .activeTab {
    font-weight: 700;
    color: #044a85;
    background-color: #f4f4f4;
  }
}

@include mobile {
  .tab-wrapper {
    height: 70px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ebebeb;

    text-align: center;
    color: #717377;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.4px;
    letter-spacing: -0.6px;
    cursor: pointer;
    white-space: pre-wrap;

    padding: 0px 10px;
  }

  .activeTab {
    font-weight: 700;
    color: #044a85;
    background-color: #f4f4f4;
  }
}
</style>
