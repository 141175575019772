import api from "./api";

export default class InfraApi {
  static async get(data) {
    return await api.get(`infras`, data);
  }

  static async getById(id) {
    return await api.get(`infra/${id}`);
  }
}
