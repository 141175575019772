<script setup>
import { ref, watch, onBeforeMount } from "vue";
import { route } from "@/router/route";

import SubpageHeader from "@/components/organisms/header/SubpageHeader.vue";
import MainFooter from "@/components/organisms/footer/MainFooter.vue";
import MainSection from "@/components/templates/mainSection.vue";
import BodyContain from "@/components/molcules/layout/bodyContain.vue";
import SearchInput from "@/components/molcules/input/searchInput.vue";
import BtmPagination from "@/components/molcules/pagination/btmPagination.vue";
import PretendardText from "@/components/atoms/text/PretendardText.vue";
import TabBar from "@/components/organisms/tab/tabBar.vue";
import AccordianItem from "@/components/organisms/plaza/accordianItem.vue";
import ContactModal from "@/components/templates/modal/contactModal.vue";
import MenuApi from "@/api/menu";
import FaqApi from "@/api/faq";
import { displayHooks } from "@/hooks/useDisplay";
import { useToast } from "vue-toastification";
import EmptyTable from "@/components/organisms/emptyTable.vue";
const toast = useToast();

let isOpen = ref(false);
let tabState = ref(1);

let useDisplayRef = ref(displayHooks);
let menuValue = ref("");

let last = ref(false);
let first = ref(false);
let page = ref(0);
let maxPage = ref(0);
let step = ref(0);
let total = ref(0);
let listSize = ref(6);
let paginationSize = ref(5);

let keyword = ref("");
let mainSectionMenu = ref([]);

const goPrevPage = () => {
  if (!first.value) {
    page.value--;
    getApi();
  } else return;

  if (page.value < paginationSize.value * step.value) {
    step.value--;
  }
};

const goNextPage = () => {
  if (!last.value) {
    page.value++;
    getApi();
  } else return;

  if (page.value + 1 > paginationSize.value * (step.value + 1)) {
    step.value++;
  }
};

const goPage = (index) => {
  page.value = index;
  getApi();
};

onBeforeMount(() => {
  getMenu();
  getApi(true);
});

const getMenu = async () => {
  try {
    const arr = [];

    const data = {
      exposureFlag: true,
    };

    const response = await MenuApi.get(data);
    if (response.data.data) {
      const subMenu = response.data.data.content.reverse()[4].subMenus;
      subMenu.map((item, index) => {
        if (item.contentsFlag) {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route.board + `/${item.boardId}`,
          });
        } else {
          arr.push({
            value: item.title,
            label: item.title,
            routeName: route[`plaza${index + 1}`],
          });
        }

        if (item.homeLink === "/square4") {
          menuValue.value = item.title;
        }
        return;
      });

      mainSectionMenu.value = arr;
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const list = ref([]);

const getApi = async (initial) => {
  try {
    if (initial) {
      total.value = 0;
      page.value = 0;
      step.value = 0;
      paginationSize.value = 5;
    }
    const response = await FaqApi.get({
      size: listSize.value,
      page: page.value,
      keyword: keyword.value,
      faqCategory: `FAQ${tabState.value}`,
    });

    if (response.data.data) {
      last.value = response.data?.data?.last;
      first.value = response.data?.data?.first;
      list.value = response.data?.data?.content;
      total.value = response.data.data.totalElements;

      maxPage.value = Math.ceil(total.value / listSize.value);
      if (maxPage.value <= paginationSize.value) {
        paginationSize.value = maxPage.value;
      }
    }
  } catch (e) {
    toast("서버에 문제가 있습니다. 잠시후 다시 시도하세요.", {
      toastClassName: "toast-wrapper",
      position: "top-center",
      timeout: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
    });
  }
};

const openModal = () => {
  isOpen.value = true;
};

const closeModal = () => {
  isOpen.value = false;
};

const changeTab = (index) => {
  tabState.value = index;
  keyword.value = "";
};

watch(isOpen, () => {
  if (isOpen.value) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
});

watch(tabState, () => {
  getApi(true);
});
</script>

<template>
  <SubpageHeader />

  <MainSection
    bgUrl="plaza"
    :options="mainSectionMenu"
    :menuValue="menuValue"
    mainHeader="정보광장"
    subHeader01="Home"
    subHeader02="정보광장"
    containStyle="min-width:88px"
  />

  <BodyContain>
    <div
      :class="useDisplayRef.isMobile ? 'flex-col' : 'flex-row-between'"
      :style="
        useDisplayRef.isMobile ? 'margin-bottom: 30px' : 'margin-bottom: 50px'
      "
    >
      <PretendardText class="header-title">FAQ</PretendardText>
      <SearchInput
        v-model="keyword"
        placeholder="검색어를 입력하세요."
        @click="getApi(true)"
      />
    </div>

    <div class="tab-container">
      <TabBar
        :active="tabState === 1"
        @click="changeTab(1)"
        :title="
          useDisplayRef.isMobile
            ? '글로벌 K-컬처 선도 융합인재양성 사업단'
            : '글로벌 K-컬처 선도 융합인재양성 사업단'
        "
        style="border-right: 0px"
      />
      <TabBar
        :active="tabState === 2"
        @click="changeTab(2)"
        :title="
          useDisplayRef.isMobile
            ? '글로벌 K-컬처 전공 안내'
            : '글로벌 K-컬처 전공 안내'
        "
        style="border-right: 0px"
      />
      <TabBar
        :active="tabState === 3"
        @click="changeTab(3)"
        :title="
          useDisplayRef.isMobile ? '수강신청/학점제한' : '수강신청/학점제한'
        "
      />
    </div>
    <div class="accordian-wrapper">
      <EmptyTable v-if="list.length <= 0" />
      <AccordianItem
        v-else
        v-for="(item, index) in list"
        :key="index"
        :title="item.title"
        :content="item.content"
        :tabState="tabState"
      />
    </div>

    <div style="position: relative">
      <BtmPagination
        :page="page"
        :maxPage="maxPage"
        :step="step"
        :last="last"
        :first="first"
        :total="total"
        :listSize="listSize"
        :paginationSize="paginationSize"
        @goPrevPage="goPrevPage"
        @goNextPage="goNextPage"
        @goPage="goPage"
      />

      <div :class="useDisplayRef.isMobile ? 'flex-row-justify-center' : ''">
        <PretendardText class="inquire-btn" @click="openModal">문의하기</PretendardText>
      </div>
    </div>
  </BodyContain>
  <MainFooter />
  <ContactModal v-if="isOpen" @closeModal="closeModal" />
</template>

<style lang="scss" scoped>
.header-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  color: #111111;
}

.inquire-btn {
  background-color: #044a85;
  border-radius: 10px;
  padding: 14px 24px;
  cursor: pointer;
  align-self: baseline;

  position: absolute;
  top: 40%;
  right: 0px;

  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  color: #ffffff;
}

.tab-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;

  width: 100%;
  height: 70px;
}

.accordian-wrapper {
  width: 100%;
  border-bottom: 1px solid $line;
}

@include mobile {
  .header-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #111111;
    margin-bottom: 15px;
  }

  .inquire-btn {
    background-color: #044a85;
    border-radius: 10px;
    padding: 14px 24px;
    cursor: pointer;
    position: relative;
    align-self: baseline;
    box-sizing: border-box;
    text-align: center;
    margin-top: 30px;

    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    color: #ffffff;
  }

  .tab-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    width: 100%;
    height: 70px;
  }

  .accordian-wrapper {
    width: 100%;
  }
}
</style>
